import Api from './'

const products = {

	list(params={})
	{
		let url = Api.getUrl('products')

		return Api.client().get(url, { params })
	},

	one(slug)
	{
		let url = Api.getUrl(`product/${slug}`)

		return Api.client().get(url)
	}

}

export default products