<template>

	<v-card class="pa-10 rounded-xl">
    
    <TicketSvg class="ticket-svg"/>

    <p class="text-h5 mt-3">
      Con <strong>Seat Reservation</strong> puoi prenotare subito il tuo prodotto e <span class="primary--text font-weight-bold">pagarlo a rate</span>.
    </p>

  </v-card>
  
</template>

<style type="text/css">
  .ticket-svg {
    fill: var(--v-primary-base);
    transform: scale(2.1);
    margin-left: 10px;
    margin-bottom: 10px;
  }
</style>

<script>
import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

import TicketSvg from '@/assets/svg/ticket.svg'

export default {
  name: 'PaymentCard',

  mixins: [ mq, utility ],

  components: {
    TicketSvg
  },

  data: () => ({
    
  }),

  props:
  {
    
  },

  computed:
  {
    
  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    }
  },

  mounted()
  {

  },

  watch:
  {

  }
};
</script>
