const dialog = {

  data: () => ({
    dialog: null,
    isLoading: false,
  }),

  props:
  {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    submitColor: {
      type: String,
      default: 'primary'
    },
    submitLabel: {
      type: String,
      default: 'Salva'
    },
    submitStatus: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  
  computed:
  {
    
  },

  methods:
  {
    close()
    {
      this.dialog = false
    },

    open()
    {
      this.dialog = true
    },
  },

  watch: 
  {
    loading(status)
    {
      this.isLoading = status
    }
  }
}

export default dialog