<template>

  <div class="orders-table-row pt-5">

    <v-row class="px-3" 
           v-if="order.hasShipping">
      <v-col cols="6" md="2">
        <strong>Spedizione</strong>
      </v-col>
      <v-col cols="6" md="2">
        Corriere Standard
      </v-col>
      <v-col cols="6" md="2">
        <strong>Tracking Code</strong>
      </v-col>
      <v-col cols="6" md="2">
        –
      </v-col>
      <v-col cols="6" md="2">
        <strong>Data consegna</strong>
      </v-col>
      <v-col cols="6" md="2">
        –
      </v-col>
    </v-row>

    <v-row class="px-3">
      <v-col cols="6" md="2">
        <strong>Coupon</strong>
      </v-col>
      <v-col cols="6" md="2">
        <span v-if="order.coupon">
          {{ order.coupon.code }}
        </span>
        <span v-else>–</span>
      </v-col>
    </v-row>
    
    <v-simple-table class="transparent mt-5 mb-3">
      <template v-slot:default>
        <thead class="grey lighten-3">
          <tr>
            <th>
              Prodotto
            </th>
            <th class="text-center">
              <download-icon/>
            </th>
            <th class="text-center">
              Quantit&agrave;
            </th>
            <th class="text-center">
              Prezzo
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in order.items"
            :key="item.id"
          >
            <td>{{ item.product.name }}</td>
            <td class="text-center">
              <v-chip v-if="item.hasDownloads">
                {{ item.downloads.length }}
              </v-chip>
              <span v-else>–</span>
            </td>
            <td class="text-center">{{ item.quantity }}</td>
            <td class="text-center">{{ item.price.fullValue }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

  </div>
  
</template>

<style lang="scss" scoped>
  .orders-table-row * {
    font-size: 0.9em !important;
  }
</style>

<script>
import Order from '@/models/order'

import { DownloadIcon } from 'vue-feather-icons'

export default {
  name: 'OrdersTableRow',

  mixins: [],

  components: {
    DownloadIcon, 
  },

  data: () => ({
    
  }),

  computed:
  {
    
  },

  props: 
  {
    order:
    {
      type: Order,
      required: true
    },

  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch: {}
};
</script>
