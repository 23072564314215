import axios from 'axios'

import SETTINGS from '@/settings'

import auth from './auth'
import categories from './categories'
import ecommerce from './ecommerce'
import products from './products'

import { mapActions } from 'vuex'

const apiConfig = {
	url: SETTINGS.BACKEND_URL,
	namespace: 'api',
	version: 'v1'
}

const API = {

	/**
	 * Modules.
	 */
	auth,
	categories,
	ecommerce,
	products,

	client()
	{
		return axios
	},

	getUrl(endpoint)
	{
		let apiUrl = apiConfig.url

		return `${apiUrl}${apiConfig.namespace}/${apiConfig.version}/${endpoint}/`
	},

	setInterceptors()
	{
		const actions = mapActions({ logout:'auth/logout' })

		/**
	     * If APIs get 401 logout user.
	     */
		axios.interceptors.response.use(
			response => response,
			error => {
				
				if (error.response.status === 401) {
					actions.logout()
				}
				
				return Promise.reject(error)
			}
		)
	},

	stateEncode(data={})
	{
		let url = this.getUrl(`state`)

		return this.client().post(url, data)
	},

	stateDecode(encodedState)
	{
		let url = this.getUrl(`state/${encodedState}`)

		return this.client().get(url)
	},

	/**
	 * 🤡 Avatars.
	 */

	avatars()
	{
		let url = this.getUrl(`avatars`)

		return this.client().get(url)
	},

}

export default API