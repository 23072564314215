//import _ from 'lodash'
import Crypto from '@/plugins/crypto'
import moment from 'moment'

import Utility from '@/mixins/utility'

class User {

  #data = {}

  #paymentMethod = {}

  #paymentMethods = []

  #address = {}

  #addresses = []

  #subscriptions = []

  constructor(data={})
  {
    this.#data = data

    this.#paymentMethod = Utility.methods.mapModel(data.payment_method, 'paymentMethod')

    this.#paymentMethods = Utility.methods.mapModels(data.payment_methods, 'paymentMethod')

    this.#address = Utility.methods.mapModel(data.address, 'address')

    this.#addresses = Utility.methods.mapModels(data.addresses, 'address')

    this.#subscriptions = Utility.methods.mapModels(data.subscriptions, 'subscription')

  }

  /**
   * Property getters.
   */
  
  get active()
  {
    return this.#data.active == true
  }

  get address()
  {
    return this.#address
  }

  get addresses()
  {
    return this.#addresses
  }

  get avatarId()
  {
    return this.#data.avatar_id
  }

  get avatar()
  {
    return this.#data.avatar
  }

  get crisp()
  {
    return this.#data.crisp
  }

  get email()
  {
    return this.#data.email
  }

  get emailVerifiedAt()
  {
    let value = this.#data.email_verified_at

    return value ? moment(value) : null
  }

  get gravatar()
  {
    const base = 'https://www.gravatar.com/avatar/',
          email = this.email.toLowerCase(),
          hashed = Crypto.md5(email),
          url = base + hashed

    return url
  }

  get id()
  {
    return this.#data.id
  }

  get firstname()
  {
    return this.#data.firstname
  }

  get lastname()
  {
    return this.#data.lastname
  }

  // default payment method
  get paymentMethod()
  {
    return this.#paymentMethod
  }

  get paymentMethods()
  {
    return this.#paymentMethods
  }

  get subscriptions()
  {
    return this.#subscriptions
  }

  get token()
  {
    return this.#data.token
  }

  /**
   * Computed properties.
   */
  
  get fullname()
  {
    return [ this.firstname, this.lastname ].join(' ')
  }

  get emailIsVerified()
  {
    return this.emailVerifiedAt ? true : false
  }

  get hasAddress()
  {
    return this.address.id ? true : false
  }

  get hasAddresses()
  {
    return this.#addresses.length > 0
  }  

  get hasFullname()
  {
    return this.name && this.lastname
  }

  get hasPaymentMethod()
  {
    return this.paymentMethod.id ? true : false
  }

  get hasPaymentMethods()
  {
    return this.paymentMethods.length > 0
  }

  get initials()
  {
    return this.firstname.substr(0,1).toUpperCase() 
           + this.lastname.substr(0,1).toUpperCase() 
  }

  get otherAddresses()
  {
    let favourite = this.address,
        allAddresses = this.addresses,
        filtered = allAddresses.filter((address)=>{
          return favourite ? (address.id != favourite.id) : true
        })

    return filtered
  }

  get otherPaymentMethods()
  {
    let favourite = this.paymentMethod,
        allMethods = this.paymentMethods,
        filtered = allMethods.filter((method)=>{
          return favourite ? (method.id != favourite.id) : true
        })

    return filtered
  }

  /**
   * Methods.
   */
  
  hasSubscription()
  {
    
  }
}

export default User