import _ from 'lodash'

import SETTINGS from '@/settings'

import Address from '@/models/address'
import CartItem from '@/models/cartItem'
import Category from '@/models/category'
import Order from '@/models/order'
import OrderItem from '@/models/orderItem'
import PaymentMethod from '@/models/paymentMethod'
import Price from '@/models/price'
import Product from '@/models/product'
import Subscription from '@/models/subscription'
import User from '@/models/user'

const Utility = {

  computed:
  {
    appName()
    {
      return SETTINGS.APP_NAME
    },
  },

  methods: {

    goTo(where)
    {
       if( typeof where == 'string' )
         where = { name:where }

       this.$router.push(where).catch(()=>{})
    },

    mapModel(model, classToMap='product')
    {
      return this.mapModels([model], classToMap)[0]
    },

    mapModels(models=[], classToMap='product')
    {
      return _.map(models, (model)=>{

        classToMap = classToMap.toLowerCase()

        switch(classToMap){
          case 'address': return new Address(model)
          case 'cartitem': return new CartItem(model)
          case 'category': return new Category(model)
          case 'order': return new Order(model)
          case 'orderitem': return new OrderItem(model)
          case 'paymentmethod': return new PaymentMethod(model)
          case 'price': return new Price(model)
          case 'product': return new Product(model)
          case 'subscription': return new Subscription(model)
          case 'user': return new User(model)
          default: return model
        }

      })
    },

    storageUrl(append="")
    {
      return `${SETTINGS.BACKEND_URL}storage/${append}`
    }

  }
}

export default Utility