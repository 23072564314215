<template>

	<div>
    
    <p class="text-h5 font-weight-bold"
       style="min-height:64px">
      Come vuoi pagare?
    </p>

    <CreditCard @update="onCreditCardUpdate"
                class="mb-7"/>

    <v-text-field
        v-model="form.email"
        label="Il tuo indirizzo email"
        required
        outlined 
        type="email"
        :error="inputErrorClass('email')" 
        hint="L'indirizzo email è necessario per inviarti la fattura e gli aggiornamenti sull'ordine."
      ></v-text-field>

    <Coupon :value.sync="form.coupon"
            :status.sync="couponStatus"/>

  </div>
  
</template>

<script>
import Coupon from '@/components/ecommerce/checkout/Coupon'
import CreditCard from '@/components/ecommerce/credit-card/Form'

import { required, email } from '@vuelidate/validators'

import vuelidate from '@/mixins/vuelidate'

export default {
  name: 'Payment',

  mixins: [ vuelidate ],

  components: {
    Coupon,
    CreditCard
  },

  data: () => ({
    form: {
      email: null,
      coupon: null,
    },
    checkCreditCard: false,
    creditCardStatus: {},
    couponStatus: false
  }),

  computed:
  {
    
  },

  validations ()
  {
    return {
      form: {
        email: { required, email }
      }
    }
  },

  methods:
  {
    emitUpdate()
    {
      let checkCoupon = (this.couponStatus >= 0)

      let status = { 
        creditCard: this.creditCardStatus,
        form: this.form,
        status: (this.canSubmit && 
                 this.creditCardStatus.status &&
                 checkCoupon )
      }

      this.$emit('update', status)
    },

    onCreditCardUpdate(status)
    {
      this.creditCardStatus = status

      this.emitUpdate()
    }
  },

  mounted()
  {

  },

  watch: 
  {
    form:
    {
      deep: true,
      handler()
      {
        this.emitUpdate()
      }
    },
  }
};
</script>
