<template>

	<div class="text-center mx-auto" style="max-width: 400px">

    <v-progress-linear
      indeterminate
      class="mb-5"
      v-if="isSigningIn"
    ></v-progress-linear>

    <v-form :disabled="isSigningIn">
      
      <v-text-field 
          outlined
          type="email"
          label="Indirizzo email"
          v-model="form.email"
          :autofocus="true"
          @keyup.enter="login"/>

      <v-text-field 
          outlined
          :type="passwordFieldType"
          label="Password"
          v-model="form.password"
          @keyup.enter="login"
          :append-icon="passwordFieldIcon"
          @click:append="togglePasswordFieldType=!togglePasswordFieldType"/>

      <v-btn x-large 
             color="primary"
             class="mb-5"
             :disabled="!canSubmit"
             @click="login"
             :loading="isSigningIn">
        Effettua l'accesso
      </v-btn>

      <p>
        <v-btn small text @click="goTo('resetPassword')">
          Hai dimenticato la password?
        </v-btn>
      </p>

      <p v-if="signup">
        <v-btn small text @click="goTo('signup')">
          Non hai uno Zwan ID? Creane uno!
        </v-btn>
      </p>

    </v-form>

    <v-alert type="warning"
             v-if="errorMessage"
             v-html="$t(errorMessage)">
    </v-alert>

  </div>
  
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import { required, email } from '@vuelidate/validators'

import utility from '@/mixins/utility'
import vuelidate from '@/mixins/vuelidate'

export default {
  name: 'Signin',

  mixins: [ utility, vuelidate ],

  components: {

  },

  data: () => ({
    form: {
      email: null,
      password: null
    },
    errorMessage: null,
    togglePasswordFieldType: false,
    isSigningIn: false
  }),

  props:
  {
    signup:
    {
      type: Boolean,
      default: true
    }
  },

  computed:
  {
    passwordFieldIcon()
    {
      return this.togglePasswordFieldType ? 'mdi-eye-off' : 'mdi-eye' 
    },
    passwordFieldType()
    {
      return this.togglePasswordFieldType ? 'text' : 'password'
    }
  },

  validations ()
  {
    return {
      form: {
        password: { required },
        email: { required, email }
      }
    }
  },

  methods:
  {
    ...mapActions({
      storeLogin: 'auth/login'
    }),

    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    login()
    {
      let credentials = this.form

      //this.setLoading(true)

      this.isSigningIn = true

      this.errorMessage = null

      this.storeLogin(credentials)
          .catch((err)=>{
            
            let message = err.response.data.message

            this.errorMessage = message

          })
          .finally(()=>{
            
            //this.setLoading(false)

            this.isSigningIn = false

          })
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
