<template>
	<div>

    <div class="loading-wrapper mt-10"
         v-if="isLoading">
      <Loading :status="true" 
               absolute 
               color="grey"
               :size="35"
               background-color="transparent"/>
    </div>
      
    <div v-else-if="hasProducts">
      
      <p class="text-h3 grey--text my-5 mb-10">
        Non sei mai stato <strong class="black--text">cos&igrave; vicino</strong> a migliorare la <strong class="black--text">reputazione</strong> del tuo brand.
      </p>

      <ProductsCarousel :products="products"/>

    </div>
    
  </div>
</template>

<style type="text/css">
  .loading-wrapper {
    position: relative;
    min-height: 100px;
  }
</style>

<script>
import API from '@/api'

import utility from '@/mixins/utility'

import ProductsCarousel from '@/components/products/Carousel'
import Loading from '@/components/UI/Loading'

import Product from '@/models/product'

export default {
  name: 'HomeCategoriesRow',

  mixins: [ utility ],

  components: {
    ProductsCarousel,
    Loading
  },

  data: () => ({
    isLoading: false,
    products: []
  }),

  props:
  {
    
  },

  computed:
  {
    hasProducts()
    {
      return this.products.length > 0
    }
  },

  methods:
  {
    load()
    {
      this.isLoading = true

      API.products.list({ 'filter[featured]':true })
                    .then(({data})=>{
                      this.products = data.data.map(product => new Product(product))

                      this.isLoading = false
                    })
    }
  },

  mounted()
  {
    this.load()
  },

  watch:
  {

  }
};
</script>
