<template>
  <div>
    <v-container class="py-10">

      <p class="text-h3 mb-10 pb-5 mt-5">
        I tuoi abbonamenti
      </p>

      <v-row>

        <v-col cols="12" md="6"
               v-for="subscription in userSubscriptions"
               :key="subscription.id">
          <Subscription :product="subscription.product"
                        :highlighted="true">
            
            <!-- subscription has an end -->
            <Alert v-if="subscription.hasEnd"
                   type="warning"
                   class="mt-5 text-center font-weight-bold"
                   dense>
              L'abbonamento scadr&agrave; il {{ subscription.endsAt.format('DD/MM/Y') }}.
            </Alert>
            
            <v-row class="mt-5">
              <v-col>
                <v-chip class="text-uppercase"
                        :color="getBadgeColor(subscription.statusCode)">
                  {{ subscription.status }}
                </v-chip>
              </v-col>
              <v-col class="text-right">
                <v-btn :elevation="0"
                       @click="deleteSubscription(subscription)"
                       v-if="!subscription.hasEnd">
                  DISATTIVA
                </v-btn>  
                <v-btn :elevation="0"
                       color="primary"
                       @click="resumeSubscription(subscription)"
                       v-else-if="subscription.hasEnd">
                  RIATTIVA
                </v-btn>  
              </v-col>
            </v-row>
            
          </Subscription>
        </v-col>

        <v-col cols="12" md="6"
               v-for="subscription in availableSubscriptions"
               :key="subscription.id">
          <Subscription :product="subscription">
            <v-row class="mt-5">
              <v-col class="text-center">
                <v-btn :elevation="0"
                       x-large
                       color="success"
                       @click="goToProduct(subscription)">
                  ATTIVA
                </v-btn>
              </v-col>
            </v-row>
          </Subscription>
        </v-col>

      </v-row>

    </v-container>
  </div>
</template>

<script>
import API from '@/api'

import { mapActions, mapGetters, mapMutations } from 'vuex'

import utility from '@/mixins/utility'

import Alert from '@/components/UI/Alert'
import Subscription from '@/components/products/Panel'

export default {
  
  name: 'Subscriptions',

  mixins: [ utility ],

  components: {
    Alert,
    Subscription
  },

  data: () => ({
    subscriptions: [],
    userSubscriptions: []
  }),

  computed: 
  {
    ...mapGetters({
      isLoading: 'ui/isLoading',
      user: 'auth/user'
    }),

    availableSubscriptions()
    {
      let activeIds = this.userSubscriptions.map( s => s.product.id ),
          excluded = this.subscriptions.filter( s => activeIds.indexOf(s.id) < 0 )

      return excluded
    }
  },

  methods:
  {
    ...mapActions({
      refreshUserData: 'auth/refreshUserData',
    }),

    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    loadSubscriptions()
    {
      this.setLoading()

      API.ecommerce.subscriptions()
                   .then(({ data })=>{

                    this.subscriptions = this.mapModels(data.data, 'product')

                   })
                   .finally(()=>{

                    this.setLoading(false)

                   })
    },

    loadUserSubscriptions()
    {
      if( !this.user )
        return

      this.userSubscriptions = this.user.subscriptions
    },

    getBadgeColor(statusCode)
    {
      switch( statusCode )
      {
        case 1: return 'success'
        default: return ''
      }
    },

    goToProduct(product)
    {
      this.goTo({ name:'product', params:{ slug:product.slug } })
    },

    deleteSubscription(subscription)
    {
      let subscriptionName = subscription.name

      this.setLoading()

      API.auth.deleteSubscription(subscriptionName)
              .then(({ data })=>{
                
                console.log(data)

                this.refreshUserData()
                    .then(()=>this.setLoading(false))

              })
    },

    resumeSubscription(subscription)
    {
      let subscriptionName = subscription.name

      this.setLoading()

      API.auth.resumeSubscription(subscriptionName)
              .then(({ data })=>{
                
                console.log(data)

                this.refreshUserData()
                    .then(()=>this.setLoading(false))

              })
    }

  },

  watch:
  {
    user()
    {
      this.loadUserSubscriptions()
    }
  },

  mounted()
  {
    this.refreshUserData()

    this.loadSubscriptions() 

    this.loadUserSubscriptions()
  }

}
</script>
