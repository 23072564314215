import CryptoJS  from 'crypto-js'

const Crypto = {

	secret()
	{
		return 'Zwan@2022+Store!'
	},

	encrypt(what)
	{
		if( typeof what !== 'string' )
			what = JSON.stringify(what)

		let secret = this.secret(), 
			ciphertext = CryptoJS.AES.encrypt(what, secret)
								.toString()

		return ciphertext
	},

	decrypt(what)
	{
		let secret = this.secret(), 
			bytes = CryptoJS.AES.decrypt(what, secret),
			originalText = bytes.toString(CryptoJS.enc.Utf8)

		try {
			return JSON.parse(originalText)
		}
		catch
		{
			return originalText
		}
	},

	md5(string)
	{
		return CryptoJS.MD5(string)
	}

}

export default Crypto