import _ from 'lodash'

import CartItem from '@/models/cartItem'
import Product from '@/models/product'

class Cart {

  products = []

  constructor(data={})
  {
    if( data.products )
      this.initProducts(data.products)
  }

  /**
   * Property getters.
   */
  
  /**
   * Computed properties.
   */
  
  // effettuo l'encoding del carrello per il salvataggio
  get encoded()
  {
    let encode = {}

        encode.products = _.map(this.products, (item)=>{

          return {
            quantity: item.quantity,
            product: item.product.data,
            price: item.product.price.data,
            meta: item.product.meta
          }

        })

    return JSON.stringify(encode)
  }
  
  get hasShipping()
  {
      return _.filter(this.products, { isDigital:false }).length > 0
  }

  get isEmpty()
  {
      return this.products.length === 0
  }

  get itemsCount()
  {
      return _.sumBy(this.products, 'quantity')
  }

  get productsForCheckout()
  {
    return _.map(this.products, (item)=>{
                return {
                  modelType: 'product',
                  modelId: item.id,
                  priceId: item.product.price.id,
                  quantity: item.quantity,
                  meta: item.meta
                }
              })
  }

  get total()
  {
      return _.sumBy(this.products, 'price')
  }
  
  /**
   * Methods.
   */
  
  add(product, quantity=1)
  {
    let inCart = this.hasProduct(product)

    /**
     * @todo check inCart.maxAmount
     */
    if( inCart && inCart.canBuyMore(quantity) )
        inCart.add()

    else if( !inCart )
        this.products.push(new CartItem(product, quantity))
  }

  clear()
  {
    this.products = []
  }

  hasProduct(product)
  {
    return _.find( this.products, { 
      id: product.id,
      product: {
        price: { id: product.price.id }
      }
    })
  }

  initProducts(products=[])
  {
    products.forEach((item)=>{

      let product = new Product(item.product),
          price = item.price

      if(item.meta)
        product.setMeta(item.meta)

      product.selectPrice(price)
      
      this.add(product, item.quantity)

    })
  }

  remove(product)
  {
    let inCart = this.hasProduct(product)

    if( inCart && inCart.quantity > 1 )
        inCart.remove()

    else
        this.products = _.filter(this.products, (item)=>{
            return item.id !== product.id
        })
  }
  
}

export default Cart