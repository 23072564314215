<template>
	<div>

    <div class="loading-wrapper mt-10"
         v-if="isLoading">
      <Loading :status="true" 
               absolute 
               color="grey"
               :size="35"
               background-color="transparent"/>
    </div>
      
    <div v-else-if="hasCategories">
      
      <p class="text-h4 grey--text mt-10 pt-10">
        Di cosa ha bisogno la <strong class="black--text">tua reputazione</strong> oggi?
      </p>
      
      <CategoriesCarousel :categories="categories"/>
      
    </div>
    
  </div>
</template>

<style type="text/css">
  .loading-wrapper {
    position: relative;
    min-height: 100px;
  }
</style>

<script>
import API from '@/api'

import utility from '@/mixins/utility'

import CategoriesCarousel from '@/components/categories/Carousel'
import Loading from '@/components/UI/Loading'

import Category from '@/models/category'

export default {
  name: 'HomeCategoriesRow',

  mixins: [ utility ],

  components: {
    CategoriesCarousel,
    Loading
  },

  data: () => ({
    categories: [],
    isLoading: false
  }),

  props:
  {
    
  },

  computed:
  {
    hasCategories()
    {
      return this.categories.length > 0
    }
  },

  methods:
  {
    load()
    {
      this.isLoading = true

      API.categories.list()
                    .then(({data})=>{
                      this.categories = data.data.map(category => new Category(category))

                      this.isLoading = false
                    })
    }
  },

  mounted()
  {
    this.load()
  },

  watch:
  {

  }
};
</script>
