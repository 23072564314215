<template>
  <div class="grey lighten-4">
    <v-container class="pt-10"
                 v-if="!isLoading">

      <Alert type="warning"
             v-if="!category.active">
        Ops, questa pagina non esiste!
      </Alert>

      <div v-else>

        <p v-if="theCategory"
           class="text-h5 grey--text">
          {{ theCategory }}
        </p>

        <p class="text-h3 mb-10">
          {{ theHeading }}
        </p>

        <Alert type="info"
               v-if="!category.hasProducts">
          Non sono presenti prodotti in questa categoria.
        </Alert>

        <v-row v-else>
          <v-col :cols="device.isSm ? 12 : 4"
                 v-for="product in category.products"
                 :key="product.id">
            <v-card hover
                    class="rounded-lg pt-5"
                    @click="goToProduct(product)">
              <v-img
                height="200"
                :src="product.image"
                contain
                position="bottom center"
                class="my-5"
              ></v-img>

              <v-card-title 
                style="word-break: break-word"
                class="text-h5 font-weight-bold text-center d-block">
                {{ product.name }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        
      </div>

    </v-container>
  </div>
</template>

<script>
import API from '@/api'

import Category from '@/models/category'

import Alert from '@/components/UI/Alert'

import mq from '@/mixins/mq'
import slug from '@/mixins/slug'
import utility from '@/mixins/utility'

import { mapGetters, mapMutations } from 'vuex'

export default {
  
  name: 'Home',

  mixins: [ mq, slug, utility ],

  components: {
    Alert    
  },

  data: () => ({
    category: {
      type: Category,
      default: ()=> new Category
    },
  }),

  computed: 
  {
    ...mapGetters({
      isLoading: 'ui/isLoading'
    }),

    theCategory()
    {
      return this.category.heading ? this.category.name : ''
    },
    theHeading()
    {
      return this.category.heading || this.category.name
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    goToProduct(product)
    {
      this.goTo({ 
        name: 'product',
        params: { slug:product.slug }
      })
    },

    load()
    {
      let slug = this.slug

      if(slug)
      {
        this.setLoading()

        this.category = new Category

        API.categories.one(slug)
                      .then(({data})=>{
                        
                        this.category = new Category(data.data)

                        this.setLoading(false)

                      })
      }
    },
    
  },

  mounted()
  {
    this.load()
  }

}
</script>
