<template>

	<v-dialog v-model="dialog"
            :max-width="maxWidth"
            :persistent="isLoading">

    <!-- dialog activator -->
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ attrs, on }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="primary">mdi-plus-circle</v-icon>
        </v-btn>
      </slot>
    </template>

    <!-- dialog body -->
    <v-card>
      <v-card-text>

        <!-- dialog header -->
        <div class="text-center pt-5">
          <div class="text-left"
               v-if="!isLoading">
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="text-h5 black--text">
            <slot name="icon">
              <v-icon v-if="icon"
                      color="primary"
                      class="text-h2 mb-2">
                {{ icon }}
              </v-icon>
            </slot>
            <span v-html="title" class="d-block font-weight-bold"/>
            <span v-html="subtitle" class="d-block"/>
          </div>
          <div class="mt-3">
            <slot name="description"></slot>
          </div>
        </div>
        
        <!-- dialog content -->
        <slot></slot>

      </v-card-text>

      <v-divider></v-divider>

      <!-- dialog buttons -->
      <v-card-actions>
        <v-btn text @click="dialog = false">
          Annulla
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :color="submitColor"
          :disabled="!submitStatus"
          :elevation="0"
          :loading="isLoading"
          @click="onSubmit"
        >
          {{ submitLabel }}
        </v-btn>
      </v-card-actions>

      <Loading :status="isLoading" absolute/>

    </v-card>
  </v-dialog>
  
</template>

<style lang="scss">
  .v-dialog {
    border-radius: 15px !important; 
  }
</style>

<script>
import dialog from '@/mixins/dialog'

import Loading from '@/components/UI/Loading'

export default {
  name: 'Dialog',

  mixins: [ dialog ],

  components: {
    Loading    
  },

  data: () => ({
    
  }),

  props:
  {
    status: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: '500'
    }
  },

  computed:
  {
    
  },

  methods:
  {
    onSubmit()
    {
      this.$emit('submit')
    },

  },

  mounted()
  {

  },

  watch:
  {
    dialog(value)
    {
      this.$emit('update:status', value)
    },
    
    status(status)
    {
      this.dialog = status
    }
  }
};
</script>
