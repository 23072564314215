<template>

  <span>
    
    <v-avatar
      :color="avatarColor"
      :size="size"
      v-if="isLogged"
      @click="onClick"
      @mouseover="isHover=true"
      @mouseleave="isHover=false"
      :style="{ cursor:overlay?'pointer':'inherit' }"
    >
      <!-- initials -->
      <span v-if="initials">
        {{ user.initials }}
      </span>

      <!-- image -->
      <v-img v-else
             :src="hubatar"
             :alt="user.fullname"
             :title="user.fullname"/>

      <!-- overlay -->
      <v-overlay :value="showOverlay" 
                 absolute>
        <v-icon>mdi-pencil</v-icon>
      </v-overlay>

    </v-avatar>

  </span>
  
</template>

<style lang="scss" scoped>
  
</style>

<script>
import { mapGetters } from 'vuex'

import Crypto from '@/plugins/crypto'

import API from '@/api'

export default {
  name: 'Gravatar',

  mixins: [],

  components: {
    
  },

  data: () => ({
    cache: null,
    isHover: false
  }),

  props:
  {
    // show initials insted of gravatar
    initials:
    {
      type: Boolean,
      default: false
    },
    overlay:
    {
      type: Boolean,
      default: false
    },
    size:
    {
      type: Number,
      default: 56
    }
  },

  computed:
  {
    ...mapGetters({
      isLogged: 'auth/isLogged',
      user: 'auth/user'
    }),

    gravatar()
    {
      const hash = Crypto.md5(this.user.email),
            defaultAvatar = encodeURIComponent(''),
            url = `https://www.gravatar.com/avatar/${hash}?d=${defaultAvatar}`

      return url
    },

    hubatar()
    {
      const email = this.user.email, 
            cache = this.cache,
            url = API.getUrl(`avatar/${email}?${cache}`)

      return url
    },

    avatarColor()
    {
      return this.isLogged ? '' : 'dark'
    },

    showOverlay()
    {
      return this.overlay && this.isHover
    }
    
  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    },

    refresh()
    {
      this.cache = Math.random()
    }
  },

  mounted()
  {

  },

  watch: {
    user(user)
    {
      if(user)
        this.refresh()
    }
  }
};
</script>
