import Api from './'

const categories = {

	list(params={})
	{
		let url = Api.getUrl('categories')

		return Api.client().get(url, { params })
	},

	one(slug)
	{
		let url = Api.getUrl(`category/${slug}`)

		return Api.client().get(url)
	}

}

export default categories