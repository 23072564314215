<template>

	<v-card class="pa-10 rounded-xl">
    
    <PackageIcon size="3x" class="primary--text"/>
    
    <p class="mt-3 text-h5">
      Nessun costo extra, la consegna con corriere in Italia &egrave; <span class="primary--text font-weight-bold">sempre gratuita</span>.
    </p>
    
  </v-card>
  
</template>

<script>
import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

import { PackageIcon } from 'vue-feather-icons'

export default {
  name: 'ShippingCard',

  mixins: [ mq, utility ],

  components: {
    PackageIcon    
  },

  data: () => ({
    
  }),

  props:
  {
    
  },

  computed:
  {
    
  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    }
  },

  mounted()
  {

  },

  watch:
  {

  }
};
</script>
