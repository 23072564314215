<template>

	<v-row>
    <v-col cols="12" 
           md="4"
           :class="device.isSm ? 'text-center' : ''">
      <p class="text-h5">
        Metodi di pagamento 
        <CreditCardDialog/>
      </p>
    </v-col>
    <v-col cols="12" md="8">

      <Alert type="warning"
              v-if="!user.hasPaymentMethod">
        Non hai ancora impostato un metodo di pagamento.
      </Alert>

      <PaymentMethod :paymentMethod="user.paymentMethod"
                     class="mb-5"
                     highlighted
                     v-if="user.hasPaymentMethod">
        <template #bottom>
          <p class="mb-0 mt-5 text-right">
            <v-btn :elevation="0" 
                   color="orange lighten-3 warning--text">
              <v-icon class="mr-1">mdi-star-outline</v-icon>
              Preferito
            </v-btn>
          </p>
        </template>
      </PaymentMethod>
      
      <PaymentMethod v-for="paymentMethod in user.otherPaymentMethods"
                     :key="paymentMethod.id"
                     :paymentMethod="paymentMethod"
                     class="mb-5">
        <template #bottom>
          <div class="mt-5 text-right">
            <v-btn icon v-if="isLoading" :loading="true"></v-btn>
            <div v-else>
              <!-- delete -->
              <DeleteDialog :payment-method="paymentMethod"/>
              <!-- mark as favourite -->
              <v-btn :elevation="0" 
                     class="ml-3"
                     @click="markAsFavourite(paymentMethod)">
                <v-icon class="mr-1">mdi-star-outline</v-icon>
                Preferito
              </v-btn>
            </div>
          </div>
        </template>
      </PaymentMethod>

    </v-col>
  </v-row>
  
</template>

<script>
import API from '@/api'

import { mapActions, mapGetters } from 'vuex'

import mq from '@/mixins/mq'

import Alert from '@/components/UI/Alert'
import CreditCardDialog from '@/components/ecommerce/credit-card/Dialog'
import DeleteDialog from '@/components/ecommerce/credit-card/Delete'
import PaymentMethod from '@/components/ecommerce/credit-card/Panel'

export default {
  
  name: 'PaymentMethods',

  mixins: [ mq ],

  components: {
    Alert,
    CreditCardDialog,
    DeleteDialog,
    PaymentMethod
  },

  data: () => ({
    isLoading: false
  }),

  computed:
  {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  methods:
  {
    ...mapActions({
      refreshUserData: 'auth/refreshUserData'
    }),

    markAsFavourite(paymentMethod)
    {
      this.isLoading = true

      API.ecommerce.favouritePaymentMethod(paymentMethod.id)
                   .then(()=>{

                    this.refreshUserData()
                        .then(()=>{
                          
                          this.isLoading = false

                        })
                        .catch(({ response })=>{

                          this.isLoading = false

                          console.error(response.data)

                        })

                   })
    } 
  },

  mounted()
  {

  },

  watch: {}
};
</script>
