import Price from '@/models/price'
import Product from '@/models/product'

class OrderItem {

  #data = {}

  #price = {}

  #product = {}

  constructor(data={})
  {  
    this.#data = data

    this.#price = new Price(data.price)

    /**
     * @todo assumo che ogni item sia associato a un product model
     */
    this.#product = new Product(data.model)
  }

  /**
   * Property getters.
   */
  
  get data()
  {
    return this.#data
  }

  get id()
  {
    return this.#data.id
  }

  get price()
  {
    return this.#price
  }

  get product()
  {
    return this.#product
  }

  get quantity()
  {
    return this.#data.quantity || 1
  }

  /**
   * Computed properties.
   */
  
  get total()
  {
    let price = this.price ? this.price.value : 0

    return price * this.quantity
  }

  get downloads()
  {
    let downloads = this.product ? this.product.downloads : []

    return downloads
  }

  get hasDownloads()
  {
    return this.product && this.product.hasDownloads
  }

}

export default OrderItem