const it = {

  /**
   * @todo implementare bene la traduzione degli errori!
   * @see https://vuelidate-next.netlify.app/advanced_usage.html#i18n-support
   */
  validations: {
    'Value is required': `Questo campo è obbligatorio.`,
    'Value must be numeric': `Inserisci soltanto numeri.`,
    'Value is not a valid email address': `Inserisci un indirizzo email valido.`,
    'The value must be equal to the other value': `I due campi sembrano diversi.`,
    'This field should be at least 5 long': `Inserisci almeno 5 caratteri.`,
    'The maximum length allowed is 5': `Inserisci massimo 5 caratteri.`,
  },

  errors: {
    general: `Si è verificato un errore.`,
    permissions: `Non hai i permessi per effettuare questa operazione.`,
    account_already_exists: `Questo Zwan ID esiste già. Effettua la login oppure se non la ricordi recupera la password.`,
    account_not_active: `Questo account non è attivo.`,
    coupon_doesnt_exist: `Il coupon inserito non esiste`,
    credentials: `Le credenziali inserite non sono valide, per favore ricontrollale.`,
    email_not_found: `L'indirizzo email non è valido, per favore ricontrollalo`,
    email_not_verified: `Per poter accedere al tuo account devi prima verificare l'indirizzo di posta elettronica cliccando sul link contenuto nell'email che ti abbiamo inviato.`,
    credit_card: `Sembra ci sia un problema con il tuo metodo di pagamento. Per favore riprova oppure utilizza un altro metodo di pagamento.`,
    payment_method_missing: `Sembra ci sia un errore con il tuo metodo di pagamento.`,
    cart_item_max_amount: `Sembra che tu abbia raggiunto il numero massimo di quantità acquistabili per il prodotto <strong>{productName}</strong>.`,
    order_not_found: `Non troviamo il tuo ordine, se il problema persiste contatta il nostro servizio clienti.`,
  },

  prices: {
    every: {
      day: ' | ogni giorno | ogni {count} giorni',
      month: ' | ogni mese | ogni {count} mesi',
      year: ' | ogni anno | ogni {count} anni',
      week: ' | ogni settimana | ogni {count} settimane'
    }
  },

  ecommerce: {
    checkout: {
      disclaimer: `Cliccando sul pulsante “Invia ordine” accetti tutti i <a href="/toc" class="black--text">termini delle condizioni di vendita e rimborso</a> e delle norme sulla privacy di Zwan. La ricevuta, che ti verrà inviata elettronicamente, contiene i termini e un elenco dettagliato dell’acquisto che include le imposte e le spese di spedizione, se previste.`,
      zero_cart: `Se il saldo finale del tuo carrello &egrave; pari a zero (ad esempio perch&eacute; stai acquistando prodotti in promozione oppure stai utilizzando dei coupon) non verr&agrave; effettuato alcun addebito sulla tua carta di credito.`
    }
  },

  signup: {
    hints: {
      email: `Controlla bene il tuo indirizzo, ti invieremo una email per attivare l'account e lo utilizzeremo per aggiornarti sui tuoi ordini.`
    }
  },

  resetPassword: {
    error: `Si è verificato un errore nel reimpostare la password. Se è trascorso molto tempo da quando hai ricevuto il link prova a richiederne uno nuovo.`,
    sent: `Ti abbiamo inviato il link per reimpostare la password all'indirizzo di posta elettronica associato al tuo <strong>Zwan ID</strong>.`,
    success: `Hai reimpostato la password del tuo <strong>Zwan ID</strong>, ora puoi utilizzare la nuova password per accedere al tuo account.`,
  }
  
}

export default it