<template>
  <div>
    <v-container class="py-10">

      <p class="text-h3 mt-5 mb-10 pb-5 text-center">
        Reimposta la password del tuo <strong>Zwan ID</strong>
      </p>

      <ResetPasswordForm v-if="token" :token="token"/>

      <AskResetPasswordForm v-else/>

    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import utility from '@/mixins/utility'

import AskResetPasswordForm from '@/components/auth/AskResetPassword'
import ResetPasswordForm from '@/components/auth/ResetPassword'

export default {
  
  name: 'ResetPassword',

  mixins: [ utility ],

  components: {
    AskResetPasswordForm,
    ResetPasswordForm
  },

  data: () => ({

  }),

  computed: 
  {
    ...mapGetters({
      user: 'auth/user'
    }),

    token()
    {
      return this.$route.query.token
    },

  },

  methods:
  {
    
  },

  watch:
  {
    
  },

  mounted()
  {
    
  }

}
</script>
