/**
 * @see http://numeraljs.com/
 */

const plugin = require("numeral")

const currency = (value, currency=" €", format="0,0.00") => {
    return plugin(value).format(format) + currency
}

export default currency