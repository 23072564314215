class PaymentMethod {

  #data = {}

  constructor(data={})
  {  
    this.#data = data
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get name()
  {
    return this.#data.billing_details.name
  }

  get brand()
  {
    return this.#data.card.brand
  }

  get last4()
  {
    return this.#data.card.last4
  }

  get expMonth()
  {
    return this.#data.card.exp_month
  }

  get expYear()
  {
    return this.#data.card.exp_year
  }

  /**
   * Computed properties.
   */
  
  get cardNumber()
  {
    return `**** **** **** ${this.last4}`
  }

  get expiration()
  {
    let month = this.expMonth < 10 ? `0${this.expMonth}` : this.expMonth,
        year = this.expYear

    return `${month}/${year}`
  }

}

export default PaymentMethod