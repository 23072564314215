<template>

	<div><!-- Crisp here. --></div>
  
</template>

<style type="text/css">
  /**
   * hide Crisp toggle for test purpose.
   */
  .crisp-client_ {
    display: none !important;
  }
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Crisp',

  mixins: [],

  components: {},

  data: () => ({
    //
  }),

  props: {},

  computed:
  {
    ...mapGetters({
      isEmbed: 'spaces/isEmbed',
      user: 'auth/user'
    }),
  },

  methods:
  {
    hide()
    {
      this.$crisp.do('chat:hide')
    },

    checkEmbed()
    {
      if( this.isEmbed )
        this.hide()
    },

    checkAuth()
    {
      let user = this.user

      if(user)
      {
        this.$crisp.set("user:avatar", [user.gravatar]);
        this.$crisp.set("user:nickname", [user.fullname]);
        this.$crisp.set("user:email", [user.email]);
      }
      else
      {
        this.$crisp.set("user:avatar", null);
        this.$crisp.set("user:nickname", null);
        this.$crisp.set("user:email", null);
      }
    }
  },

  mounted()
  {
    this.checkEmbed()
    
    this.$crisp.$on('loaded', () => {
      window.setTimeout(this.checkAuth, 500 )
    })
  },

  watch: 
  {
    user()
    {
      this.checkAuth()
    }
  }
};
</script>
