<template>

	<v-sheet :class="getClasses"
           :style="getCss"
           @click="onClick">
    
    <slot></slot>

    <div v-show="expanded">
      <slot name="expansion"></slot>
    </div>

  </v-sheet>
  
</template>

<style lang="scss" scoped>
  .panel {
    border-radius: 10px;

    &.with-hover:hover {
      background-color: #eee;
      &.highlighted {
        background-color: var(--v-primary-lighten4);
      }
    }
  }
</style>

<script>
export default {
  name: 'Panel',

  mixins: [],

  components: {
    
  },

  data: () => ({
    //
  }),

  props:
  {
    borderColor: {
      type: String,
      default: 'grey'
    },
    clean: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    pointer: {
      type: Boolean,
      default: false
    },
    withHover: {
      type: Boolean,
      default: false
    },
  },

  computed:
  {
    getClasses()
    {
      let css = ['px-5', 'py-5', 'panel']

      if( this.withHover )
        css.push('with-hover')

      if( this.highlighted )
        css.push('highlighted')

      return css.join(' ')
    },
    
    getCss()
    {
      let theme = this.$vuetify.theme.themes.light,
          borderColor = this.highlighted ? theme.primary : this.borderColor

      let border = this.clean ? 'none' : `2px solid ${borderColor}`,
          cursor = this.pointer ? 'pointer' : ''

      return {
        border,
        cursor
      }
    }
  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
