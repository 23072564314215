<!-- 

  ⚠️ Il testo contenuto in questa pagina è stato generato da 

-->
<template>
  <div>
    <v-container class="py-10">

      <p class="text-h3 mb-10 pb-5 mt-5">
        Termini e Condizioni
      </p>
      
      <div>
        <p>
        Il presente documento riporta i termini e le condizioni generali sulla base dei quali viene offerto agli utenti l'uso
        del sito web
        Zwan Store che offre
        la vendita di prodotti e servizi in abbonamento.
        </p>
      </div>
      
      <ol>
      <li>
      <h4>Definizioni</h4>
      <p>
      Per consentire una completa comprensione e accettazione dei presenti termini e condizioni, i seguenti termini, al singolare e al plurale, avranno il significato di seguito indicato:
      <ul>
      <li>
      <strong>Titolare:</strong>
      <strong>Zwan S.r.l.</strong>, con sede legale in Piazza Capponi 13, 00193 Roma , Partita IVA / Codice Fiscale 15488481001, VAT number 15488481001, capitale sociale interamente versato 10.000,00 €,
      indirizzo PEC
      <a href="mailto:zwansrl@pec.it">zwansrl@pec.it</a>
      </li>
      <li>
      <strong>Applicazione:</strong>
      il sito web
      Zwan Store
      </li>
      <li>
      <strong>Prodotti:</strong>
      <ul>
      <li>
      <strong>Prodotti materiali:</strong>
      i beni mobili materiali, inclusi eventuali beni materiali con elementi digitali, venduti dal Titolare
      </li>
      <li>
      <strong>Prodotti digitali:</strong>
      i contenuti e servizi forniti in formato digitale e venduti dal Titolare
      </li>
      <li>
      <strong>Servizi:</strong>
      qualsiasi prestazione di servizio, diversa da un servizio digitale venduta dal Titolare
      </li>
      </ul>
      </li>
      <li>
      <strong>Utente:</strong>
      qualunque soggetto che accede e utilizza l’Applicazione
      </li>
      <li>
      <strong>Utente Consumatore:</strong>
      la persona fisica maggiorenne che conclude un contratto per scopi estranei alla propria attività imprenditoriale, commerciale, artigianale o professionale eventualmente svolta
      </li>
      <li>
      <strong>Utente non Consumatore: </strong>
      la persona fisica maggiorenne o persona giuridica che conclude un contratto per lo svolgimento o per esigenze della propria attività imprenditoriale, commerciale, artigianale o professionale
      </li>
      <li>
      <strong>Condizioni:</strong>
      il presente contratto che disciplina i rapporti tra il Titolare e gli Utenti
      e la vendita o fornitura dei Prodotti offerti dal Titolare tramite l’Applicazione.
      </li>
      </ul>
      </p>
      </li>
      <li>
      <h4>Ambito di applicazione delle Condizioni</h4>
      <p>L’uso dell’Applicazione comporta l’accettazione delle Condizioni da parte dell’Utente. Qualora l’Utente non intenda accettare le Condizioni e/o qualsiasi altra nota, avviso legale, informativa pubblicati o ivi richiamati non potrà utilizzare l’Applicazione né i relativi servizi.</p>
      <p>
      Le Condizioni possono essere modificate in ogni momento.
      </p>
      <p>Le Condizioni applicabili sono quelle in vigore alla data di trasmissione dell’ordine di acquisto o richiesta di fornitura di un Prodotto.</p>
      <p>Prima di utilizzare l’Applicazione, l’Utente è tenuto a leggere attentamente le Condizioni e a salvarle o stamparle per future consultazioni.</p>
      <p>
      Il Titolare si riserva il diritto di variare a propria discrezione, in qualunque momento
      anche successivo alla registrazione dell’Utente, l’interfaccia grafica dell’Applicazione, i Contenuti e la loro organizzazione, nonché ogni altro aspetto che caratterizza la funzionalità e la gestione dell’Applicazione, comunicando all’Utente, ove occorrenti, le relative istruzioni.
      </p>
      </li>
      <li>
      <h4>Acquisto o richiesta di fornitura tramite l’Applicazione</h4>
      <p>
      Tutti i Prodotti offerti tramite l’Applicazione sono descritti in maniera dettagliata nelle relative pagine prodotto (qualità, caratteristiche, disponibilità, prezzo,
      tempi e spese di consegna,
      tempi di fornitura,
      oneri accessori, etc.). Potrebbero evidenziarsi alcuni errori, imprecisioni o piccole differenze tra quanto pubblicato sull’Applicazione e il Prodotto reale. Inoltre, le eventuali immagini dei Prodotti sono solo rappresentative e non costituiscono elemento contrattuale.
      </p>
      <p>
      Gli acquisti e/o le richieste di fornitura di uno o più Prodotti tramite l’Applicazione sono consentiti
      sia a Utenti Consumatori sia a Utenti non Consumatori.
      </p>
      <p>Alle persone fisiche gli acquisti e/o le richieste di fornitura sono consentiti solo a condizione che siano maggiorenni. Per i minorenni ogni acquisto e/o richiesta di fornitura di Prodotti tramite l’Applicazione dovranno essere vagliati e autorizzati dai genitori o dagli esercenti la responsabilità genitoriale.</p>
      <p>L’offerta dei Prodotti tramite l’Applicazione costituisce invito ad offrire e l’ordine inviato dall’Utente varrà quale proposta contrattuale di acquisto e/o di richiesta di fornitura, soggetta a conferma e/o accettazione da parte del Titolare secondo quanto descritto di seguito. Pertanto, il Titolare avrà, a proprio insindacabile giudizio, il diritto di accettare o meno l’ordine dell’Utente senza che quest’ultimo possa eccepire o lamentare alcunché a qualsivoglia titolo e/o ragione. </p>
      <p>
      Il contratto di vendita o di fornitura dei Prodotti si intende concluso con l’accettazione da parte del Titolare della proposta contrattuale dell’Utente . Il Titolare accetterà la proposta contrattuale dell’Utente mediante l’invio della conferma d’ordine all’indirizzo di posta elettronica indicato dall’Utente oppure tramite la visualizzazione di una pagina web di conferma d’ordine, nella quale saranno riportati la data dell’ordine, i dati dell’Utente, le caratteristiche e la disponibilità del Prodotto, il prezzo o la modalità di calcolo del prezzo, gli eventuali ulteriori oneri e imposte accessori, i tempi di fornitura ed esecuzione, l’indirizzo di consegna, i tempi e le eventuali spese di consegna, le modalità per esercitare il diritto di recesso o la sua eventuale esclusione e la garanzia.
      </p>
      <p>Il contratto di vendita o fornitura dei Prodotti non si considera efficace fra le parti in difetto di quanto indicato al punto precedente.</p>
      <p>Nel caso in cui il Prodotto non fosse disponibile, il Titolare renderà noto all’Utente i nuovi termini di consegna o fornitura, chiedendo se intende confermare l’ordine o meno. Resta inteso che il contratto si intenderà perfezionato relativamente ai Prodotti accettati dal Titolare.</p>
      <p>L’Utente si impegna a verificare la correttezza dei dati riportati nella conferma d’ordine e a comunicare immediatamente al Titolare eventuali errori e provvederà a conservare copia del proprio ordine, della relativa conferma e delle Condizioni. </p>
      </li>
      <li>
      <h4>Registrazione</h4>
      <p>
      Per usufruire delle funzionalità dell’Applicazione, gli Utenti devono registrarsi fornendo, in maniera veritiera e completa, tutti i dati richiesti nel relativo form di registrazione ed accettare integralmente la privacy policy (
      <a href="https://www.zwan.it/privacy-policy/">https://www.zwan.it/privacy-policy/</a>) e le Condizioni.
      </p>
      <p>L’Utente ha l’onere di custodire le proprie credenziali di accesso che devono essere utilizzate esclusivamente dall’Utente e non possono essere cedute a terzi. L’Utente si impegna a tenerle segrete e ad assicurarsi che nessun terzo vi abbia accesso e a informare immediatamente il Titolare nel caso in cui sospetti o venga a conoscenza di un uso indebito o di una indebita divulgazione delle stesse.</p>
      <p>L'Utente garantisce che le informazioni personali fornite durante la procedura di registrazione sono complete e veritiere e si impegna a tenere il Titolare indenne e manlevato da qualsiasi danno, obbligo risarcitorio e/o sanzione derivante da e/o in qualsiasi modo collegata alla violazione da parte dell'Utente delle regole sulla registrazione all’Applicazione o sulla conservazione delle credenziali di registrazione.</p>
      </li>
      <li>
      <h4>Cancellazione account e chiusura</h4>
      <p>
      L’Utente registrato può interrompere l’utilizzo dell’Applicazione in ogni momento e disattivare il proprio account o richiederne la cancellazione attraverso l’interfaccia dell’Applicazione, se possibile, o inviando una comunicazione scritta all’indirizzo e-mail
      <a href="mailto:info@zwan.it">info@zwan.it</a>
      o chiamando il servizio clienti al numero +390640060079
      .
      </p>
      <p>In caso di violazione da parte dell’Utente delle Condizioni o delle disposizioni di legge applicabili, il Titolare si riserva il diritto di sospendere o chiudere l’account dell’Utente in ogni momento e senza preavviso.</p>
      </li>
      <li>
      <h4>Abbonamento</h4>
      <p>L’Applicazione offre Prodotti in abbonamento. Le caratteristiche e le modalità di abbonamento sono dettagliate nell’Applicazione.</p>
      <p>Per sottoscrivere l’abbonamento, l’Utente dovrà seguire la procedura indicata nell’Applicazione e inserire i dati richiesti. L’eventuale addebito del costo dell’abbonamento avrà inizio alla data specificata e con la frequenza indicata al momento della sottoscrizione.</p>
      <p>L’abbonamento si rinnova secondo le modalità ed i tempi indicati nell’Applicazione.</p>
      <p>
      Gli Utenti possono disattivare il rinnovo attraverso l’Applicazione oppure inviando una comunicazione all’indirizzo e-mail
      <a href="mailto:info@zwan.it">info@zwan.it</a>
      o chiamando il servizio clienti al numero +390640060079
      o tramite la modifica delle preferenze del metodo di pagamento utilizzato.
      </p>
      </li>
      <li>
      <h4>Prezzi e pagamenti</h4>
      <p>
      Per ogni Prodotto viene indicato il prezzo
      comprensivo di IVA, se dovuta. Qualora la natura del Prodotto comporti l’impossibilità di calcolarlo in anticipo, vengono indicate le modalità di calcolo del prezzo.
      </p>
      <p>
      Inoltre, saranno indicate tutte le eventuali imposte, spese aggiuntive
      e spese di consegna
      che possono variare in relazione
      alla destinazione, alla modalità di consegna prescelta e/o
      alla modalità di pagamento utilizzata. Qualora tali voci di spesa non possano ragionevolmente essere calcolate in anticipo, vi sarà l’indicazione di quali spese saranno addebitate all’Utente.
      </p>
      <p>Il Titolare si riserva la facoltà di modificare, in qualsiasi momento, il prezzo dei Prodotti e tutti gli eventuali costi aggiuntivi. Resta inteso che le eventuali modifiche di prezzo non pregiudicheranno in alcun caso i contratti già conclusi prima della modifica.</p>
      <p>L’Utente si impegna a pagare il prezzo del Prodotto nei tempi e modi indicati nell’Applicazione e a comunicare tutti i dati necessari eventualmente richiesti.</p>
      <p>L’Applicazione utilizza strumenti terzi per l’elaborazione dei pagamenti e non entra in alcun modo in contatto con i dati di pagamento forniti (numero delle carte di credito, nome del titolare, password, etc.).</p>
      <p>Qualora tali strumenti terzi dovessero negare l’autorizzazione al pagamento, il Titolare non potrà fornire i Prodotti e non potrà essere considerato in alcun modo responsabile.</p>
      </li>
      <li>
      <h4>Fatturazione</h4>
      <p>
      All’Utente che desideri ricevere la fattura saranno richiesti i dati per la fatturazione. Per l’emissione della fattura faranno fede le informazioni fornite dall’Utente che egli dichiara e garantisce essere rispondenti al vero, rilasciando al Titolare ogni più ampia manleva al riguardo.
      </p>
      </li>
      <li>
      <h4>Modalità di consegna di Prodotti materiali</h4>
      <p>I Prodotti materiali e/o beni digitali forniti su un supporto materiale saranno consegnati all’indirizzo indicato dall’Utente, con le modalità e nel termine scelti o indicati sull’Applicazione e riportati nella conferma d’ordine. Se non specificato diversamente, i termini di consegna specificati nella conferma d’ordine indicano il periodo di tempo abitualmente necessario per recapitare il Prodotto dal momento della presa in carico del corriere.</p>
      <p>Nel caso in cui non sia possibile fornire i Prodotti richiesti, ne verrà dato tempestivo avviso tramite e-mail all’Utente, con l’indicazione di quando si prevede di poterli consegnare o delle ragioni che rendono la fornitura impossibile.</p>
      <p>Qualora l’Utente non intenda accettare il nuovo termine o la consegna sia divenuta impossibile, potrà chiedere il rimborso di quanto pagato che verrà accreditato tempestivamente nelle stesse modalità di pagamento utilizzate dall’Utente per l’acquisto al massimo entro 14 giorni dalla data in cui il Titolare è venuto a conoscenza della richiesta di rimborso.</p>
      <p>All’atto del ricevimento del Prodotto, l’Utente è tenuto a verificarne la conformità con l’ordine effettuato nonché l’integrità dell’imballaggio. Nel caso in cui risultino danni evidenti all’imballaggio e/o al Prodotto, l’Utente può rifiutare la consegna del Prodotto e potrà restituirlo senza alcuna spesa a suo carico. Una volta firmato il documento di consegna, l’Utente non potrà opporre alcuna contestazione circa le caratteristiche esteriori dei Prodotti consegnati.</p>
      <p>Il Titolare non sarà responsabile nei confronti di alcuna parte o di terzi in merito a danni, perdite e costi subiti a seguito della mancata esecuzione del contratto dovuta a forza maggiore.</p>
      </li>
      <li>
      <h4>
      Modalità di fornitura di
      Prodotti digitali
      e
      servizi
      </h4>
      <p>
      Il Titolare provvederà a fornire i
      Prodotti digitali
      e/o
      servizi
      all’Utente, con le modalità e nel termine indicati sull’Applicazione e riportati nella conferma d’ordine.
      </p>
      <p>
      Nel caso in cui non sia possibile fornire i
      Prodotti digitali
      e i
      servizi
      richiesti entro tale termine, ne verrà dato tempestivo avviso tramite e-mail all’Utente, con l’indicazione di quando si prevede di poterli fornire o delle ragioni che rendono la fornitura impossibile.
      </p>
      <p>Qualora l’Utente non intenda accettare il nuovo termine o la fornitura sia divenuta impossibile, potrà chiedere il rimborso di quanto pagato che verrà accreditato tempestivamente nelle stesse modalità di pagamento utilizzate dall’Utente per l’acquisto al massimo entro 14 giorni dalla data in cui il Titolare è venuto a conoscenza della richiesta di rimborso.</p>
      </li>
      <li>
      <h4>Esclusione del diritto di recesso di Utenti non Consumatori</h4>
      <p>All’Utente non Consumatore non è riconosciuto il diritto di recesso dal contratto di vendita o fornitura dei Prodotti. L’Utente riconosce espressamente che l’indicazione nell’ordine di un numero di partita IVA lo qualifica automaticamente come Utente non Consumatore, per il quale non è previsto il diritto di recesso. </p>
      </li>
      <li>
      <h4>
      Diritto di recesso di Utenti Consumatori dall’acquisto di Prodotti materiali
      </h4>
      <p>
      Il solo Utente Consumatore ha diritto di recedere senza alcuna penalità e senza specificare il motivo entro il termine di
      30 giorni decorrente dalla data di ricevimento del Prodotto, mediante l’invio di una comunicazione scritta all’indirizzo e-mail
      <a href="mailto:info@zwan.it">info@zwan.it</a>, utilizzando il modulo facoltativo di recesso di cui al successivo articolo o una qualsiasi altra dichiarazione scritta.
      </p>
      <p>
      Nel caso di consegna separata di più Prodotti, ordinati dall’Utente Consumatore con un solo ordine, il termine di
      30 giorni per l’esercizio del diritto di recesso decorre dal giorno in cui è stato ricevuto l’ultimo Prodotto.
      </p>
      <p>In caso di recesso, l’Utente Consumatore dovrà restituire il Prodotto al Titolare, senza indebito ritardo e in ogni caso entro e non oltre 14 giorni dalla data in cui ha comunicato la sua decisione di recedere dal contratto. L’Utente Consumatore sosterrà solo il costo diretto della restituzione del Prodotto, a meno che il Titolare non abbia dichiarato di farsene carico.</p>
      <p>In caso di recesso esercitato correttamente, il Titolare rimborserà i pagamenti ricevuti dall’Utente, nelle stesse modalità di pagamento utilizzate dall’Utente per la transazione iniziale, senza indebito ritardo e comunque entro 14 giorni dal giorno in cui l’Utente ha comunicato di voler recedere dal contratto. </p>
      <p>Il Titolare non è tenuto a rimborsare i costi di consegna, qualora l’Utente Consumatore abbia scelto espressamente un tipo di consegna diversa dal tipo meno costoso di consegna offerto dal Titolare.</p>
      <p>Il Titolare, salvo che abbia offerto di ritirare egli stesso il Prodotto, può trattenere il rimborso finché non abbia ricevuto il Prodotto oppure finché l’Utente Consumatore non abbia dimostrato di averlo rispedito.</p>
      <p>Il Titolare non accetterà la restituzione nel caso in cui il Prodotto risulti malfunzionante a causa di utilizzo improprio, negligenza, danni o alterazioni fisiche, estetiche o superficiali, manomissioni o manutenzione impropria o usura e logorio, assenza di elementi integranti del prodotto (accessori, parti, etc.). In tali casi, il Titolare provvederà a restituire al mittente il Prodotto acquistato, addebitando allo stesso le spese di spedizione.</p>
      <p>L’Utente Consumatore prende atto e accetta che il recesso da un abbonamento sottoscritto non comporta la restituzione degli importi relativi ai Prodotti già ricevuti.</p>
      </li>
      <li>
      <h4>
      Diritto di recesso di Utenti Consumatori dall’acquisto di
      Prodotti digitali
      e
      servizi
      </h4>
      <p>
      Il solo Utente Consumatore ha diritto di recedere senza alcuna penalità e senza specificare il motivo entro il termine di
      30 giorni decorrente dalla data di conclusione del contratto, mediante l’invio di una comunicazione scritta all’indirizzo e-mail
      <a href="mailto:info@zwan.it">info@zwan.it</a>, utilizzando il modulo facoltativo di recesso di cui al successivo articolo o una qualsiasi altra dichiarazione scritta.
      </p>
      <p>In caso di recesso esercitato correttamente, il Titolare rimborserà all’Utente Consumatore i pagamenti ricevuti, nelle stesse modalità di pagamento utilizzate dall’Utente per la transazione iniziale, senza indebito ritardo e comunque entro 14 giorni dalla data in cui l’Utente ha comunicato di recedere dal contratto. </p>
      <p>L’Utente Consumatore prende atto e accetta, ai sensi dell’art. 59, lett. o) del Codice del Consumo, di perdere il diritto di recesso dopo aver scaricato o utilizzato un Prodotto digitale.</p>
      <p>L’Utente Consumatore prende atto e accetta, ai sensi e per gli effetti dell’art. 59, lett. a) del Codice del Consumo, di perdere il diritto di recesso dopo la completa prestazione di un servizio. Laddove la prestazione del servizio non sia stata completamente eseguita e l’Utente Consumatore voglia recedere dal contratto, egli resta comunque obbligato, ai sensi dell’art. 57, comma 3 del Codice del Consumo, a versare al Titolare un importo proporzionale a quanto è stato eseguito fino al momento in cui ha esercitato il diritto di recesso.</p>
      <p>L’Utente Consumatore prende atto e accetta che il recesso da un abbonamento sottoscritto non comporta la restituzione degli importi relativi ai Prodotti già utilizzati o erogati.</p>
      </li>
      <li>
      <h4>Esclusione del diritto di recesso dell’Utente Consumatore</h4>
      <p>Il diritto di recesso dal contratto di vendita o di fornitura dei Prodotti da parte dell’Utente Consumatore è escluso relativamente:</p>
      <ul>
      <li>alla fornitura di giornali, periodici e riviste ad eccezione dei contratti di abbonamento per la fornitura di tali pubblicazioni</li>
      </ul>
      <p>
      Per maggiori chiarimenti contattare il Titolare all’indirizzo e-mail
      <a href="mailto:info@zwan.it">info@zwan.it</a>
      o chiamando il servizio clienti al numero +390640060079
      .
      </p>
      </li>
      <li>
      <h4>Modulo facoltativo per esercitare il diritto di recesso</h4>
      <p></p>
      L’Utente può recedere utilizzando il seguente modulo che dovrà essere compilato in ogni sua parte e inviato all’indirizzo e-mail
      <a href="mailto:info@zwan.it">info@zwan.it</a>
      prima della scadenza del periodo di recesso:
      <p>
      <i>
      Con la presente, comunico il recesso dal contratto di vendita o fornitura relativo al seguente prodotto __________
      <br />
      Ordine numero:_______
      <br />
      Ordinato il: _______
      <br />
      Nome e Cognome: _______
      <br />
      Indirizzo: ______
      <br />
      E-mail associata all’account da cui è stato effettuato l’ordine: ____________________
      <br />
      Data: __________
      <br />
      </i>
      </p>
      </li>
      <li>
      <h4>Garanzia dei Prodotti materiali per Utenti non Consumatori</h4>
      <p>In relazione ai Prodotti materiali, agli Utenti non Consumatori saranno applicate le garanzie per i vizi della cosa venduta, la garanzia per difetto di qualità promesse ed essenziali e le altre garanzie previste dal codice civile con i relativi termini, decadenze e limitazioni (artt. 1490 e ss. c.c.).</p>
      </li>
      <li>
      <h4>Garanzia di conformità dei Prodotti materiali per Utenti Consumatori</h4>
      <p>Agli Utenti Consumatori viene riconosciuta la garanzia di conformità, prevista dagli artt. 129 e seguenti del Codice del Consumo, per tutti i Prodotti materiali venduti tramite l’Applicazione, fatta eccezione per le ipotesi di esclusione previste dall’art.128 del Codice del Consumo.</p>
      <p>Il Titolare è responsabile nei confronti dell’Utente Consumatore di qualsiasi difetto di conformità che si manifesta entro due anni dalla consegna. Durante il primo anno, l’Utente Consumatore non è tenuto a dimostrare che il difetto esisteva al momento della consegna in quanto si presume che esistesse già a tale data, a meno che tale ipotesi sia incompatibile con la natura del Prodotto Materiale o con la natura del difetto di conformità.</p>
      <p>
      Qualora l’Utente Consumatore intenda usufruire dei rimedi previsti dalla garanzia di conformità, dovrà inviare una comunicazione scritta all’indirizzo e-mail
      <a href="mailto:info@zwan.it">info@zwan.it</a>
      o chiamando il servizio clienti al numero +390640060079
      .
      </p>
      <p>Il Titolare darà tempestivo riscontro e indicherà all’Utente Consumatore la procedura da seguire.</p>
      <p>Per quanto non previsto dalla presente clausola, si applicano gli articoli dal 128 al 135-septies del Codice del Consumo e gli articoli del del codice civile in tema di formazione, validità ed efficacia dei contratti, comprese le conseguenze della risoluzione del contratto e il diritto al risarcimento del danno.</p>
      </li>
      <li>
      <h4>Garanzia di conformità di Prodotti digitali per Utenti Consumatori</h4>
      <p>Agli Utenti Consumatori viene riconosciuta la garanzia di conformità, prevista dagli artt. 135-decies e seguenti del Codice del Consumo, per tutti i Prodotti digitali venduti tramite l’Applicazione, fatta eccezione per le ipotesi di esclusione previste dall’art.135-novies del Codice del Consumo.</p>
      <p>Il Titolare è responsabile per i difetti di conformità che si manifestino entro due anni dalla data di fornitura. Eventuali difetti di conformità, devono essere denunciati dall’Utente consumatore entro ventisei mesi dalla data di fornitura o dall’ultimo atto di fornitura. Quando il contratto di vendita prevede che i Prodotti digitali siano forniti per un determinato periodo di tempo, l’obbligo di assicurare la conformità permane per l’intera durata di tale periodo.</p>
      <p>
      Qualora l’Utente Consumatore intenda usufruire dei rimedi previsti dalla garanzia di conformità, dovrà inviare una comunicazione scritta all’indirizzo e-mail
      <a href="mailto:info@zwan.it">info@zwan.it</a>
      o chiamando il servizio clienti al numero +390640060079
      . Il Titolare darà tempestivo riscontro alla comunicazione del presunto difetto di conformità e indicherà all’Utente Consumatore la specifica procedura da seguire.
      Per quanto non previsto dalla presente clausola, si applicano gli articoli dall’art.dal Art. 135-octies all’art. 135-vicies bis del Codice del Consumo e gli articoli del codice civile in tema di formazione, validità ed efficacia dei contratti, comprese le conseguenze della risoluzione del contratto e il diritto al risarcimento del danno.
      </p>
      </li>
      <li>
      <h4>Diritti di Proprietà Industriale e Intellettuale</h4>
      <p>Il Titolare dichiara di essere titolare e/o licenziatario di tutti i diritti di proprietà intellettuale relativi e/o afferenti all’Applicazione e/o ai Contenuti disponibili sull’Applicazione. Pertanto, tutti i marchi, figurativi o nominativi e tutti gli altri segni, nomi commerciali, marchi di servizio, marchi denominativi, denominazioni commerciali, illustrazioni, immagini, loghi, contenuti relativi all’Applicazione sono e rimangono di titolarità del Titolare o dei suoi licenziatari e sono protetti dalle leggi vigenti sui marchi e dai relativi trattati internazionali.</p>
      <p>Le Condizioni non concedono all’Utente alcuna licenza d’uso relativa a singoli contenuti e/o materiali ivi disponibili, se non diversamente disciplinato.</p>
      <p>Eventuali riproduzioni in qualunque forma dei testi esplicativi e dei Contenuti dell’Applicazione, qualora non autorizzate, saranno considerate violazioni del diritto proprietà intellettuale ed industriale del Titolare.</p>
      </li>
      <li>
      <h4>Esclusione della garanzia</h4>
      <p>L’Applicazione viene fornita ”così come è” e ”come è disponibile” e il Titolare non fornisce alcuna garanzia esplicita o implicita in relazione all’Applicazione, né fornisce alcuna garanzia che l’Applicazione potrà soddisfare le esigenze degli Utenti o che non avrà mai interruzioni o sarà priva di errori o che sarà priva di virus o bug.</p>
      <p>Il Titolare si adopererà per assicurare che l’Applicazione sia disponibile ininterrottamente 24 ore al giorno, ma non potrà in alcun modo essere ritenuto responsabile se, per qualsiasi motivo, l’Applicazione non fosse accessibile e/o operativa in qualsiasi momento o per qualsiasi periodo. L’accesso all’Applicazione può essere sospeso temporaneamente e senza preavviso in caso di guasto del sistema, manutenzione, riparazioni o per ragioni del tutto estranee alla volontà del Titolare o per eventi di forza maggiore.</p>
      </li>
      <li>
      <h4>Limitazione della Responsabilità</h4>
      <p>Il Titolare non potrà ritenersi responsabile verso l’Utente, salvo il caso di dolo o colpa grave, per disservizi o malfunzionamenti connessi all’utilizzo della rete internet al di fuori del controllo proprio o di suoi fornitori.</p>
      <p>
      Il Titolare non sarà inoltre responsabile in merito a danni, perdite e costi subiti dall’Utente a seguito della mancata esecuzione del contratto per cause a lui non imputabili, avendo l’Utente diritto soltanto alla eventuale restituzione integrale del prezzo corrisposto e degli eventuali oneri accessori sostenuti.
      </p>
      <p>
      Il Titolare non assume alcuna responsabilità per l’eventuale uso fraudolento e illecito che possa essere fatto da parte di terzi delle carte di credito e altri mezzi di pagamento,
      in quanto non entra in alcun modo in contatto con i dati di pagamento utilizzati (numero delle carte di credito, nome del titolare, password, etc.)
      </p>
      <p>Il Titolare non sarà responsabile per:</p>
      <ul>
      <li>eventuali perdite di opportunità commerciale e qualsiasi altra perdita, anche indiretta, eventualmente subita dall’Utente che non siano conseguenza diretta della violazione del contratto da parte del Titolare</li>
      <li>errato o inidoneo utilizzo dell’Applicazione da parte degli Utenti o di terzi</li>
      <li>l’emissione di documenti o dati fiscali errati a causa di errori relativi ai dati forniti dall’Utente, essendo quest’ultimo l’unico responsabile del corretto inserimento</li>
      </ul>
      <p>In nessun caso il Titolare potrà essere ritenuto responsabile per una somma superiore al doppio del costo pagato dall’Utente.</p>
      </li>
      <li>
      <h4>Forza maggiore</h4>
      <p>Il Titolare non potrà essere considerato responsabile per il mancato o ritardato adempimento delle proprie obbligazioni, per circostanze al di fuori del controllo ragionevole del Titolare dovute ad eventi di forza maggiore o, comunque, ad eventi imprevisti ed imprevedibili e, comunque, indipendenti dalla sua volontà.</p>
      <p>L’adempimento delle obbligazioni da parte del Titolare si intenderà sospeso per il periodo in cui si verificano eventi di forza maggiore.</p>
      <p>Il Titolare compirà qualsiasi atto in suo potere al fine di individuare soluzioni che consentano il corretto adempimento delle proprie obbligazioni nonostante la persistenza di eventi forza maggiore.</p>
      </li>
      <li>
      <h4>Collegamento a siti di terzi</h4>
      <p>L’Applicazione potrebbe contenere collegamenti a siti/applicazioni di terzi. Il Titolare non esercita alcun controllo su di essi e, pertanto, non è in alcun modo responsabile per i contenuti di questi siti/applicazioni.</p>
      <p>Alcuni di questi collegamenti potrebbero rinviare a siti/applicazioni di terzi che forniscono servizi attraverso l’Applicazione. In questi casi, ai singoli servizi si applicheranno le condizioni generali per l’uso del sito/applicazione e per la fruizione del servizio predisposte dai terzi, rispetto alle quali il Titolare non assume alcuna responsabilità.</p>
      </li>
      <li>
      <h4>Privacy</h4>
      <p>
      La tutela e il trattamento dei dati personali avverranno in conformità all’Informativa Privacy che può essere consultata alla pagina
      <a href="https://www.zwan.it/privacy-policy/">https://www.zwan.it/privacy-policy/</a>
      </p>
      </li>
      <li>
      <h4>Legge applicabile e foro competente</h4>
      <p>Le Condizioni sono soggette alla legge italiana.</p>
      <p>Per gli utenti non Consumatori, per ogni controversia relativa alla Applicazione, esecuzione e interpretazione delle presenti Condizioni è competente il foro del luogo in cui ha sede il Titolare.</p>
      <p>Per gli Utenti Consumatori ogni controversia relativa all’applicazione, esecuzione e interpretazione delle presenti Condizioni sarà devoluta al foro del luogo in cui l’Utente Consumatore risiede o ha eletto domicilio, se ubicati nel territorio dello Stato Italiano, salva la facoltà per l’Utente Consumatore di adire un giudice diverso da quello del ”foro del consumatore” ex art. 66 bis del Codice del Consumo, competente per territorio secondo uno dei criteri di cui agli artt. 18, 19 e 20 del codice processuale civile.</p>
      <p>È fatta salva l’applicazione agli Utenti Consumatori che non abbiano la loro residenza abituale in Italia delle disposizioni eventualmente più favorevoli e inderogabili previste dalla legge del paese in cui essi hanno la loro residenza abituale, in particolare in relazione al termine per l'esercizio del diritto di recesso, al termine per la restituzione dei Prodotti, in caso di esercizio di tale diritto, alle modalità e formalità della comunicazione del medesimo e alla garanzia legale di conformità.</p>
      </li>
      <li>
      <h4>Risoluzione delle controversie online per Utenti Consumatori</h4>
      <p>L’Utente Consumatore residente in Europa deve essere a conoscenza del fatto che la Commissione Europea ha istituito una piattaforma online che fornisce uno strumento di risoluzione alternativa delle controversie. Tale strumento può essere utilizzato dall’Utente Consumatore per risolvere in via non giudiziale ogni controversia relativa a e/o derivante da contratti di vendita di beni e fornitura di servizi stipulati in rete. Di conseguenza, l’Utente Consumatore può usare tale piattaforma per la risoluzione di ogni disputa nascente dal contratto stipulato online. La piattaforma è disponibile al seguente indirizzo: ec.europa.eu/consumers/odr/</p>
      </li>
      </ol>
      
      <p class="text-right">Data 24/01/2022</p>

    </v-container>
  </div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'

export default {
  
  name: 'OrderPlaced',

  mixins: [],

  components: {

  },

  data: () => ({

  }),

  computed: 
  {
    
  },

  methods:
  {
    scrollToTop()
    {
      goTo(0)
    }
  },

  mounted()
  {
    this.scrollToTop()
  },

  watch:
  {
    
  }

}
</script>
