<template>

	<v-row class="my-10">
    <!-- image -->
    <v-col cols="12"
           md="3">
      <v-img :src="cartImage" max-height="100" contain/>
    </v-col>
    <!-- description -->
    <v-col cols="12"
           md="6"
           :class="device.isSm ? 'text-center':''">
      
      <span class="text-h5 font-weight-bold">
        {{ item.product.name }}
      </span>
      
      x {{ item.quantity }}

      <p class="mt-1" v-if="item.product.hasManyPrices">
        {{ item.product.price.name }}
      </p>

      <!-- buttons to change quantity -->
      <p class="mt-3">
        <v-btn fab small 
               color="grey lighten-3" 
               :elevation="0"
               class="mr-5"
               @click="lessQuantity">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-btn fab small 
               color="grey lighten-3" 
               :elevation="0"
               @click="moreQuantity"
               v-if="item.canBuyMore()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </p>

    </v-col>
    <!-- price -->
    <v-col cols="12" 
           md="3" 
           class="text-right">
      <span class="text-h4">
        {{ item.price | currency }}
      </span>
    </v-col>
  </v-row>
  
</template>

<script>
import { mapActions } from 'vuex'

import mq from '@/mixins/mq'

export default {
  name: 'CartItem',

  mixins: [ mq ],

  components: {

  },

  data: () => ({
    //
  }),

  props:
  {
    item: {
      type: Object,
      required: true
    }
  },

  computed:
  {
    cartImage()
    {
      return this.item.product.logo || this.item.product.image
    }
  },

  methods:
  {
    ...mapActions({
      addToCart: 'ecommerce/addToCart',
      removeFromCart: 'ecommerce/removeFromCart'
    }),

    moreQuantity()
    {
      this.addToCart(this.item.product)
    },

    lessQuantity()
    {
      this.removeFromCart(this.item.product)
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
