<template>

  <Dialog :status.sync="dialog"
          title="Modifica la tua password"
          :submit-status="canSubmit"
          @submit="submit"
          :loading="isLoading">

    <template #activator="props">
      <v-btn v-bind="props.attrs" v-on="props.on">
        MODIFICA
      </v-btn>
    </template>

    <template #icon>
        <key-icon size="2.5x" class="primary--text"></key-icon>
    </template>
        
    <!-- vecchia password -->
    <v-text-field 
          outlined
          :type="passwordFieldType"
          label="Password attuale"
          v-model="form.old_password"
          @keyup.enter="submit"
          :append-icon="passwordFieldIcon"
          @click:append="togglePasswordFieldType=!togglePasswordFieldType"
          :error="inputErrorClass('old_password')"/>
    
    <!-- nuova password -->
    <v-text-field 
          outlined
          :type="passwordFieldType"
          label="Nuova password"
          v-model="form.password"
          @keyup.enter="submit"
          :error="inputErrorClass('password')"/>
    
    <!-- conferma nuova password -->
    <v-text-field 
          outlined
          :type="passwordFieldType"
          label="Conferma nuova password"
          v-model="form.password_confirmation"
          @keyup.enter="submit"
          :error="inputErrorClass('password_confirmation')"/>

    <Alert v-if="errorMessage"
           type="warning">
      {{ $t(errorMessage) }}
    </Alert>

  </Dialog>
  
</template>

<script>
import API from '@/api'

import { required, sameAs } from '@vuelidate/validators'

import dialog from '@/mixins/dialog'
import vuelidate from '@/mixins/vuelidate'

import Alert from '@/components/UI/Alert'
import Dialog from '@/components/UI/Dialog'

import { KeyIcon } from 'vue-feather-icons'

export default {
  name: 'PasswordDialog',

  mixins: [ dialog, vuelidate ],

  components: {
    Alert,
    Dialog,
    KeyIcon
  },

  data: () => ({
    form: {
      old_password: null,
      password: null,
      password_confirmation: null
    },
    errorMessage: '',
    togglePasswordFieldType: false
  }),

  props:
  {
    
  },

  computed:
  {
    passwordFieldIcon()
    {
      return this.togglePasswordFieldType ? 'mdi-eye-off' : 'mdi-eye'  
    },

    passwordFieldType()
    {
      return this.togglePasswordFieldType ? 'text' : 'password'
    }
  },

  validations ()
  {
    return {
      form: {
        old_password: { required },
        password: { required },
        password_confirmation: {
          required,
          sameAsPassword: sameAs(this.form.password)
        },
      }
    }
  },

  methods:
  {
    submit()
    {
      this.isLoading = true

      this.errorMessage = ""

      API.auth.updatePassword(this.form)
              .then(()=>{

                this.resetForm()

                this.close()

              })
              .catch((err)=>{
                
                let message = err.response.data.message

                this.errorMessage = message

              })
              .finally(()=>{
                
                this.isLoading = false

              })
    },

    resetForm()
    {
      this.form.password = null

      this.form.password_confirmation = null      

      this.form.old_password = null
    },

  },

  mounted()
  {

  },

  watch: {}
};
</script>
