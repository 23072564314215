<template>

	<Dialog :status.sync="dialog"
          title="Modifica il tuo profilo"
          :submit-status="canSubmit"
          @submit="submit"
          :loading="isLoading">

    <template #activator="props">
      <v-btn v-bind="props.attrs" v-on="props.on">
        MODIFICA
      </v-btn>
    </template>

    <template #icon>
      <user-check-icon size="2.5x" class="primary--text"/>
    </template>
        
    <!-- firstname -->
    <v-text-field 
          outlined
          label="Nome"
          v-model="form.firstname"
          @keyup.enter="submit"
          :error="inputErrorClass('firstname')"/>
    
    <!-- lastname -->
    <v-text-field 
          outlined
          label="Cognome"
          v-model="form.lastname"
          @keyup.enter="submit"
          :error="inputErrorClass('lastname')"/>

    <Alert v-if="errorMessage"
           type="warning">
      {{ $t(errorMessage) }}
    </Alert>

  </Dialog>
  
</template>

<script>
import API from '@/api'

import { mapActions, mapGetters } from 'vuex'

import { required } from '@vuelidate/validators'

import dialog from '@/mixins/dialog'
import vuelidate from '@/mixins/vuelidate'

import Alert from '@/components/UI/Alert'
import Dialog from '@/components/UI/Dialog'

import { UserCheckIcon } from 'vue-feather-icons'

export default {
  name: 'ProfileDialog',

  mixins: [ dialog, vuelidate ],

  components: {
    Alert,
    Dialog,
    UserCheckIcon
  },

  data: () => ({
    form: {
      firstname: null,
      lastname: null,
    },
    errorMessage: ''
  }),

  props:
  {
    
  },

  computed:
  {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  validations ()
  {
    return {
      form: {
        firstname: { required },
        lastname: { required },
      }
    }
  },

  methods:
  {
    ...mapActions({
      refreshUserData: 'auth/refreshUserData'
    }),

    submit()
    {
      this.isLoading = true

      this.errorMessage = ""

      API.auth.updateUser(this.form)
              .then(()=>{

                this.refreshUserData()

                this.close()

              })
              .catch((err)=>{
                
                let message = err.response.data.message

                this.errorMessage = message

              })
              .finally(()=>{
                
                this.isLoading = false

              })
    },

    syncUser()
    {
      if( this.user )
      {
        this.form.firstname = this.user.firstname

        this.form.lastname = this.user.lastname
      }
    }

  },

  mounted()
  {
    this.syncUser()
  },

  watch: 
  {
    user()
    {
      this.syncUser()
    }
  }
};
</script>
