<template>

	<v-btn icon @click="goToCart">
    <shopping-bag-icon :color="cartColor"/>
    <v-badge :content="cartItemsCount"
             v-if="!cartIsEmpty"/>
  </v-btn>
  
</template>

<script>
// @see https://vue-feather-icons.egoist.sh/
import { ShoppingBagIcon } from 'vue-feather-icons'

import { mapGetters } from 'vuex'

import utility from '@/mixins/utility'

export default {
  name: 'CartButton',

  mixins: [ utility ],

  components: {
    ShoppingBagIcon
  },

  data: () => ({
    //
  }),

  computed:
  {
    ...mapGetters({
      cartIsEmpty: 'ecommerce/cartIsEmpty',
      cartItemsCount: 'ecommerce/cartItemsCount'
    }),

    cartColor()
    {
      return this.cartIsEmpty ? 'grey' : 'white'
    }
  },

  methods:
  {
    goToCart()
    {
      this.goTo('cart')
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
