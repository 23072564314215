const state = {
    headerIsHidden: false,
    isLoading: false
}

const getters = {

    headerIsHidden(state)
    {
        return state.headerIsHidden
    },

    isLoading(state)
    {
        return state.isLoading
    }

}

const actions = {

    hideHeader({ commit })
    {
        commit('setHeaderIsHidden', true)
    }

}

const mutations = {

    setHeaderIsHidden(state, value)
    {
        state.headerIsHidden = value
    },

    setLoading(state, status=true)
    {
        state.isLoading = status
    },

}

const ui = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default ui