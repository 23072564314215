import _ from 'lodash'
import moment from 'moment'

import utility from '@/mixins/utility'

class Order {

  #data = {}

  #items = []

  #coupon = {}

  #shipping = {}

  constructor(data={})
  {  
    this.#data = data

    this.#coupon = data.coupon

    this.#items = utility.methods.mapModels( data.items, 'orderItem' )
  }

  /**
   * Property getters.
   */
  
  get data()
  {
    return this.#data
  }

  get number()
  {
    return this.#data.number
  }

  get id()
  {
    return this.#data.id
  }

  get items()
  {
    return this.#items
  }

  get date()
  {
    const date = moment(this.#data.created_at)

    return date
  }

  get meta()
  {
    return this.#data.meta
  }

  get shippedAt()
  {
    if( !this.#data.shippedAt )
      return null

    const date = moment(this.#data.shippedAt)
    
    return date
  }

  get shipping()
  {
    return this.#shipping
  }

  get coupon()
  {
    return this.#coupon
  }

  /**
   * Computed properties.
   */
  
  get downloads()
  {
    let downloads = _.chain(this.items)
                     .map('downloads')
                     .flatten()
                     .uniq('id')
                     .value()

    return downloads
  }

  get hasDownloads()
  {
    let productsWithDownloads = this.items.filter((item)=>{
                                  return item.hasDownloads
                                })

    return productsWithDownloads.length > 0
  }
  
  get hasShipping()
  {
    let notDigitalItems = this.items.filter((item)=>{
                                      return item.product && 
                                             !item.product.isDigital
                                    })

    return notDigitalItems.length > 0
  }

  get isCompleted()
  {
    return !this.isToShip
  }
  
  get isShipped()
  {
    return !!this.#data.shipped_at
  }

  get isToShip()
  {
    return this.hasShipping && !this.isShipped
  }

  get status()
  {
    if( this.isToShip )
      return `In lavorazione`

    return `Completato`
  }

  get total()
  {
    let total = 0

    this.items.forEach((item)=>{
      total += item.total
    })

    return total
  }

}

export default Order