var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',[_c('v-data-table',{staticClass:"orders-table",attrs:{"headers":_vm.headers,"items":_vm.orders,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header.shippedAt",fn:function(){return [_c('package-icon')]},proxy:true},{key:"header.hasSubscriptions",fn:function(){return [_c('refresh-cw-icon')]},proxy:true},{key:"header.hasDownloads",fn:function(){return [_c('download-icon')]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(" #"+_vm._s(item.id)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date.format('DD/MM/Y'))+" ")]}},{key:"item.shippedAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(!item.hasShipping)?_c('span',[_vm._v("–")]):(item.isToShip)?_c('clock-icon'):_c('check-square-icon')],1)]}}],null,true)},[(!item.hasShipping)?_c('span',[_vm._v(" Questo ordine non necessita di spedizione. ")]):(item.isToShip)?_c('span',[_vm._v(" Stiamo preparando la tua spedizione. ")]):_c('span',[_vm._v(" Il prodotto è statoo spedito. ")])])]}},{key:"item.hasDownloads",fn:function(ref){
var item = ref.item;
return [(item.hasDownloads)?_c('v-chip',[_vm._v(" "+_vm._s(item.downloads.length)+" ")]):_c('span',[_vm._v("–")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('TableRow',{attrs:{"order":item}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }