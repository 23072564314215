<template>
  <div>

    <v-text-field
        v-model="form.cardholder"
        label="Nome riportato sulla carta"
        required
        outlined
        :error="inputErrorClass('cardholder')" 
      ></v-text-field>

    <div id="stripe-card"></div>

    <!--
    <v-alert type="warning" v-if="error">
      {{ error }}
    </v-alert>

    <v-alert type="warning" v-else-if="firstFormError">
      {{ $t(`validations.${firstFormError}`) }}
    </v-alert>
    -->

  </div>
</template>

<style lang="scss">
  #stripe-card {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    padding: 18px 12px;
  }
</style>

<script>
import { mapMutations, mapGetters } from 'vuex'

import { required } from '@vuelidate/validators'

import vuelidate from '@/mixins/vuelidate'

export default {

  name: 'CreditCardForm',

  mixins: [ vuelidate ],
  
  components: {},
  
  data() {
    return {
      card: null,
      cardComplete: false,
      error: '',
      intentToken: null,
      form: {
        cardholder: null
      }
    }
  },

  props: {
    /*
    user: {
      type: User,
      required: true
    }
    */
  },
  
  computed: {
    ...mapGetters({
      'stripe': 'ecommerce/stripe',
    }),
    canSave()
    {
      return (this.canSubmit && 
              this.card && 
              this.cardComplete) ? true : false
    },
    userId()
    {
      return this.user.id
    }
  },

  validations ()
  {
    return {
      form: {
        cardholder: { required }
      }
    }
  },
  
  methods: {
    
    ...mapMutations({
      'growl': 'ui/growl'
    }),

    checkStripe()
    {
      if( !this.stripe )
        return

      if(!this.card)
        this.initCard()
    },
    
    initCard()
    {
      /**
       * @see https://stripe.com/docs/js/appendix/style
       */
      const style = {
              base: {
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '16px',
                fontFamily: 'Roboto, sans-serif',
                //iconColor: '#fff',
              },
              complete: {
                //color: this.$vuetify.theme.themes.light.success
              }
            },
            opt = { hidePostalCode:true, style },
            elements = this.stripe.elements()

      this.card = elements.create('card', opt)

      // Input error listener
      this.card.on('change', (event)=>{
        
        this.cardComplete = event.complete
        
        if (event.error)
          this.error = event.error.message
        
        else
          this.error = ''

        this.emitUpdate()

      })

      this.card.mount('#stripe-card')
    },

    emitUpdate()
    {
      this.$emit('update', { 
          status: this.canSave,
          card: this.card,
          cardholder: this.form.cardholder,
          cardComplete: this.cardComplete
        })
    },
    
    reset()
    {
      this.loading = false
      
      this.loadingMessage = ''
      
      this.card.clear()
      
      this.form.cardholder = null
      
    },

  },
  
  watch: {
    form:
    {
      deep: true,
      handler()
      {
        this.emitUpdate()
      }
    },
    stripe()
    {
      this.checkStripe()
    }
  },

  mounted()
  {
    this.checkStripe()
  },

}
</script>