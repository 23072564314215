import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
		dark: false,
		themes: {
			light: {
				primary: '#0071e3',
				secondary: '#06c',
				anchor: '#0071e3',
			},
		},
		options: {
			customProperties: true,
		},
	}

export default new Vuetify({
	theme
});

export { theme }