import moment from 'moment'

import utility from '@/mixins/utility'

class Subscription {

  #data = {}

  #product = null;

  #price = null;

  constructor(data={})
  {  
    this.#data = data

    if( data.product )
      this.#product = utility.methods.mapModel( data.product, 'product' )

    if( data.price )
      this.#price = utility.methods.mapModel( data.price, 'price' )
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get endsAt()
  {
    return this.#data.ends_at ? moment(this.#data.ends_at) : null
  }

  get name()
  {
    return this.#data.name
  }

  get product()
  {
    return this.#product
  }

  get price()
  {
    return this.#price
  }
  

  /**
   * Computed properties.
   */
  
  get hasEnd()
  {
    return this.#data.ends_at !== null
  }

  get hasEnded()
  {
    return this.endsAt ? this.endsAt.isBefore(moment()) : false
  }

  get isEnding()
  {
    return this.endsAt ? this.endsAt.isAfter(moment()) : false
  }

  get status()
  {
    switch( this.statusCode )
    {
      case -1: return 'cancellato'
      default: return 'attivo'
    }
  }

  get statusCode()
  {
    if( this.hasEnd )
      return -1

    return 1
  }
  
}

export default Subscription