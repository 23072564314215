<template>
  <div>

    <ProductHeader :product="product"
                   @price="onHeaderPriceClick"/>

    <div class="grey lighten-3">
      <v-container class="text-center">
        <info-icon class="primary--text mr-2" 
                   style="margin-bottom:-6px"/>
        Lo sapevi che le spese di spedizione sono gratuite? Approfittane subito!
      </v-container>
    </div>

    <v-container class="pt-10">

      <v-row>
        
        <v-col cols="12"
               sm="6"
               v-if="product.image"
               class="mb-5">
          <v-img :src="product.image"
                 contain
                 min-height="200"
                 :max-height="device.isSm ? 200 : 'auto'"
                 max-width="100%"/>
        </v-col>
        
        <v-col cols="12" 
               sm="6"
               :offset="colOffset">
          
          <p class="text-h5 primary--text"
             v-if="product.headline">
            {{ product.headline }}
          </p>
          
          <p class="text-h3">
            {{ product.isSubscription ? 'Abbonati a' : 'Acquista' }}
            <span class="font-weight-bold">{{ product.name }}</span>
          </p>

          <div v-html="product.description"></div>

          <Alert v-if="!product.active"
                 type="warning"
                 class="text-h6 mt-10">
            Questo prodotto non &egrave; pi&ugrave; disponibile per la vendita.     
          </Alert>

          <div v-else>

            <PricePanel class="mt-10"
                        v-for="price in product.prices"
                        :key="price.id"
                        :price="price"
                        :highlighted="isSelected(price)"
                        @click="setPrice(price)"/>

            <Panel class="mt-10 pt-10 text-center grey lighten-3" clean>

              <v-btn color="primary" x-large
                     ref="checkoutButton"
                     @click="checkout"
                     :disabled="!canCheckout">
                Continua 
                <v-icon class="ml-3">mdi-arrow-right</v-icon>
              </v-btn>

              <p class="mt-5 px-10 font-weight-light">
                <span v-if="!product.isDigital">
                  I dettagli della spedizione per la tua zona verranno mostrati al momento del pagamento.
                </span>
                <span v-else>
                  Stai acquistando un prodotto digitale, non appena completato il pagamento avrai immediatamente accesso ai contenuti.
                </span>
              </p>

            </Panel>

          </div>

          <v-row class="text-center mt-10">
            <v-col v-if="!product.isDigital">
              <v-card flat>
                <v-card-text>
                  <v-icon size="45">mdi-package</v-icon>
                  <p class="mt-3">Consegna rapida con corriere espresso</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card flat
                      @click="$crisp.do('chat:open')">
                <v-card-text>
                  <v-icon size="45">mdi-chat</v-icon>
                  <p class="mt-3">Hai domande? Contattaci!</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-col>

      </v-row>
      
    </v-container>

  </div>
</template>

<script>
import API from '@/api'

import mq from '@/mixins/mq'
import slug from '@/mixins/slug'

import Product from '@/models/product'

import Alert from '@/components/UI/Alert'
import Panel from '@/components/UI/Panel'
import ProductHeader from '@/components/products/Header'
import PricePanel from '@/components/products/Price'

import { InfoIcon } from 'vue-feather-icons'

import { mapActions, mapMutations } from 'vuex'
import goTo from 'vuetify/lib/services/goto'

export default {
  
  name: 'Product',

  mixins: [ mq, slug ],

  components: {
    Alert,
    InfoIcon,
    Panel,
    ProductHeader,
    PricePanel
  },

  data: () => ({
    product: {
      type: Product,
      default: ()=> new Product
    },
    selectedPrice: {}
  }),

  computed:
  {
     canCheckout()
     {
      return this.selectedPrice.id
     },

     colOffset()
     {  
        if( this.device.isSm )
          return 0

        let offset = this.product.image ? 0 : 3

        return offset
     }
  },

  methods:
  {
    ...mapActions({
      addToCart: 'ecommerce/addToCart'
    }),

    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    load()
    {
      this.setLoading()

      API.products.one(this.slug)
                  .then(({ data })=>{

                    this.product = new Product(data.data)

                  })
                  .finally(()=>this.setLoading(false))
    },

    isSelected(price)
    {
      return this.selectedPrice.id === price.id
    },

    onHeaderPriceClick(price)
    {
      this.setPrice(price)

      if( this.canCheckout )
        this.checkout()
    },

    setPrice(price)
    {
      this.selectedPrice = price
    },

    scrollToButton()
    {
      if(this.$refs.checkoutButton)
        goTo(this.$refs.checkoutButton)
    },

    checkout()
    {
      let product = this.product

          product.selectPrice(this.selectedPrice)

      this.addToCart(product)

      this.$router.push({ name:'cart' })
    }
  },

  mounted()
  {
    this.load()
  },

  watch:
  {
    product(product)
    {
      // if product has only one price autoselect it
      if( product.hasPrices && !product.hasManyPrices )
        this.selectedPrice = product.prices[0]
    },
    selectedPrice(price)
    {
      if(price)
        this.scrollToButton()
    }
  }

}
</script>
