/**
 * @see http://numeraljs.com/
 */

const plugin = require("numeral")

const numeral = (value, format="0,0") => {
    return plugin(value).format(format)
}

export default numeral