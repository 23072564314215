<template>

	<v-row>
    <v-col cols="12" 
           md="4"
           :class="device.isSm ? 'text-center' : ''">
      <p class="text-h5">
        I tuoi indirizzi 
        <AddressDialog/>
      </p>
    </v-col>
    <v-col cols="12" md="8">

      <Alert type="warning"
              v-if="!user.hasAddress">
        Non hai ancora impostato il tuo indirizzo.
      </Alert>

      <AddressPanel highlighted
                    :address="user.address"
                    v-if="user.hasAddress">
        <template #bottom>
          <div class="text-right mb-0">
            <v-btn icon v-if="isLoading" :loading="true"></v-btn>
            <div v-else>
              <!-- edit -->
              <AddressDialog :defaults="user.address.data"
                             :address-id="user.address.id"
                             title="Modifica indirizzo">
                <template #activator="props">
                  <v-btn :elevation="0" 
                         v-bind="props.attrs" 
                         v-on="props.on">
                    Modifica
                  </v-btn>
                </template>
              </AddressDialog>
              <!-- favourite mark -->
              <v-btn :elevation="0" 
                     class="ml-3"
                     color="orange lighten-3 warning--text">
                <v-icon class="mr-1" small>mdi-star</v-icon>
                Preferito
              </v-btn>
            </div>
          </div>
        </template>
      </AddressPanel>

      <AddressPanel v-for="address in user.otherAddresses" 
                    :key="address.id"
                    :address="address"
                    class="mt-5">
        <template #bottom>
          <div class="text-right mb-0">
            <v-btn icon v-if="isLoading" :loading="true"></v-btn>
            <div v-else>
              <!-- delete -->
              <AddressDelete :address="address"/>
              <!-- edit -->
              <AddressDialog :defaults="address.data"
                             :address-id="address.id"
                             title="Modifica indirizzo">
                <template #activator="props">
                  <v-btn :elevation="0" 
                         class="ml-3"
                         v-bind="props.attrs" 
                         v-on="props.on">
                    Modifica
                  </v-btn>
                </template>
              </AddressDialog>
              <!-- mark as favourite -->
              <v-btn :elevation="0" 
                     class="ml-3"
                     @click="markAsFavourite(address)">
                <v-icon class="mr-1" small>mdi-star-outline</v-icon>
                <span v-if="!device.isSm">Preferito</span>
              </v-btn>
            </div>
          </div>
        </template>
      </AddressPanel>

    </v-col>
  </v-row>
  
</template>

<script>
import API from '@/api'

import { mapActions, mapGetters } from 'vuex'

import mq from '@/mixins/mq'

import Alert from '@/components/UI/Alert'
import AddressDelete from '@/components/addresses/Delete'
import AddressDialog from '@/components/addresses/Dialog'
import AddressPanel from '@/components/addresses/Panel'

export default {
  
  name: 'Address',

  mixins: [ mq ],

  components: {
    Alert,
    AddressDelete,
    AddressDialog,
    AddressPanel
  },

  data: () => ({
    isLoading: false
  }),

  computed:
  {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  methods:
  {
    ...mapActions({
      refreshUserData: 'auth/refreshUserData'
    }),

    markAsFavourite(address)
    {
      this.isLoading = true

      API.auth.favouriteAddress(address.id)
              .then(()=>{

                this.refreshUserData()
                    .then(()=>this.isLoading = false)

              })
    },

  },

  mounted()
  {

  },

  watch: {}
};
</script>
