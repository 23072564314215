<template>
  <div>
    <v-container class="py-10" style="max-width:700px">

      <v-row>
        <v-col class="text-center">

          <!-- error request -->
          <div v-if="error">
            <p>
                <alert-circle-icon size="5.5x" class="error--text"/>
            </p>  
            <p class="text-h2 mt-10">
              Ops!
            </p>
            <p class="text-h5 mt-5">
              Si &egrave; verificato un errore. Se il problema persiste contatta il nostro servizio clienti.
            </p>
          </div>
          
          <!-- checking request -->
          <div v-if="isLoading"
               class="pt-10">
            <p><v-progress-circular
              indeterminate
              :size="50"
              color="grey"
            ></v-progress-circular></p>
            <p class="text-h5 mt-10">
              Stiamo attivando il tuo account.
            </p>
          </div>

          <!-- success message -->
          <div v-if="success">
            <p>
              <check-circle-icon size="5.5x" class="success--text"/>
            </p>
            <p class="text-h2 mt-10">
              Benvenuto in Zwan  
            </p>
            <p class="text-h5 mt-5">
              Il tuo <strong>Zwan ID</strong> &egrave; attivo e pronto per essere utilizzato.
            </p>
          </div>

        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import API from '@/api'

import { AlertCircleIcon, CheckCircleIcon } from 'vue-feather-icons'

export default {
  
  name: 'OrderPlaced',

  mixins: [],

  components: {
    AlertCircleIcon,
    CheckCircleIcon    
  },

  data: () => ({
    isLoading: false,
    error: false,
    success: false
  }),

  computed: 
  {
    email()
    {
      return this.$route.params.email
    }
  },

  methods:
  {
    checkEmail()
    {
      this.error = false

      this.success = false

      if( !this.email )
      {  
        this.error = true

        return
      }

      this.isLoading = true

      API.auth.verifyEmail(this.email)
              .then(()=>{
                this.success=true
              })
              .catch(()=>{
                this.error=true
              })
              .finally(()=>{
                this.isLoading=false
              })
    }
  },

  mounted()
  {
    this.checkEmail()
  },

  watch:
  {
    
  }

}
</script>
