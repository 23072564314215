<template>

	<v-row class="py-10">

    <v-col cols="3"><!-- --></v-col>

    <v-col cols="9">
      <!-- subtotal -->
      <v-row>
        <v-col>
          <span class="text-h5">
            Subtotale
          </span>
        </v-col>
        <v-col class="text-right">
          <span class="text-h5">{{ cartTotal | currency }}</span>
        </v-col>
      </v-row>
      <!-- shipping costs -->
      <v-row v-if="cartHasShipping">
        <v-col>
          <span class="text-h5">
            Spese di spedizione
          </span>
        </v-col>
        <v-col class="text-right">
          <span class="text-h5 success--text">
            GRATIS
          </span>
        </v-col>
      </v-row>
      <v-divider class="my-10"/>
      <!-- grand total -->
      <v-row>
        <v-col>
          <span class="text-h4 font-weight-bold">
            Totale
          </span>
        </v-col>
        <v-col class="text-right">
          <span class="text-h4 font-weight-bold">
            {{ cartTotal | currency }}
          </span>
        </v-col>
      </v-row>

    </v-col>
  </v-row>
  
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CartTotal',

  mixins: [],

  components: {

  },

  data: () => ({
    //
  }),

  props:
  {

  },

  computed:
  {
    ...mapGetters({
      cartHasShipping: 'ecommerce/cartHasShipping',
      cartTotal: 'ecommerce/cartTotal'
    })
  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch: {}
};
</script>
