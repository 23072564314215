<template>

	<v-card class="rounded-xl"
          hover
          @click="onClick">
    
    <div class="px-10 pt-10">
    
      <p class="text-h5 mb-1">
        Hai bisogno di un consiglio su come migliorare la tua reputazione?
      </p>
    
      <p class="text-h4 primary--text">
        <strong>Chiedilo a Simone!</strong>
      </p>
    
    </div>
    
    <v-img contain
        src="/images/simone.png"
        height="310"
        position="bottom right"/>
        
  </v-card>
  
</template>

<script>
import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

export default {
  name: 'ChatCard',

  mixins: [ mq, utility ],

  components: {
    
  },

  data: () => ({
    
  }),

  props:
  {
    
  },

  computed:
  {
    
  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    }
  },

  mounted()
  {

  },

  watch:
  {

  }
};
</script>
