import utility from '@/mixins/utility'

class Category {

  #data = {}

  #children = []

  #parent = {}

  #products = []

  constructor(data={})
  {  
    this.#data = data

    if( data.parent )
      this.#parent = new Category(data.parent)

    this.#children = utility.methods.mapModels(data.children, 'category')

    this.#products = utility.methods.mapModels(data.products, 'product')
  }

  /**
   * Property getters.
   */
  
  get data()
  {
    return this.#data
  }
  
  get id()
  {
    return this.#data.id
  }

  get active()
  {
    return this.#data.active
  }

  get slug()
  {
    return this.#data.slug
  }

  get name()
  {
    return this.#data.name
  }

  get heading()
  {
    return this.#data.heading
  }

  get image()
  {
    return this.#data.image
  }

  get children()
  {
    return this.#children
  }

  get parent()
  {
    return this.#parent
  }

  get products()
  {
    return this.#products
  }

  /**
   * Computed properties.
   */
  
  get hasChildren()
  {
    return this.#children.length > 0
  }

  get hasProducts()
  {
    return this.#products.length > 0
  }

  /**
   * Methods.
   */
  
  
}

export default Category