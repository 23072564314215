<template>

  <Dialog :status.sync="dialog"
          title="Modifica il tuo avatar"
          :submit-status="canSubmit"
          @submit="submit"
          :loading="isLoading"
          max-width="50vw">

    <template #activator>
      <span></span>
    </template>

    <template #icon>
        <aperture-icon size="2.5x" class="primary--text"></aperture-icon>
    </template>
        
    <v-row class="mt-5">
        <v-col cols="2"
                v-for="avatar in avatars"
                :key="avatar.id"
                class="text-center">
            <v-avatar size="80"
                      class="avatar-gallery"
                      :class="{ selected:isSelected(avatar) }"
                      @click="selectAvatar(avatar)">
                <v-img :src="avatar.url"/>
            </v-avatar>
        </v-col>
    </v-row>

    <Alert v-if="errorMessage"
           type="warning">
      {{ $t(errorMessage) }}
    </Alert>

  </Dialog>
  
</template>

<style lang="scss">
    .avatar-gallery {
        opacity: 0.7;
        border: 3px solid transparent;
        cursor: pointer;
        &.selected {
            opacity: 1;
            border: 3px solid var(--v-primary-base);
        }
        &:hover {
            opacity: 1;
        }
    }
</style>

<script>
import API from '@/api'

import dialog from '@/mixins/dialog'

import Alert from '@/components/UI/Alert'
import Dialog from '@/components/UI/Dialog'

import { ApertureIcon } from 'vue-feather-icons'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AvatarDialog',

  mixins: [ dialog ],

  components: {
    Alert,
    Dialog,
    ApertureIcon
  },

  data: () => ({
    avatars: [],
    errorMessage: '',
    selectedAvatar: null
  }),

  props:
  {
    
  },

  computed:
  {
    ...mapGetters({
        user: 'auth/user'
    }),

    canSubmit()
    {
        return this.selectedAvatar && this.selectAvatar.id !== this.user.avatarId
    },

    newAvatar()
    {
        if( this.selectedAvatar )
            return this.selectedAvatar
        
        return { id: this.user.avatarId }
    }
  },

  methods:
  {
    ...mapActions({
        refreshSpacesAvatar: 'spaces/refreshAvatar',
        refreshUserData: 'auth/refreshUserData'
    }),

    load()
    {
        this.avatars = []

        this.isLoading = true

        API.avatars()
            .then(({ data })=>{
                this.avatars = data.data
            })
            .finally(()=>this.isLoading=false)
    },
    
    submit()
    {
        this.isLoading = true

        API.auth.updateUser({ avatar_id:this.selectedAvatar.id })
                .then(()=>{
                    this.selectedAvatar = null
                    this.close()
                    this.refreshUserData()
                    this.refreshSpacesAvatar()
                })
                .finally(()=>this.isLoading=false)
    },

    isSelected(avatar)
    {
        if( !this.newAvatar.id && avatar.default )
            return true
        
        return ( this.newAvatar.id === avatar.id )
    },

    selectAvatar(avatar)
    {
        if( this.selectedAvatar === avatar )
            this.selectedAvatar = null
        else
            this.selectedAvatar = avatar
    }
  },

  mounted()
  {
    this.load()
  },

  watch: {}
};
</script>
