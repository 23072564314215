import axios from 'axios'

import API from '@/api'

import User from '@/models/user'

import Crypto from '@/plugins/crypto'

const state = {
    user: null,
}

const getters = {

    isLogged: state => !!state.user,
    
    user(state)
    {
      return state.user ? state.user : null
    },

}

const actions = {

    checkLocalUserData({ commit })
    {
      const userData = localStorage.getItem('user')
      
      if (userData)
      {
        let decrypted = Crypto.decrypt(userData)
        
        commit('setUserData', decrypted)
      }
    },

    login ({ commit }, credentials)
    {
      return API.auth.login(credentials)
        .then(({ data }) => {
          commit('setUserData', data)
        })
    },

    logout ({ commit })
    {
      commit('clearUserData')
    },

    refreshUserData({ commit })
    {
      return API.auth.refresh()
        .then(({ data }) => {
          commit('setUserData', data)
        })
        .catch((err)=>{
          console.log(err)
        })
    },

}

const mutations = {

    setUserData (state, userData)
    {
      state.user = new User(userData.user)

      let encrypted = Crypto.encrypt(userData)
      
      localStorage.setItem('user', encrypted)

      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData (state)
    {
      localStorage.removeItem('user')

      state.user = null
      
      axios.defaults.headers.common.Authorization = null
    },

}

const auth = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default auth