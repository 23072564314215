<template>
   <span><!-- state request manager --></span>
</template>

<style lang="scss" scoped>
   
</style>

<script>
  import API from '@/api'

  import { mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'StateRequest',

    mixins: [],

    components: {
      
    },

    data: () => ({

    }),

    props: {
      
    },

    computed: 
    {
      ...mapGetters({
        cart: 'ecommerce/cart'
      }),

      param()
      {
        return this.$route.query.state
      }  
    },

    methods:
    {
      ...mapActions({
        clearCart: 'ecommerce/clearCart',
        storeCart: 'ecommerce/storeCart'
      }),

      ...mapMutations({
        setLoading: 'ui/setLoading',
        setUserData: 'auth/setUserData'
      }),

      load()
      {
        const token = this.param

        if( !token )
          return

        this.setLoading(true)

        API.stateDecode(token)
           .then(({ data })=>{

            // user auth
            if(data.user)
              this.setUserData(data)

            // reset cart?
            if(data.resetCart)
              this.clearCart()

            // cart
            if( data.cart && data.cart.length > 0 )
            {
              this.cart.initProducts(data.cart)

              this.storeCart()
            }
            
           })
           .catch((err)=>{
            console.log(err)
           })
           .finally(this.setLoading(false))
      }
    },

    watch:
    {
      
    },

    mounted()
    {
      this.load()
    }
  }
</script>
