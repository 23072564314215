import Vue from 'vue'
import App from './App.vue'
import { CrispChat, CrispConfig } from '@/plugins/crisp'
import i18n from '@/plugins/vue-i18n'
import router from '@/router'
import store from '@/store'
import useVuelidate from '@vuelidate/core'
import VueMq from 'vue-mq'
import vuetify from '@/plugins/vuetify'
import { SSO } from '@zwan-it/zwan-spaces'

import API from '@/api'

Vue.config.productionTip = false

/**
 * Filters.
 */

import currency from '@/filters/currency'
import numeral from '@/filters/numeral'

Vue.filter('currency', currency)
Vue.filter('numeral', numeral)

/**
 * Crisp
 */
Vue.use(CrispChat, CrispConfig)

/**
 * Vue3mq
 */

Vue.use(VueMq, {
  //preset: 'vuetify',
  breakpoints: {
    xs: 600,
    sm: 960,
    md: 1264,
    lg: 1904,
    xl: Infinity,
  }
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
  setup ()
  {
    return { v$: useVuelidate() }
  },
  created ()
  {
    this.$store.dispatch('auth/checkLocalUserData')

    /**
     * Zwan Spaces login
     */
    const sso = new SSO(),
          token = sso.token

    if (token) {
      API.auth.deploy(token)
              .then(({ data })=>{
                this.$store.commit('auth/setUserData', data)
              })
              .catch((err)=>{
                console.log(err)
              })
    }

    API.setInterceptors()
  }
}).$mount('#app')