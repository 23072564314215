<template>

	<Panel :highlighted="highlighted"
         :withHover="withHover"
         @click="onClick">
    
    <div class="text-h6">
      <v-icon v-if="icon">mdi-{{ icon }}</v-icon>
      {{ address.name }}
    </div>

    <div v-show="expanded" class="mt-3">

      <p class="font-weight-bold">
        {{ address.firstname }} {{ address.lastname }}
      </p>
      
      <p>{{ address.street }}</p>
      
      <p v-if="address.details">{{ address.details }}</p>
      
      <p>
        {{ address.zipcode }} - {{ address.city }} ({{ address.state }})
      </p>

      <slot name="bottom"></slot>
      
    </div>

  </Panel>
  
</template>

<script>
import Address from '@/models/address'

import Panel from '@/components/UI/Panel'

export default {
  name: 'AddressPanel',

  mixins: [],

  components: {
    Panel
  },

  data: () => ({
    //
  }),

  computed:
  {
    
  },

  props: 
  {
    address:
    {
      type: Address,
      required: true
    },

    expanded:
    {
      type: Boolean,
      default: true
    },
    
    highlighted:
    {
      type: Boolean,
      default: false
    },

    icon:
    {
      type: String,
      default: ''
    },

    withHover:
    {
      type: Boolean,
      default: false
    }

  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    } 
  },

  mounted()
  {

  },

  watch: {}
};
</script>
