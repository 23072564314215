<template>

	<div>
    
    <p class="text-h5 font-weight-bold"
       style="min-height:64px">
      Dove dobbiamo spedire i tuoi acquisti?
    </p>

    <AddressForm @update="onFormUpdate"
                 hide="address_name" />

  </div>
  
</template>

<script>
import AddressForm from '@/components/addresses/Form'

export default {
  name: 'Shipping',

  mixins: [],

  components: {
    AddressForm
  },

  data: () => ({
    //
  }),

  computed:
  {
    
  },

  methods:
  {
    onFormUpdate(status)
    {
      this.$emit('update', status)
    }
  },

  mounted()
  {

  },

  watch:
  {
    
  }
};
</script>
