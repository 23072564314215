<template>

	<v-slide-group
    v-model="active"
    center-active
    show-arrows
    class="mt-0"
  >
    <v-slide-item
      v-for="product in products"
      :key="product.id"
    >
      <v-card class="mx-4 transparent pt-2"
              width="200"
              :elevation="0"
              hover
              @click="goToProduct(product)">
        
        <div class="white rounded-xl py-4 px-10 with-shadow-hover">
          <v-img
            height="160"
            :src="product.image"
            contain
          ></v-img>
        </div>

        <v-card-title 
          style="word-break: break-word"
          class="text-subtitle-1 font-weight-bold text-center d-block">
          {{ product.name }}
        </v-card-title>
      </v-card>
    </v-slide-item>
  </v-slide-group>
  
</template>

<script>
//import Product from '@/models/product'

import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

export default {
  name: 'ProductsCarousel',

  mixins: [ mq, utility ],

  components: {
    
  },

  data: () => ({
    active: null
  }),

  props:
  {
    products: {
      //type: Product,
      required: true
    }
  },

  computed:
  {
    
  },

  methods:
  {
    goToProduct(product)
    {
      this.goTo({ 
            name:'product', 
            params:{ slug:product.slug } 
          })
    },
  },

  mounted()
  {

  },

  watch:
  {

  }
};
</script>
