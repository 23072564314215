import { version } from '@/../package'

const STRIPE = {
	apiKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY
}

// @see https://github.com/dansmaculotte/vue-crisp-chat
const CRISP = {
	websiteId: process.env.VUE_APP_CRISP_WEBSITE_ID
}

const SETTINGS = {
	
	APP_NAME: process.env.VUE_APP_APP_NAME || 'Zwan Store',

	APP_VERSION: version,

	CRISP,
	
	STRIPE,

	get BACKEND_URL()
	{
		let url = process.env.VUE_APP_BACKEND_URL || ''

		if( url.substr(-1) != '/' )
			url += '/'

		return url
	}
}

export default SETTINGS