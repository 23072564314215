<template>

	<Panel pointer with-hover
         :highlighted="highlighted"
         @click="onClick">
    <v-row>
      <v-col cols="8">
        <p class="text-h6 font-weight-bold mb-0">
          <v-icon v-if="price.icon"
                  class="mb-1">
            mdi-{{ price.icon }}
          </v-icon>
          {{ price.name }}
        </p>
        <div v-if="price.description"
             v-html="price.description"></div>
        <div v-if="price.infoUrl"
             class="mt-3">
          <v-btn :href="price.infoUrl"
                 target="_blank"
                 text small>
            <v-icon class="mr-2"
                    color="grey">
              mdi-information-outline
            </v-icon>
            Maggiori informazioni
          </v-btn>
        </div>
      </v-col>
      <v-col class="text-right"
             cols="4">
        <span v-if="price.isFree"
              class="d-block text-h6 font-weight-bold success--text">
          GRATIS
        </span>
        <span v-else>
          <span class="d-block text-h6">
            {{ price.value | currency }}
          </span>
          {{ price.recurrencyText }}
        </span>
      </v-col>
    </v-row>
  </Panel>
  
</template>

<script>
import Price from '@/models/price'

import utility from '@/mixins/utility'

import Panel from '@/components/UI/Panel'

export default {
  name: 'PricePanel',

  mixins: [ utility ],

  components: {
    Panel
  },

  data: () => ({
    //
  }),

  props: 
  {
    highlighted:
    {
      type: Boolean,
      default: false
    },

    price:
    {
      type: Price,
      default: () => new Price
    }

  },

  computed:
  {
    
  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
