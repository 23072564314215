<template>

  <Dialog :status.sync="dialog"
          :title="address.name"
          @submit="submit"
          :loading="isLoading"
          submit-label="Elimina"
          submit-color="error">

    <template #icon>
      <alert-circle-icon size="2.5x" class="error--text"/>
    </template>

    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ attrs, on }">
        <v-btn color="error" :elevation="0" v-bind="attrs" v-on="on">
          Elimina
        </v-btn>
      </slot>
    </template>

    <div class="text-center subtitle-1">
      <p class="mb-0">Sei sicuro di voler eliminare questo indirizzo?</p>
      <p>Una volta eliminato non sarà più possibile recuperarlo!</p>
    </div>

    <Alert type="warning" v-if="errorMessage">
      {{ $t(errorMessage) }}
    </Alert>

  </Dialog>
  
</template>

<script>
import API from '@/api'

import { mapActions } from 'vuex'

import dialog from '@/mixins/dialog'

import Address from '@/models/address'

import Alert from '@/components/UI/Alert'
import Dialog from '@/components/UI/Dialog'

import { AlertCircleIcon } from 'vue-feather-icons'

export default {
  name: 'AddressDeleteDialog',

  mixins: [ dialog ],

  components: {
    Alert,
    Dialog,
    AlertCircleIcon
  },

  data: () => ({
    errorMessage: ''
  }),

  props:
  {
    address: {
      type: Address,
      required: true
    },
  },

  computed:
  {
    
  },

  methods:
  {
    ...mapActions({
      refreshUserData: 'auth/refreshUserData'
    }),

    submit()
    {
      this.isLoading = true

      this.errorMessage = ""

      let id = this.address.id, 
          ws = API.auth.deleteAddress(id)

      ws.then(()=>{

          this.refreshUserData()

          this.close()

        })
        .catch((err)=>{
          
          let message = err.response.data.message

          this.errorMessage = message

        })
        .finally(()=>{
          
          this.isLoading = false

        })
    }

  },

  mounted()
  {

  },

  watch: 
  {

  }
};
</script>
