<template>

	<v-text-field
      v-model="coupon"
      label="Hai un coupon da utilizzare?"
      required
      outlined 
      :color="couponInputColor"
      hint="Se hai ricevuto un coupon da utilizzare al momento del checkout inseriscilo qui."
    >
      <template slot="append">
        <InputLiveTypingIcon :status="liveTyping.isTyping"/>
        <v-icon v-if="!liveTyping.isTyping"
                :color="couponInputColor">
          {{ couponIcon }}
        </v-icon>
      </template>
  </v-text-field>
  
</template>

<script>
import InputLiveTypingIcon from '@/components/UI/InputLiveTypingIcon'

import liveTyping from '@/mixins/liveTyping'

import API from '@/api'

export default {
  name: 'Coupon',

  mixins: [ liveTyping ],

  components: {
    InputLiveTypingIcon
  },

  data: () => ({
    coupon: null,
    couponStatus: 0, // 0 = empty, 1 = OK, -1 = KO
    isCheckingCoupon: true
  }),

  computed:
  {
    couponIcon()
    {
        switch(this.couponStatus)
        {
            case 1: return 'mdi-check-circle';
            case -1 : return 'mdi-alert-circle';
            default: return ''
        }
    },
    couponInputColor()
    {
        switch(this.couponStatus)
        {
            case 1: return 'success';
            case -1 : return 'error';
            default: return ''
        }  
    }
  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch: 
  {
    coupon(coupon)
    {    
        if( !coupon )
        {
          
          this.$emit('update:value', coupon)

          return this.couponStatus = 0
        }

        this.isLoading = true

        // mentre inserisco il coupon do un feedback all'utente
        let resolve = () => {

          this.couponStatus = 0

          API.ecommerce.coupon(coupon)
                       .then(()=>
                       {
                          this.couponStatus = 1
                       })
                       .catch(()=>
                       {
                          this.couponStatus = -1
                       })
                       .finally(()=>
                       {
                          this.isLoading = false

                          this.$emit('update:value', coupon)

                       })
            
        }

        this.liveType(resolve)
    },

    couponStatus(status)
    {
        this.$emit('update:status', status)
    }
  }
};
</script>
