<template>

	<v-alert :color="color" :dense="dense" :class="css">
    <slot name="icon">
      <span v-if="!icon"></span>
      <component v-else-if="iconType" 
                :is="iconType"
                class="mr-2"
                style="margin-bottom:-5px"/>
    </slot>
    <slot></slot>
  </v-alert>
  
</template>

<script>
import { AlertCircleIcon, CheckCircleIcon, InfoIcon, XCircleIcon } from 'vue-feather-icons'

export default {
  name: 'Alert',

  mixins: [],

  components: {
    AlertCircleIcon, 
    CheckCircleIcon, 
    InfoIcon, 
    XCircleIcon
  },

  data: () => ({
    //
  }),

  props:
  {
    type: {
      type: String,
      default: ''
    },

    bold: {
      type: Boolean,
      default: false
    },

    dense: {
      type: Boolean,
      default: false
    },

    icon: {
      type: Boolean,
      default: false
    }
  },

  computed:
  {
    color()
    {
      let color = 'grey lighten-3'

      switch(this.type)
      {
        case 'info': 
          color = 'light-blue lighten-4 primary--text'; 
          break;
        case 'success': 
          color = 'light-green lighten-4 success--text'; 
          break;
        case 'warning': 
          color = 'orange lighten-4 warning--text'; 
          break;
      }

      return color
    },

    css()
    {
      let css = ''

      if( this.bold )
        css += 'font-weight-bold '

      return css
    },

    iconType()
    {
      let icon

      switch(this.type)
      {
        case 'info': 
          icon = 'inco-icon'; 
          break;
        case 'success': 
          icon = 'check-circle-icon'; 
          break;
        case 'warning': 
          icon = 'alert-circle-icon'; 
          break;
      }

      return icon      
    }
  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch: {}
};
</script>
