<template>

	<v-row>

    <v-col cols="12" class="grey lighten-3 text-center">
      <v-container>
        <small class="d-block">I prezzi sono comprensivi di IVA (22%), sono escluse le spese di spedizione, salvo diversa indicazione.</small> 
        <small class="d-block">Zwan Store usa la crittografia standard del settore per proteggere la riservatezza delle informazioni inviate.</small>
      </v-container>
    </v-col>

    <v-col
      class="text-center grey darken-4 white--text"
      cols="12"
    >
      <p>
        &copy;{{ new Date().getFullYear() }} 
        <a class="font-weight-bold" @click="goToZwan">Zwan</a>. 
        Tutti i diritti riservati. 
        <a @click="goTo('toc')">Termini e condizioni di utilizzo</a>.
      </p>
      <small class="d-block">Zwan S.r.l. • Sede legale: Piazza Capponi, 13 - 00193 Roma • Sede operativa: Via del Babuino, 65, 00187 Roma RM • P.IVA e C.F. 15488481001</small>
    </v-col>
  
  </v-row>
  
</template>

<script>
import utility from '@/mixins/utility'

export default {
  name: 'Footer',

  mixins: [ utility ],

  components: {
    
  },

  data: () => ({
    //
  }),

  computed:
  {
    
  },

  methods:
  {
    goToZwan()
    {
      window.open('https://www.zwan.it/')
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
