<template>

	<v-row>
    <v-col cols="12" md="4"
           :class="device.isSm ? 'text-center' : ''">
      <p class="text-h5">
        Accesso e sicurezza
      </p>
    </v-col>
    <v-col cols="12" md="8">
      <v-row>
        <v-col cols="12" md="3">
          <strong>Zwan ID</strong>
        </v-col>
        <v-col>
          {{ user.email }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <strong>Password</strong>
        </v-col>
        <v-col>
          ******** 
        </v-col>
        <v-col class="text-right">
          <PasswordDialog/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  
</template>

<script>
import { mapGetters } from 'vuex'

import mq from '@/mixins/mq'

import PasswordDialog from '@/components/auth/dialogs/Password'

export default {
  
  name: 'Account',

  mixins: [ mq ],

  components: {
    PasswordDialog
  },

  data: () => ({
    //
  }),

  computed:
  {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch: {}
};
</script>
