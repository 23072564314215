<template>

  <Dialog :status.sync="dialog"
          :title="title"
          :submit-status="canSubmit"
          @submit="submit"
          :loading="isLoading">

    <template #icon>
      <package-icon size="2.5x" class="primary--text"/>
    </template>
    
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ attrs, on }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="primary">mdi-plus-circle</v-icon>
        </v-btn>
      </slot>
    </template>

    <AddressForm @update="onFormUpdate"
                 ref="addressForm"
                 :defaults="userFields"
                 class="mt-8"/>

  </Dialog>
  
</template>

<script>
import API from '@/api'

import { mapActions, mapGetters } from 'vuex'

import dialog from '@/mixins/dialog'

import AddressForm from '@/components/addresses/Form'
import Dialog from '@/components/UI/Dialog'

import { PackageIcon } from 'vue-feather-icons'

export default {
  name: 'AddressDialog',

  mixins: [ dialog ],

  components: {
    AddressForm,
    Dialog,
    PackageIcon
  },

  data: () => ({
    formData: {},
    errorMessage: ''
  }),

  props:
  {
    title: {
      type: String,
      default: 'Aggiungi un nuovo indirizzo'
    },

    addressId: {
      type: Number,
      default: null
    },

    defaults: {
      type: Object,
      default: ()=>{ return {} }
    },
  },

  computed:
  {
    ...mapGetters({
      user: 'auth/user'
    }),

    canSubmit()
    {
      return this.formData.status
    },

    userFields()
    {
      let fields = {}

      if( this.user )
        fields = {
          firstname: this.user.firstname,
          lastname: this.user.lastname
        }

      fields = {
        ...fields,
        ...this.defaults
      }

      return fields
    }
  },

  methods:
  {
    ...mapActions({
      refreshUserData: 'auth/refreshUserData'
    }),

    onFormUpdate(data)
    {
      this.formData = data
    },

    resetForm()
    {
      this.$refs.addressForm.reset()
    },

    submit()
    {
      let data = this.formData.form

      if(!data)
        return

      this.isLoading = true

      this.errorMessage = ""

      let id = this.addressId, 
          ws = id ? API.auth.updateAddress(id, data) 
                  : API.auth.createAddress(data)

      ws.then(()=>{

          this.resetForm()

          this.refreshUserData()
              .then(()=>{

                this.isLoading = false

                this.close()

              })

        })
        .catch((err)=>{
          
          let message = err.response.data.message

          this.errorMessage = message

          this.isLoading = false

        })
    }

  },

  mounted()
  {

  },

  watch: 
  {

  }
};
</script>
