import CrispChat from '@dansmaculotte/vue-crisp-chat'

import SETTINGS from '@/settings'

const crispId = SETTINGS.CRISP.websiteId

const CrispConfig = {
	websiteId: crispId,
	disabled: false,
	hideOnLoad: false
}

export { CrispChat, CrispConfig }