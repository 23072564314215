<template>

	<v-form>

    <!-- address name -->
    <v-text-field 
      outlined
      label="Nome indirizzo (es. ufficio)"
      v-model="form.address_name"
      hint="È il nome che visualizzerai nell'elenco dei tuoi indirizzi"
      :error="inputErrorClass('address_name')"
      v-if="!isHidden('address_name')" />

    <v-row>
      <!-- firstname -->
      <v-col v-if="!isHidden('firstname')"
             cols="12"
             md="6">
        <v-text-field 
          outlined
          hide-details
          label="Nome"
          v-model="form.firstname"
          :autofocus="true"
          :error="inputErrorClass('firstname')" />
      </v-col>
      <!-- lastname -->
      <v-col v-if="!isHidden('lastname')"
             cols="12"
             md="6">
        <v-text-field 
          outlined
          label="Cognome"
          v-model="form.lastname"
          :error="inputErrorClass('lastname')" />
      </v-col>
    </v-row>

    <!-- indirizzo -->
    <v-text-field 
      outlined
      label="Indirizzo e numero civico"
      v-model="form.street"
      hint="Ricordati di inserire anche il numero civico!"
      :error="inputErrorClass('street')"
      v-if="!isHidden('street')" />

    <!-- dettagli aggiuntivi -->
    <v-text-field 
      outlined
      label="Dettagli aggiuntivi (es. azienda)"
      v-model="form.details"
      :error="inputErrorClass('details')"
      v-if="!isHidden('details')" />

    <v-row>
      <!-- cap -->
      <v-col cols="12" md="3">
        <v-text-field 
          outlined
          hide-details
          label="CAP"
          v-model="form.zipcode"
          :error="inputErrorClass('zipcode')"
          v-if="!isHidden('zipcode')" />
      </v-col>
      <!-- città -->
      <v-col cols="12" md="6">
        <v-text-field 
          outlined
          hide-details
          label="Città"
          v-model="form.city"
          hint="Le spedizioni al momento possono essere effettuate solo in Italia."
          :error="inputErrorClass('city')"
          v-if="!isHidden('city')" />
      </v-col>
      <!-- provincia -->
      <v-col cols="12" md="3">
        <v-select
          :items="province"
          label="Provincia"
          outlined
          v-model="form.state"
          :error="inputErrorClass('state')" 
          v-if="!isHidden('state')"
        ></v-select>
      </v-col>
    </v-row>

    <Alert type="warning" icon
           v-if="firstFormError">
      {{ $t(`validations.${firstFormError}`) }}
    </Alert>
    
  </v-form>
  
</template>

<script>
import _ from 'lodash'

import PROVINCE from '@/plugins/province'

import { required, requiredIf, numeric, minLength, maxLength } from '@vuelidate/validators'

import vuelidate from '@/mixins/vuelidate'

import Alert from '@/components/UI/Alert'

export default {
  name: 'AddressForm',

  mixins: [ vuelidate ],

  components: {
    Alert
  },

  data: () => ({
    form: {
      address_name: null,
      firstname: null,
      lastname: null,
      street: null,
      details: null,
      zipcode: null,
      city: null,
      state: null,
      country: 'IT'
    }
  }),

  props: 
  {
    hide: {
      type: String,
      default: ''
    },

    defaults: {
      type: Object,
      default: ()=>{ return {} }
    }
  },

  computed:
  {
    province()
    {
      return _.map(PROVINCE, (provincia)=>{
        return {
          text: provincia.nome,
          value: provincia.sigla
        }
      })
    }
  },

  validations ()
  {
    return {
      form: {
        address_name: {
          requiredIfNotHidden: requiredIf(!this.isHidden('address_name'))
        },
        firstname: { required },
        lastname: { required },
        street: { required },
        details: {},
        zipcode: { 
          required,
          numeric,
          minLength:minLength(5),
          maxLength:maxLength(5)
        },
        city: { required },
        state: { required },
        country: { required }
      }
    }
  },

  methods:
  {
    loadDefaults()
    {
      _.each( this.defaults, (value,key)=>{

        if( _.has( this.form, key ) )
          this.form[key] = value

      })
    },

    isHidden(fieldName)
    {
      if( !this.hide )
        return false

      let hiddenFields = _.chain(this.hide)
                          .split(',')
                          .trim()
                          .value()

      return (hiddenFields.indexOf(fieldName) >= 0)
    },

    reset()
    {
      _.each(this.form, (value, key)=>{
        if( key !== 'country')
          this.form[key] = null
      })

      this.loadDefaults()

    }
  },

  mounted()
  {
    this.loadDefaults()
  },

  watch:
  {
    form:
    {
      deep: true,
      handler()
      {
        this.$emit('update', { 
          status: this.canSubmit,
          form: this.form
        })
      }
    }
  }
};
</script>
