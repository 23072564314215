<template>

	<div class="text-center mx-auto" style="max-width: 400px">

    <p class="mb-10">
      Insersci l'indirizzo e-mail associato al tuo <strong>Zwan ID</strong> e riceverai le indicazioni per reimpostare la password del tuo account.
    </p>

    <v-form>
      
      <v-text-field 
          outlined
          type="email"
          label="Indirizzo email"
          v-model="form.email"
          :autofocus="true"
          @keyup.enter="submit"/>

      <v-btn x-large 
             color="primary"
             class="mb-5"
             :disabled="!canSubmit"
             @click="submit">
        Cambia password
      </v-btn>

    </v-form>

    <v-alert type="success"
             v-if="message"
             v-html="$t(message)">
    </v-alert>

  </div>
  
</template>

<script>
import API from '@/api'

import { mapMutations } from 'vuex'

import { required, email } from '@vuelidate/validators'

import vuelidate from '@/mixins/vuelidate'

export default {
  name: 'AskResetPassword',

  mixins: [ vuelidate ],

  components: {

  },

  data: () => ({
    form: {
      email: null
    },
    message: null
  }),

  props:
  {
    
  },

  computed:
  {
    
  },

  validations ()
  {
    return {
      form: {
        email: { required, email }
      }
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    submit()
    {
      let email = this.form.email

      this.setLoading(true)

      this.message = null

      API.auth.forgotPassword(email)
              .then(({ data })=>{

                this.message = data.message

                this.form.email = null

              })
              .catch((err)=>{
                
                let message = err.response.data.message

                this.message = message

              })
              .finally(()=>{
                
                this.setLoading(false)

              })
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
