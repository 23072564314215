<template>
  <div>
    <v-container class="py-10">

      <v-alert type="info" v-if="cartIsEmpty">
        Il tuo carrello &egrave; vuoto.
      </v-alert>

      <div v-else>

        <CheckoutSignin @submit="onSignin"
                        v-if="showSignin"/>

        <GuestCheckout v-if="showCheckout"/>

        <UserCheckout v-if="isLogged"/>
        
      </div>

    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import goTo from 'vuetify/lib/services/goto'

import CheckoutSignin from '@/components/ecommerce/checkout/Signin'
import GuestCheckout from '@/components/ecommerce/checkout/GuestCheckout'
import UserCheckout from '@/components/ecommerce/checkout/UserCheckout'

export default {
  
  name: 'Checkout',

  mixins: [],

  components: {
    CheckoutSignin,
    GuestCheckout,
    UserCheckout
  },

  data: () => ({
    showCheckout: false
  }),

  computed: 
  {
    ...mapGetters({
      cart: 'ecommerce/products',
      cartIsEmpty: 'ecommerce/cartIsEmpty',
      cartTotal: 'ecommerce/cartTotal',
      isLogged: 'auth/isLogged'
    }),

    showSignin()
    {
      return !this.showCheckout && !this.isLogged
    }
  },

  methods:
  {
    onSignin()
    {
      this.showCheckout = true
    }
  },

  mounted()
  {
    goTo(0)
  }

}
</script>
