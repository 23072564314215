<template>
   <v-btn text 
          plain 
          class="logo-wrapper"
          @click="onClick"
          :href="href">
            
      <component :is="logoVariant" :height="height"/>

    </v-btn>
</template>

<style lang="scss" scoped>
   .logo-wrapper {
    -webkit-transition: all .3s;
    transition        : all .3s;
   }
</style>

<script>
  import LogoSvg from '@/assets/logo.svg'
  import LogoWhite from '@/assets/logo-white.svg'

  export default {
    name: 'Logo',

    mixins: [],

    components: {
      LogoSvg,
      LogoWhite
    },

    data: () => ({
      
    }),

    props: {
      height: {
        type: Number,
        default: 50
      },
      href: {
        type: String,
        default: ''
      },
      white: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      logoVariant()
      {
        if( this.white )
          return 'LogoWhite'

        return 'LogoSvg'
      }
    },

    methods:
    {
      onClick()
      {
        this.$emit('click')
      }
    }
  }
</script>
