<template>

	<div>

		<Header @mobileMenu="$refs.mobileMenu.show()"
            v-if="!headerIsHidden"/>
  
		<router-view class="main-view pb-10"/>

		<Footer v-show="!isEmbed"/>

    <Crisp v-if="hasChat"/>

    <MobileMenu v-if="this.device.isSm"
                ref="mobileMenu"/>
		
	</div>
  
</template>

<style lang="scss">
  .main-view {
    min-height: calc( 100vh - 200px );
  }
</style>

<script>
import Crisp from '@/components/plugins/Crisp'
import Footer from '@/components/UI/Footer'
import Header from '@/components/UI/Header'
import MobileMenu from '@/components/UI/MobileMenu'

import mq from '@/mixins/mq'

import SETTINGS from '@/settings'

import { mapGetters } from 'vuex'

export default {
  name: 'FullLayout',

  mixins: [ mq ],

  components: {
    Crisp,
    Footer,
    Header,
    MobileMenu
  },

  data: () => ({
    //
  }),

  computed:
  {
    ...mapGetters({
      headerIsHidden: 'ui/headerIsHidden',
      isEmbed: 'spaces/isEmbed'
    }),

    hasChat()
    {
      if(this.isEmbed)
        return false
        
      return SETTINGS.CRISP.websiteId
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
