import Vue from 'vue'
import Vuex from 'vuex'

// modules.
import auth from './auth'
import ecommerce from './ecommerce'
import ui from './ui'
import { vuex as spaces } from '@zwan-it/zwan-spaces'

import SETTINGS from '@/settings'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    auth,
    ecommerce,
    ui,
    spaces
  },

  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },

  mutations: {},

  actions: {},

  getters :
  {
    appVersion: () => {
      return SETTINGS.APP_VERSION
    }
  }
})