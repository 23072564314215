import API from './'

const ecommerce = {

	/**
	 * Get a list of subscription plans.
	 */
	plans(params={})
	{
		let url = API.getUrl(`ecommerce/plans`)

		return API.client().get(url, { params })
	},

	/**
     * Get a Stripe intent.
    **/
    getIntent(userEmail=null)
    {
        let append = userEmail ? `/${userEmail}` : '',
            url = API.getUrl(`ecommerce/intent${append}`)

        return API.client().get(url)
    },

    /**
     * Do checkout.
     */
    checkout(data, userEmail=null)
    {
        let append = userEmail ? `/${userEmail}` : '', 
            url = API.getUrl(`ecommerce/checkout${append}`)

        return API.client().post(url, data)
    },

    /**
     * Get group stored payment methods.
    **/
    paymentMethods()
    {
        let url = API.getUrl(`ecommerce/payment-methods`)

        return API.client().get(url)
    },    

    savePaymentMethod(payment_method, userEmail=null)
    {
        let append = userEmail ? `/${userEmail}` : '', 
            url = API.getUrl(`ecommerce/payments${append}`),
            data = { payment_method }

        return API.client().post(url, data)
    },

    deletePaymentMethod(paymentMethodId)
    {
        let url = API.getUrl(`ecommerce/payment-method/${paymentMethodId}`)

        return API.client().delete(url)
    },

    updatePaymentMethod(paymentMethod)
    {
        let url = API.getUrl(`ecommerce/payment-methods/default`),
            data = { paymentMethod }

        return API.client().post(url, data)
    },

    favouritePaymentMethod(paymentMethodId)
    {
        let url = API.getUrl(`ecommerce/payment-methods/favourite/${paymentMethodId}`)

        return API.client().post(url)
    },

    /**
     * Get all published subscriptions.
    **/
    subscriptions(params={})
    {
        let url = API.getUrl(`ecommerce/subscriptions`)

        return API.client().get(url, { params })
    },

    /**
     * Get user invoices.
    **/
    invoices()
    {
        const requestData = {
            method: 'get',
            url: API.api(`ecommerce/invoices`)
        }

        return API.request(requestData)
    },

    /**
     * Get user orders.
    **/
    orders(params={})
    {
        let url = API.getUrl(`user/orders`)

        return API.client().get(url, { params })
    },

    /**
     * Get an order.
    **/
    order(orderNumber)
    {
        let url = API.getUrl(`ecommerce/order/${orderNumber}`)

        return API.client().get(url)
    },

    /**
     * Check a coupon.
    **/
    coupon(couponCode, params={})
    {
        let url = API.getUrl(`ecommerce/coupon/${couponCode}`)

        return API.client().get(url, { params })
    }

}

export default ecommerce