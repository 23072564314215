<template>
  <div>
    <v-container class="py-10">

      <p class="text-h3 mb-10 pb-5 mt-5">
        <Gravatar overlay
                  @click="$refs.avatarDialog.open()"/>
        Il tuo profilo
      </p>

      <AvatarDialog ref="avatarDialog"/>

      <AccountRow/>

        <v-divider class="my-10"/>

      <ProfileRow/>

        <v-divider class="my-10"/>

      <PaymentMethodsRow/>

        <v-divider class="my-10"/>

      <AddressRow/>

    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AccountRow from '@/components/auth/profile/Account'
import AddressRow from '@/components/auth/profile/Address'
import PaymentMethodsRow from '@/components/auth/profile/PaymentMethods'
import ProfileRow from '@/components/auth/profile/Profile'

import Gravatar from '@/components/UI/Gravatar'
import AvatarDialog from '../components/UI/AvatarDialog.vue'

export default {
  
  name: 'Profile',

  mixins: [ ],

  components: {
    AccountRow,
    AddressRow,
    Gravatar,
    PaymentMethodsRow,
    ProfileRow,
    AvatarDialog
},

  data: () => ({

  }),

  computed: 
  {
    ...mapGetters({
      isEmbed: 'spaces/isEmbed',
      user: 'auth/user'
    })
  },

  methods:
  {
    ...mapActions({
      hideHeader: 'ui/hideHeader'
    }) 
  },

  mounted()
  {
    if( this.isEmbed )
      this.hideHeader()
  }

}
</script>
