<template>

	<Dialog :status.sync="dialog"
          :title="title"
          :submit-status="formCanSubmit ? true : false"
          @submit="submit"
          :loading="isLoading">

    <template #icon>
      <credit-card-icon size="2.5x" class="primary--text"/>
    </template>

    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ attrs, on }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="primary">mdi-plus-circle</v-icon>
        </v-btn>
      </slot>
    </template>

    <CreditCardForm @update="onFormUpdate"
                    ref="creditCardForm"/>

    <Alert v-if="errorMessage"
           type="warning">
      {{ $t(errorMessage) }}
    </Alert>

  </Dialog>
  
</template>

<script>
import API from '@/api'

import { mapActions, mapGetters } from 'vuex'

import dialog from '@/mixins/dialog'

import Alert from '@/components/UI/Alert'
import CreditCardForm from '@/components/ecommerce/credit-card/Form'
import Dialog from '@/components/UI/Dialog'

import { CreditCardIcon } from 'vue-feather-icons'

export default {
  name: 'CreditCardDialog',

  mixins: [ dialog ],

  components: {
    Alert,
    CreditCardForm,
    CreditCardIcon,
    Dialog,
  },

  data: () => ({
    errorMessage: '',
    form: {}
  }),

  props:
  {
    title: {
      type: String,
      default: 'Aggiungi un metodo di pagamento'
    }
  },

  computed:
  {
    ...mapGetters({
      user: 'auth/user'
    }),

    formCanSubmit()
    {
      return this.form.status
    }
  },

  methods:
  {
    ...mapActions({
      confirmCard: 'ecommerce/confirmCard',
      refreshUserData: 'auth/refreshUserData'
    }),

    submit()
    {
      let email = this.user.email

      this.isLoading = true

      this.errorMessage = ""

      API.ecommerce.getIntent(email)
         .then(({ data })=>{

            this.confirmCard({
              intentToken: data,
              cardholder: this.form.cardholder,
              card: this.form.card
            })        
            .then((res)=>{

              // Something went wrong?
              if (res.error) {

                this.errorMessage = 'errors.credit_card'

                console.error(res.error.message)
              
              } else {

                // Save payment method
                let paymentMethod = res.setupIntent.payment_method
                
                API.ecommerce
                   .savePaymentMethod(paymentMethod, email)
                   .then(({ data })=>{
                      
                      console.log(data)

                      this.refreshUserData()

                      this.isLoading = false

                      this.$refs.creditCardForm.reset()

                      this.close()

                   })
              }

            })
        })
    },

    onFormUpdate(data)
    {
      this.form = data
    }

  },

  mounted()
  {

  },

  watch: 
  {
    
  }
};
</script>
