<template>
  <div class="pt-10">

    <p class="text-h3 text-center pt-10 mt-10">



      Redirecting...
    </p>

  </div>
</template>

<script>
export default {
  
  name: 'RedirectFrontend',

  mixins: [],

  components: {

  },

  data: () => ({
    products: []
  }),

  computed: {
    
  },

  methods:
  {

  },

  mounted()
  {
    location.href = 'https://www.zwan.it'
  }

}
</script>
