<template>

	<div>
    
      <p class="text-h4 text-center font-weight-bold my-10">
        Se hai gi&agrave; uno Zwan ID accedi per effettuare il checkout rapido.
      </p> 

      <v-row class="mt-20 pt-10">

        <v-col cols="12" md="7">

          <p class="text-h5 text-center">
            Continua con il tuo <strong>Zwan ID</strong>
          </p>
          
          <Signin :signup="false"/>
          
        </v-col>

        <v-col cols="12" md="5" class="text-center"
               style="border-left: 1px solid #ccc">
          
          <p class="text-h5">
            Non hai ancora uno <strong>Zwan ID</strong>?
          </p>

          <p>
            Procedi come ospite, puoi creare il tuo Zwan ID in seguito.
          </p>

          <v-btn color="primary" 
                 class="px-7 py-6"
                 @click="checkoutAsGuest">
            Continua come ospite
          </v-btn>

        </v-col>        
        
      </v-row>

  </div>
  
</template>

<script>
import Signin from '@/components/auth/Signin'

export default {
  name: 'CheckoutSignin',

  mixins: [],

  components: {
    Signin
  },

  data: () => ({
    //
  }),

  computed:
  {
    
  },

  methods:
  {
    checkoutAsGuest()
    {
      this.$emit('submit')
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
