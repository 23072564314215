<template>
  <div class="grey lighten-4">
    <v-container class="py-10">

      <Products/>

      <v-row class="mt-10">
        <v-col>
          <ChatCard/>
        </v-col>
        <v-col>
          <ShippingCard/>
          <PaymentCard class="mt-5"/>
        </v-col>
      </v-row>

      <Categories/>

    </v-container>
  </div>
</template>

<script>
import Categories from '@/components/home/Categories'
import ChatCard from '@/components/cards/Chat'
import PaymentCard from '@/components/cards/Payment'
import Products from '@/components/home/Products'
import ShippingCard from '@/components/cards/Shipping'

export default {
  
  name: 'Home',

  mixins: [],

  components: {
    Categories,
    ChatCard,
    PaymentCard,
    Products,
    ShippingCard
  },

  data: () => ({

  }),

  computed: 
  {
    
  },

  methods:
  {
    
  },

  mounted()
  {

  }

}
</script>
