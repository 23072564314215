<template>
    <span><!-- Fast Checkout --></span>
</template>

<script>
import API from '@/api'

import Product from '@/models/product'

import { mapActions } from 'vuex'

export default{
    
    name: 'FastCheckout',

    props: {},
    
    data()
    {
        return {}
    },
    
    computed: {
        
    },
    
    methods: {
        
        ...mapActions({
            addToCart: 'ecommerce/addToCart',
        }),
        
        fastCheckout()
        {
          let addToCart = this.$route.query.addToCart

          if(!addToCart)
            return

          addToCart.split(',')
                   .forEach((slug)=>{
                      
                      API.products.one(slug)
                                  .then(({ data })=>{

                                    let product = new Product(data.data),
                                        /**
                                         * @todo UI to select price
                                         */
                                        price = product.prices[0]

                                        product.selectPrice(price)

                                    this.addToCart(product)

                                  })

                   })
        }
    },

    mounted()
    {
        this.fastCheckout()
    },
    
    watch: 
    {
        $route()
        {
          this.fastCheckout()
        }    
    }
}
</script>