class Address {

  #data = {}

  constructor(data={})
  {  
    this.#data = data
  }

  /**
   * Property getters.
   */
  
  get data()
  {
    return this.#data
  }

  get id()
  {
    return this.#data.id
  }

  get name()
  {
    return this.#data.address_name
  }

  get firstname()
  {
    return this.#data.firstname
  }

  get lastname()
  {
    return this.#data.lastname
  }

  get favourite()
  {
    return this.#data.favourite
  }

  get street()
  {
    return this.#data.street
  }

  get details()
  {
    return this.#data.details
  }

  get zipcode()
  {
    return this.#data.zipcode
  }

  get city()
  {
    return this.#data.city
  }

  get state()
  {
    return this.#data.state
  }

  get country()
  {
    return this.#data.country
  }

  /**
   * Computed properties.
   */
  
  get toForm()
  {
    let form = {
      firstname: this.firstname,
      lastname: this.lastname,
      street: this.street,
      details: this.details,
      zipcode: this.zipcode,
      city: this.city,
      state: this.state,
      country: this.country
    }

    return form
  }

}

export default Address