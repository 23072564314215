<template>

	<div class="text-center mx-auto" style="max-width: 400px">

    <v-form>
      
      <v-text-field 
          outlined
          type="email"
          label="Indirizzo email"
          v-model="form.email"
          :autofocus="true"
          @keyup.enter="signup"
          :hint="$t('signup.hints.email')"
          :error="inputErrorClass('email')"/>

      <v-text-field 
          outlined
          :type="passwordFieldType"
          label="Password"
          v-model="form.password"
          @keyup.enter="signup"
          :append-icon="passwordFieldIcon"
          @click:append="togglePasswordFieldType=!togglePasswordFieldType"
          :error="inputErrorClass('password')"/>

      <v-text-field 
          outlined
          :type="passwordFieldType"
          label="Conferma la password"
          v-model="form.passwordCheck"
          @keyup.enter="signup"
          :error="inputErrorClass('passwordCheck')"/>

      <v-alert type="warning"
             v-if="firstFormError"
             v-html="$t(`validations.${firstFormError}`)">
      </v-alert>

      <v-alert type="error"
               v-if="errorMessage">
        {{ $t(errorMessage) }}
      </v-alert>

      <v-btn x-large 
             color="primary"
             class="mb-5"
             :disabled="!canSubmit"
             @click="signup">
        Registrati
      </v-btn>

    </v-form>

  </div>
  
</template>

<script>
import API from '@/api'

import { mapMutations } from 'vuex'

import { required, email, sameAs } from '@vuelidate/validators'

import utility from '@/mixins/utility'
import vuelidate from '@/mixins/vuelidate'

export default {
  name: 'Signup',

  mixins: [ utility, vuelidate ],

  components: {

  },

  data: () => ({
    form: {
      email: null,
      password: null,
      passwordCheck: null
    },
    errorMessage: null,
    togglePasswordFieldType: false
  }),

  props:
  {
    
  },

  computed:
  {
    passwordFieldIcon()
    {
      return this.togglePasswordFieldType ? 'mdi-eye-off' : 'mdi-eye' 
    },
    
    passwordFieldType()
    {
      return this.togglePasswordFieldType ? 'text' : 'password'
    }
  },

  validations ()
  {
    return {
      form: {
        password: { 
          required 
          // @todo add special characters, lenght etc
        },
        email: { required, email },
        passwordCheck: { 
          required, 
          sameAsPassword:sameAs(this.form.password) 
        }
      }
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    signup()
    {
      let credentials = this.form

      this.setLoading(true)

      this.errorMessage = null

      API.auth.signup(credentials)
              .then(()=>{

                this.goTo('signupDone')

              })
              .catch((err)=>{
                
                let message = err.response.data.message

                this.errorMessage = message

              })
              .finally(()=>{
                
                this.setLoading(false)

              })
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
