<template>

	<v-app-bar dense>

    <v-container class="d-flex">
    
      <v-toolbar-title class="font-weight-bold">
        {{ product.name }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text 
             v-if="showPrice"
             @click="onPriceClick">
        <span v-if="price.isFree"
              class="font-weight-bold success--text">
          GRATIS
        </span>
        <span v-else>
          {{ price.fullValue | currency }}
          {{ price.recurrencyText }}
        </span>
      </v-btn>

    </v-container>

  </v-app-bar>
  
</template>

<script>
//import Product from '@/models/product'

import mq from '@/mixins/mq'

export default {
  name: 'ProductsHeader',

  mixins: [ mq ],

  components: {
    
  },

  data: () => ({
    //
  }),

  props:
  {
    product: {
      //type: Product,
      required: true
    }
  },

  computed:
  {
    price()
    {
      return this.product.lowestPrice
    },

    showPrice()
    {
      return this.price && !this.device.isSm
    }
  },

  methods:
  {
    onPriceClick()
    {
      this.$emit('price', this.price)
    }
  },

  mounted()
  {

  },

  watch:
  {

  }
};
</script>
