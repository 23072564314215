const mq = {
  
  computed:
  {
    device()
    {
      return {
        isSm: (this.$mq === 'sm' || this.$mq === 'xs'),
        isMd: (this.$mq === 'md'),
        isMdOrBelow: (this.$mq === 'sm' || this.$mq === 'md'),
        isMdAndAbove: (this.$mq === 'md' || this.$mq === 'lg'),
        isLg: (this.$mq === 'lg'),
      }
    }
  },

  watch: {}
}

export default mq