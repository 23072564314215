<template>
  <div>
    <v-container class="py-10">

      <p class="text-h3 mt-5 text-center">
        Ti sei disconnesso. A presto!
      </p>

    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  
  name: 'Signout',

  mixins: [ ],

  components: {
    
  },

  data: () => ({

  }),

  computed: 
  {
    
  },

  methods:
  {
    ...mapActions({
      logout: 'auth/logout'
    })
  },

  mounted()
  {
    this.logout()
  }

}
</script>
