<template>

	<v-menu offset-y rounded="lg">
    
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon 
             v-bind="attrs"
             v-on="on"
             @click="onClick">
        <user-icon :color="iconColor"/>
      </v-btn>
    </template>
    
    <v-list v-if="isLogged">
      <v-list-item 
        v-for="(item,i) in menu" 
        :key="i"
        @click="openMenu(item.route)">
        
        <v-list-item-icon class="mr-4"
          v-if="item.icon">
          <component :is="item.icon"/>
        </v-list-item-icon>
        
        <v-list-item-content>
          <v-list-item-title v-text="item.label"></v-list-item-title>
        </v-list-item-content>

      </v-list-item>

    </v-list>

  </v-menu>
  
</template>

<script>
// @see https://vue-feather-icons.egoist.sh/
import { DownloadIcon, PowerIcon, PackageIcon, SettingsIcon, UserIcon, TvIcon } from 'vue-feather-icons'

import { mapActions, mapGetters } from 'vuex'

import utility from '@/mixins/utility'

export default {
  name: 'UserButton',

  mixins: [ utility ],

  components: {
    DownloadIcon,
    PowerIcon,
    PackageIcon,
    SettingsIcon,
    UserIcon,
    TvIcon
  },

  data: () => ({

  }),

  computed:
  {
    ...mapGetters({
      isEmbed: 'spaces/isEmbed',
      isLogged: 'auth/isLogged',
      user: 'auth/user'
    }),

    menu()
    {
      let menu = [
        { label: 'Account', route: 'profile', icon:'settings-icon' },
        { label: 'Abbonamenti', route: 'subscriptions', icon:'tv-icon' },
        { label: 'Downloads', route: 'downloads', icon:'download-icon' },
        { label: 'Ordini', route: 'orders', icon:'package-icon' }
      ]

      if( !this.isEmbed )
        menu.push({ label: 'Disconnetti', route: 'signout', icon:'power-icon' })

      return menu
    },

    iconColor()
    {
      return this.isLogged ? 'white' : 'grey'
    }
  },

  methods:
  {
    ...mapActions({
      'spacesRoute': 'spaces/route'
    }),

    onClick()
    {
      if( !this.isLogged )
        this.goTo('login')
    },

    openMenu(route)
    {
      // handle profile routing in Zwan Spaces
      if( this.isEmbed && route === 'profile' ){
        const resolvedRoute = this.$router.resolve(route),
              absoluteURL = new URL(resolvedRoute.href, window.location.origin).href
        this.spacesRoute(absoluteURL)
      }

      this.goTo(route)
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
