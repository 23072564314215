import _ from 'lodash'

import utility from '@/mixins/utility'

class Product {

  #data = {}

  #prices = []

  #downloads = []

  #selectedPrice = {}

  #meta = {}

  constructor(data={})
  {  
    this.#data = data

    this.#prices = utility.methods.mapModels(data.prices, 'price')

    this.#downloads = data.downloads
  }

  /**
   * Property getters.
   */
  
  get data()
  {
    return this.#data
  }
  
  get id()
  {
    return this.#data.id
  }

  get active()
  {
    return this.#data.active
  }

  get slug()
  {
    return this.#data.slug
  }

  get meta()
  {
    return this.#meta
  }

  get name()
  {
    return this.#data.name
  }

  get description()
  {
    return this.#data.description
  }

  get headline()
  {
    return this.#data.headline
  }

  get image()
  {
    return this.#data.image
  }

  get logo()
  {
    return this.#data.logo
  }

  get maxAmount()
  {
    return this.#data.max_amount
  }

  get prices()
  {
    return this.#prices
  }

  get downloads()
  {
    return this.#downloads
  }

  /**
   * Computed properties.
   */
  
  get encoded()
  {
    let encoded = JSON.stringify(this.#data)

    return encoded
  }

  get hasDownloads()
  {
    return this.#downloads.length > 0
  }

  get hasMaxAmount()
  {
    return (this.maxAmount > 0)
  }

  get hasManyPrices()
  {
    return this.#prices.length > 1
  }

  get hasPrices()
  {
    return this.#prices.length > 0
  }

  get hasSelectedPrice()
  {
    return this.#selectedPrice.id
  }
  
   get isDigital()
   {
    return this.#data.digital ? true : false
   }

   get isSubscription()
   {
    return this.#data.subscription
   }

  get lowerPrice()
  {
    return _.minBy(this.#prices, 'value')
  }

  // get selected price
  get price()
  {
    return this.#selectedPrice
  }

  get lowestPrice()
  {
    if( !this.hasPrices )
      return null

    return _.minBy(this.prices, 'value')
  }

  /**
   * Methods.
   */
  
  selectPrice(price)
  {
    if( price )
      this.#selectedPrice = price

    else if( this.hasPrices )
      this.#selectedPrice = this.#prices[0]
    
  }

  setMeta(meta={})
  {
    this.#meta = meta
  }
}

export default Product