<template>
  <div>
    <v-container class="py-10">

      <p class="text-h3 mb-10 pb-5 mt-5">
        I tuoi ordini
      </p>

      <v-alert type="info" v-if="isLoading">
        Sto caricando gli ordini.
      </v-alert>

      <v-alert type="info" v-if="noOrders">
        Non hai ancora effettuato ordini
      </v-alert>

      <OrdersTable :orders="orders"/>

    </v-container>
  </div>
</template>

<script>
import API from '@/api'

import { mapGetters, mapMutations } from 'vuex'

import utility from '@/mixins/utility'

import OrdersTable from '@/components/orders/Table'

export default {
  
  name: 'Orders',

  mixins: [ utility ],

  components: {
    OrdersTable
  },

  data: () => ({
    loaded: false,
    orders: [],
  }),

  computed: 
  {
    ...mapGetters({
      isLoading: 'ui/isLoading',
      user: 'auth/user'
    }),

    noOrders()
    {
      return this.loaded && !this.orders.length
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    loadOrders()
    {
      this.setLoading()

      API.ecommerce.orders()
                   .then(({ data })=>{

                    this.orders = this.mapModels(data.data, 'order')
                                      // sort latest orders first
                                      .sort((a,b)=>{
                                        return b.id - a.id
                                      })

                    this.loaded = true

                   })
                   .finally(()=>{

                    this.setLoading(false)

                   })
    },

  },

  watch:
  {
    
  },

  mounted()
  {
    this.loadOrders()
  }

}
</script>
