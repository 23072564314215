<template>

	<v-row>
    <v-col class="text-center">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
      <p class="text-h5 mt-10">
        <slot></slot>
      </p>
    </v-col>
  </v-row>
  
</template>

<script>
export default {
  name: 'CheckoutLoading',

  mixins: [],

  components: {

  },

  data: () => ({
    //
  }),

  computed:
  {
    
  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch: {}
};
</script>
