<template>

  <Panel>
	
    <v-data-table
      :headers="headers"
      :items="orders"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="orders-table"
    >
      <!-- header columns -->
      
      <template v-slot:header.shippedAt>
        <package-icon/>
      </template>

      <template v-slot:header.hasSubscriptions>
        <refresh-cw-icon/>
      </template>

      <template v-slot:header.hasDownloads>
        <download-icon/>
      </template>

      <!-- item row columns -->

      <template v-slot:item.id="{ item }">
        <strong>
          #{{ item.id }}
        </strong>
      </template>
      
      <template v-slot:item.date="{ item }">
        {{ item.date.format('DD/MM/Y') }}
      </template>

      <template v-slot:item.shippedAt="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span v-if="!item.hasShipping">–</span>
              <clock-icon v-else-if="item.isToShip"/>
              <check-square-icon v-else/>
            </span>
          </template>
          <span v-if="!item.hasShipping">
            Questo ordine non necessita di spedizione.
          </span>
          <span v-else-if="item.isToShip">
            Stiamo preparando la tua spedizione.
          </span>
          <span v-else>
            Il prodotto &egrave; statoo spedito.
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.hasDownloads="{ item }">
        <v-chip v-if="item.hasDownloads">
          {{ item.downloads.length }}
        </v-chip>
        <span v-else>–</span>
      </template>

      <template v-slot:item.total="{ item }">
        {{ item.total | currency }}
      </template>
      
      <!-- order details -->

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0">
          <TableRow :order="item"/>
        </td>
      </template>

    </v-data-table>

  </Panel>
  
</template>

<style lang="scss">
  .orders-table * {
    font-size: 1.05em !important;
  }
</style>

<script>
//import Order from '@/models/order'

import Panel from '@/components/UI/Panel'
import TableRow from '@/components/orders/TableRow'

import { CheckSquareIcon, DownloadIcon, PackageIcon, RefreshCwIcon, ClockIcon } from 'vue-feather-icons'

export default {
  name: 'OrdersTable',

  mixins: [],

  components: {
    Panel,
    TableRow,
    // icons.
    CheckSquareIcon,
    DownloadIcon,
    PackageIcon,
    RefreshCwIcon,
    ClockIcon
  },

  data: () => ({
    expanded: [],
    headers: [
      {
        text: 'Numero ordine',
        align: 'start',
        value: 'id',
      },
      {
        text: 'Data', 
        value: 'date', 
        sortable: false,
        align: 'center' 
      },
      { 
        text: 'Spedizione', 
        value: 'shippedAt', 
        sortable: false,
        align: 'center' 
      },
      { 
        text: 'Abbonamenti', 
        value: 'hasSubscriptions', 
        sortable: false,
        align: 'center' 
      },
      { 
        text: 'Download', 
        value: 'hasDownloads', 
        sortable: false,
        align: 'center'
      },
      { text: 'Totale', value: 'total', sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
  }),

  computed:
  {
    
  },

  props: 
  {
    orders:
    {
      type: Array,
      required: true
    },

  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    } 
  },

  mounted()
  {

  },

  watch: {}
};
</script>
