import Api from './'

const auth = {

	login(credentials = {})
	{
		let url = Api.getUrl('login')

		return Api.client().post(url, credentials)
	},

	signup(credentials = {})
	{
		let url = Api.getUrl('signup')

		return Api.client().post(url, credentials)
	},

	user()
	{
		let url = Api.getUrl('user')

		return Api.client().get(url)
	},

	upsertUser(data={})
	{
		let url = Api.getUrl(`user/upsert`)

		return Api.client().post(url, {...data})
	},

	updateUser(data = {})
	{
		let url = Api.getUrl('user')

		return Api.client().post(url, {...data})
	},

	// Fetch data for logged in user.
	refresh(params={})
	{
		let url = Api.getUrl(`user`)

		return Api.client().get(url, { params })
	},

	verifyEmail(email)
	{
		let url = Api.getUrl(`user/verify/${email}`)

		return Api.client().post(url)
	},

	deploy(token, extraParams={})
	{
		const url = Api.getUrl('network/user/deploy'),
			params = { token, ...extraParams }

		return Api.client().post(url, params)
	},
	
	/**
	 * 🔑 Password.
	 */
	
	forgotPassword(email)
	{
		let url = Api.getUrl(`user/forgot-password`)

		return Api.client().post(url, {email})
	},
	
	resetPassword(data = {})
	{
		let url = Api.getUrl(`user/reset-password`)

		return Api.client().post(url, data)
	},

	updatePassword(data={})
	{
		let url = Api.getUrl(`user/password`)

		return Api.client().post(url, data)
	},

	/**
	 * 🏠 Addresses.
	 */
	
	createAddress(data={})
	{
		let url = Api.getUrl(`user/address`)

		return Api.client().post(url, data)
	},

	updateAddress(addressId, data={})
	{
		let url = Api.getUrl(`user/address/${addressId}`)

		return Api.client().post(url, data)
	},

	deleteAddress(addressId)
	{
		let url = Api.getUrl(`user/address/${addressId}`)

		return Api.client().delete(url)
	},

	favouriteAddress(addressId)
	{
		let url = Api.getUrl(`user/address/favourite/${addressId}`)

		return Api.client().post(url)
	},


	/**
	 * 🔁 Subscriptions.
	 */

	deleteSubscription(subscriptionName)
	{
		let url = Api.getUrl('subscription'),
			data = { subscriptionName }

		return Api.client().delete(url, { data })
	},

	resumeSubscription(subscriptionName)
	{
		let url = Api.getUrl('subscription/resume')

		return Api.client().post(url, { subscriptionName })
	},

	/**
	 * ⏬ Downloads.
	 */

	downloads(params={})
	{
		let url = Api.getUrl(`user/downloads`)

		return Api.client().get(url, { params })
	},

}

export default auth