import Vue from 'vue'
import VueRouter from 'vue-router'

// Layouts.
import FullLayout from '@/layouts/Full'

// Pages.
import Cart from '@/views/Cart.vue'
import Category from '@/views/Category.vue'
import Checkout from '@/views/Checkout.vue'
import Downloads from '@/views/Downloads.vue'
import Home from '@/views/Home.vue'
import Order from '@/views/Order.vue'
import Orders from '@/views/Orders.vue'
import OrderPlaced from '@/views/OrderPlaced.vue'
import Product from '@/views/Product.vue'
import Profile from '@/views/Profile.vue'
import RedirectFrontend from '@/views/RedirectFrontend.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import Signin from '@/views/Signin.vue'
import Signout from '@/views/Signout.vue'
import Signup from '@/views/Signup.vue'
import SignupDone from '@/views/SignupDone.vue'
import Subscriptions from '@/views/Subscriptions.vue'
import ToC from '@/views/ToC.vue'
import VerifyEmail from '@/views/VerifyEmail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: FullLayout,
    children: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/product/:slug',
            name: 'product',
            component: Product,
            meta: {
              //onlyNotLogged: true
              //onlyLogged: true
            }
        },
        {
            path: '/category/:slug',
            name: 'category',
            component: Category,
        },
        {
            path: '/cart',
            name: 'cart',
            component: Cart
        },
        {
            path: '/checkout',
            name: 'checkout',
            component: Checkout
        },
        {
            path: '/order/:orderNumber',
            name: 'order',
            component: Order
        },
        {
            path: '/order/placed/:orderNumber',
            name: 'orderPlaced',
            component: OrderPlaced
        },
        {
            path: '/bye',
            name: 'signout',
            component: Signout
        },
        {
            path: '/login',
            name: 'login',
            component: Signin
        },
        {
            path: '/signup',
            name: 'signup',
            component: Signup
        },
        {
            path: '/signup/done',
            name: 'signupDone',
            component: SignupDone
        },
        {
            path: '/reset-password',
            name: 'resetPassword',
            component: ResetPassword,
            meta: {
              onlyNotLogged: true
              //onlyLogged: true
            }
        },
        {
            path: '/email-verification/:email',
            name: 'verifyEmail',
            component: VerifyEmail,
            meta: {
              onlyNotLogged: true
            }
        },
        {
            path: '/toc',
            name: 'toc',
            component: ToC
        },
        /**
         * 🔒 Routes for authenticated users.
         */
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            meta: {
              onlyLogged: true
            }
        },
        {
            path: '/profile/orders',
            name: 'orders',
            component: Orders,
            meta: {
              onlyLogged: true
            }
        },
        {
            path: '/profile/subscriptions',
            name: 'subscriptions',
            component: Subscriptions,
            meta: {
              onlyLogged: true
            }
        },
        {
            path: '/profile/downloads',
            name: 'downloads',
            component: Downloads,
            meta: {
              onlyLogged: true
            }
        },
    ]
  },
  {
    path: '*',
    component: RedirectFrontend
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const loggedIn = localStorage.getItem('user')

  // auth check
  if (to.matched.some(record => record.meta.onlyLogged) && !loggedIn) {
    next({ name:'login', query:to.query })
    return
  }

  if (to.matched.some(record => record.meta.onlyNotLogged) && loggedIn) {
    next({ name:'profile', query:to.query })
    return
  }

  next()

})

export default router