<template>

	<Panel :highlighted="highlighted"
         :expanded="expanded"
         :withHover="withHover">
    
    <p class="text-h4 mb-0 text-center">
      <v-img :src="product.logo"
             height="80"
             contain
             v-if="product.logo"/>
      <span v-else>
        {{ product.name }}
      </span>
    </p>

    <slot></slot>

    <template v-slot:expansion>
      <slot name="expansion"></slot>
    </template>
    
  </Panel>
  
</template>

<script>
import Product from '@/models/product'

import utility from '@/mixins/utility'

import Panel from '@/components/UI/Panel'

export default {
  name: 'ProductPanel',

  mixins: [ utility ],

  components: {
    Panel
  },

  data: () => ({
    //
  }),

  props: 
  {
    product:
    {
      type: Product,
      required: true
    },

    expanded:
    {
      type: Boolean,
      default: false
    },
    
    highlighted:
    {
      type: Boolean,
      default: false
    },

    withHover:
    {
      type: Boolean,
      default: false
    }

  },

  computed:
  {
    
  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch: {}
};
</script>
