<template>

	<v-app-bar dense dark>

    <v-container class="d-flex"
                 v-if="!loaded">
      <v-skeleton-loader
        type="list-item-avatar"
        width="300"
      ></v-skeleton-loader>
    </v-container>

    <v-container class="d-flex"
                 v-else>
      
      <!-- logo Zwan -->
      <v-toolbar-title>
        <Logo white 
              :height="30" 
              class="pt-3"
              href="https://www.zwan.it"
              v-if="!isEmbed"/>
        <Logo white 
              :height="30" 
              class="pt-3"
              @click="goTo('home')"
              v-if="isEmbed"/>
      </v-toolbar-title>

      <!-- menu -->
      <div v-if="!this.device.isSm">
        <v-btn
          text
          class="mx-2 mt-1"
          @click="goTo('home')"
          v-if="!isEmbed">
          Store
        </v-btn>
        <v-menu v-for="category in categories" 
                :key="category.id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              class="mx-2 mt-1"
              @click="onCategoryClick(category)"
            >
              {{ category.name }}
            </v-btn>
          </template>
          <v-list v-if="category.hasChildren"
                  class="mt-5">
            <v-list-item v-for="child in category.children"
                         :key="child.id"
                         @click="onCategoryClick(child)">
              <v-list-item-title>
                {{ child.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-spacer></v-spacer>

      <UserButton/>

      <CartButton/>

      <v-btn icon color="grey"
             @click="onMobileMenu"
             v-if="this.device.isSm">
        <menu-icon/>
      </v-btn>

    </v-container>

    </v-app-bar>
  
</template>

<script>
import API from '@/api'

import { MenuIcon } from 'vue-feather-icons'

import Logo from '@/components/Logo'
import CartButton from '@/components/ecommerce/CartButton'
import UserButton from '@/components/auth/UserButton'

import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

import { mapGetters } from 'vuex'

export default {
  name: 'Header',

  mixins: [ mq, utility ],

  components: {
    Logo,
    CartButton,
    UserButton,
    MenuIcon
  },

  data: () => ({
    categories: [],
    loaded: false
  }),

  computed:
  {
    ...mapGetters({
      isEmbed: 'spaces/isEmbed'
    }),
  },

  mounted()
  {
    this.loadCategories()
  },

  methods:
  {
    loadCategories()
    {
      API.categories.list({ 'filter[main]':true })
                    .then(({ data })=>{
                      
                      this.categories = this.mapModels(data.data, 'category')

                      this.loaded = true

                    })
    },

    onCategoryClick(category)
    {
      if( !category.hasChildren )
        this.goTo({ 
          name:'category', 
          params: { slug:category.slug }
        })
    },

    onLogoClick()
    {
      this.goTo('home')
    },

    onMobileMenu()
    {
      this.$emit('mobileMenu')
    }
  },

  watch: {}
};
</script>
