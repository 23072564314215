<template>
  <div>
    <v-container class="py-10">

      <p class="text-h3 mt-5 mb-10 pb-5 text-center">
        Accedi con il tuo <strong>Zwan ID</strong>
      </p>

      <SigninForm/>

    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import utility from '@/mixins/utility'

import SigninForm from '@/components/auth/Signin'

export default {
  
  name: 'Signin',

  mixins: [ utility ],

  components: {
    SigninForm    
  },

  data: () => ({

  }),

  computed: 
  {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  methods:
  {
    doRedirect()
    {
      /**
       * @todo check ?referral request param
       */

      if(this.user)
        this.goTo('profile')
    }
  },

  watch:
  {
    user()
    {
      this.doRedirect()
    }
  },

  mounted()
  {
    this.doRedirect()
  }

}
</script>
