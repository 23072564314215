<template>

	<div class="text-center mx-auto" style="max-width: 400px">

    <v-form>
      
      <v-text-field 
          outlined
          type="email"
          label="Indirizzo email"
          v-model="form.email"
          :autofocus="true"
          @keyup.enter="submit"/>

      <v-text-field 
          outlined
          :type="passwordFieldType"
          label="Nuova password"
          v-model="form.password"
          @keyup.enter="submit"
          :append-icon="passwordFieldIcon"
          @click:append="togglePasswordFieldType=!togglePasswordFieldType"/>

      <v-text-field 
          outlined
          :type="passwordFieldType"
          label="Conferma nuova password"
          v-model="form.password_confirmation"
          @keyup.enter="submit"/>

      <v-btn x-large 
             color="primary"
             class="mb-5"
             :disabled="!canSubmit"
             @click="submit">
        Cambia password
      </v-btn>

    </v-form>

    <v-alert :type="messageType"
             v-if="message"
             v-html="$t(message)">
    </v-alert>

  </div>
  
</template>

<script>
import API from '@/api'

import { mapMutations } from 'vuex'

import { required, email, sameAs } from '@vuelidate/validators'

import vuelidate from '@/mixins/vuelidate'

export default {
  name: 'ResetPassword',

  mixins: [ vuelidate ],

  components: {

  },

  data: () => ({
    form: {
      email: null,
      password: null,
      password_confirmation: null
    },
    message: null,
    messageType: 'info',
    togglePasswordFieldType: false
  }),

  props:
  {
    token:
    {
      type: String,
      required: true
    }
  },

  computed:
  {
    passwordFieldIcon()
    {
      return this.togglePasswordFieldType ? 'mdi-eye-off' : 'mdi-eye' 
    },
    passwordFieldType()
    {
      return this.togglePasswordFieldType ? 'text' : 'password'
    }
  },

  validations ()
  {
    return {
      form: {
        password: { required },
        password_confirmation: { 
          required,  
          sameAsPassword: sameAs(this.form.password)
        },
        email: { required, email }
      }
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    submit()
    {
      let data = { ...this.form }

          data.token = this.token

      this.setLoading(true)

      this.message = null

      API.auth.resetPassword(data)
              .then(({ data })=>{
                
                this.message = data.message

                this.messageType = 'info'

                this.resetForm()

              })
              .catch((err)=>{
                
                let message = err.response.data.message

                this.message = message

                this.messageType = 'error'

              })
              .finally(()=>{
                
                this.setLoading(false)

              })
    },

    resetForm()
    {
      this.form.password = null

      this.form.password_confirmation = null

      this.form.email = null
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
