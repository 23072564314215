<template>

	<div>

      <Loading v-show="isLoading">
        {{ loadingMessage }}
      </Loading>

      <div v-show="!isLoading">
    
        <v-row>
          
          <v-col class="px-10"
                 :style="shippingCss"
                 cols="12"
                 lg="6"
                 v-if="hasShipping">

            <p class="text-h5 font-weight-bold"
               style="min-height:64px">
              Dove dobbiamo spedire i tuoi acquisti?
            </p>

            <!-- user has no address? -->
            <div v-if="!user.hasAddresses">
              <Alert type="warning" icon>
                Non hai ancora impostato un indirizzo di spedizione.
              </Alert>
              <p class="text-center">
                <AddressDialog>
                  <template #activator="props">
                    <v-btn v-bind="props.attrs" v-on="props.on">
                      Aggiungi indirizzo
                    </v-btn>
                  </template>
                </AddressDialog>
              </p>
            </div>
            
            <Shipping v-for="address in user.addresses"
                      :address="address"
                      :key="address.id"
                      :highlighted="addressIsSelected(address)"
                      :expanded="addressIsSelected(address)"
                      class="mt-5 pointer"
                      :withHover="true"
                      @click="onShippingClick(address)"/>

          </v-col>

          <v-col class="px-10"
                 cols="12"
                 lg="6"
                 :offset="colOffset">
            
            <v-divider class="mt-5 mb-10"
                       v-if="device.isSm"/>

            <!-- Credit cards -->

            <p class="text-h5 font-weight-bold"
               style="min-height:64px">
              Come vuoi pagare?
            </p>

            <!-- user has no payment method? -->
            <div v-if="!user.hasPaymentMethods">
              <Alert type="warning" icon>
                Non hai ancora impostato un metodo di pagamento.
              </Alert>
              <p class="text-center">
                <CreditCardDialog>
                  <template #activator="props">
                    <v-btn v-bind="props.attrs" v-on="props.on">
                      Aggiungi metodo
                    </v-btn>
                  </template>
                </CreditCardDialog>
              </p>
            </div>

            <Payment v-for="paymentMethod in user.paymentMethods"
                     :key="paymentMethod.id"
                     :paymentMethod="paymentMethod"
                     class="mt-5 pointer"
                     :highlighted="paymentMethodIsSelected(paymentMethod)"
                     :expanded="paymentMethodIsSelected(paymentMethod)"
                     :withHover="true"
                     @click="onPaymentClick(paymentMethod)"/>

            <!-- Coupon -->

            <p class="text-h5 font-weight-bold mt-10">
              Hai un coupon da utilizzare?
            </p>

            <Coupon :value.sync="coupon"
                    :status.sync="couponStatus"/>

          </v-col>

        </v-row>

        <p v-if="errorMessage"
           class="mt-10 mx-auto"
           style="max-width:600px">
          <v-alert type="warning">
            <span v-html="$t(errorMessage, errorData)"></span>
          </v-alert>
        </p>

        <p class="text-center mt-10">
          <v-btn x-large 
                 color="primary"
                 :disabled="!canSubmit"
                 @click="checkout">
            Invia ordine
          </v-btn>
        </p>

        <p class="text-center mx-auto mt-10" style="max-width: 600px">
          <small v-html="$t('ecommerce.checkout.disclaimer')"></small>
        </p>

        <p class="text-center mx-auto mt-10" style="max-width: 600px">
          <small v-html="$t('ecommerce.checkout.zero_cart')"></small>
        </p>

      </div>

  </div>
  
</template>

<script>
import API from '@/api'

import AddressDialog from '@/components/addresses/Dialog'
import Alert from '@/components/UI/Alert'
import Coupon from '@/components/ecommerce/checkout/Coupon'
import CreditCardDialog from '@/components/ecommerce/credit-card/Dialog'
import Loading from '@/components/ecommerce/checkout/Loading'
import Payment from '@/components/ecommerce/credit-card/Panel'
import Shipping from '@/components/addresses/Panel'

import { mapActions, mapGetters } from 'vuex'

import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

export default {
  name: 'UserCheckout',

  mixins: [ mq, utility ],

  components: {
    AddressDialog,
    Alert,
    Coupon,
    CreditCardDialog,
    Loading,
    Payment,
    Shipping
  },

  data: () => ({
    selected: {
      payment: {},
      shipping: {}
    },
    coupon: null,
    couponStatus: 0,
    isLoading: false,
    errorMessage: '',
    errorData: {},
    loadingMessage: ''
  }),

  computed:
  {
    ...mapGetters({
      cart: 'ecommerce/cart',
      hasShipping: 'ecommerce/cartHasShipping',
      products: 'ecommerce/products',
      stripe: 'ecommerce/stripe',
      user: 'auth/user'
    }),

    canSubmit()
    {
      let checkShippingForm = this.hasShipping ? 
                              this.selected.shipping.id : true

      return checkShippingForm
             && this.selected.payment.id
             && this.couponStatus >= 0
    },

    colOffset()
    {
      if( this.device.isSm )
        return 0

      let offset = this.hasShipping ? 0 : 3

      return offset
    },

    shippingCss()
    {
      return this.device.isLg ? `border-right: 1px solid #ccc` : ``
    }

  },

  methods:
  {
    ...mapActions({
      clearCart: 'ecommerce/clearCart'
    }),

    onPaymentClick(paymentMethod)
    {
      if( !this.paymentMethodIsSelected(paymentMethod) )
        this.selected.payment = paymentMethod
    },

    onShippingClick(address)
    {
      if( !this.addressIsSelected(address) )
        this.selected.shipping = address
    },

    checkout()
    {
      let meta = {},
          payment_method = this.selected.payment.id,
          shipping = this.selected.shipping.toForm,
          coupon = this.coupon,
          products = this.cart.productsForCheckout


      let checkout = {
            products,
            payment_method,
            coupon,
            shipping,
            meta
          },
          email = this.user.email

      this.resetLoading()

      this.setLoading(`Effettuo il checkout.`)

      API.ecommerce.checkout(checkout, email)
                   .then(({ data })=>{

                    this.clearCart()

                    let order = data.data

                    this.goTo({ 
                      name:'orderPlaced', 
                      params:{ orderNumber:order.number } 
                    })

                   })
                   .catch((err)=>{
                    
                    let errorMessage = err.response.data.message,
                        errorData = { }

                    if( err.response.data.product )
                      errorData.productName = err.response.data.product.name

                    this.resetLoading(errorMessage, errorData)

                   })

      /*
      const content = 'Metodo di pagamento registrato',
            options = { color:'success' }
      
      this.growl({ content, ...options })
      */
      
    },

    resetLoading(errorMessage='', errorData={})
    {
      this.errorMessage = errorMessage

      this.errorData = errorData

      this.loadingMessage = ''
      
      this.isLoading = false
    },

    setLoading(loadingMessage='')
    {
      this.loadingMessage = loadingMessage

      this.isLoading = true
    },

    addressIsSelected(address)
    {
      return this.selected.shipping.id === address.id
    },

    paymentMethodIsSelected(paymentMethod)
    {
      return this.selected.payment.id === paymentMethod.id
    },

    syncUserData()
    {
      if( !this.user )
        return

      this.selected.shipping = this.user.address

      this.selected.payment = this.user.paymentMethod
    }

  },

  mounted()
  {
    this.syncUserData()
  },

  watch: 
  {
    user()
    {
      this.syncUserData()
    }
  }
};
</script>
