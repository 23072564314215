const province = [
    {
        "nome": "AGRIGENTO",
        "sigla": "AG"
    },
    {
        "nome": "ALESSANDRIA",
        "sigla": "AL"
    },
    {
        "nome": "ANCONA",
        "sigla": "AN"
    },
    {
        "nome": "AOSTA",
        "sigla": "AO"
    },
    {
        "nome": "AREZZO",
        "sigla": "AR"
    },
    {
        "nome": "ASCOLI PICENO",
        "sigla": "AP"
    },
    {
        "nome": "ASTI",
        "sigla": "AT"
    },
    {
        "nome": "AVELLINO",
        "sigla": "AV"
    },
    {
        "nome": "BARI",
        "sigla": "BA"
    },
    {
        "nome": "BARLETTA-ANDRIA-TRANI",
        "sigla": "BT"
    },
    {
        "nome": "BELLUNO",
        "sigla": "BL"
    },
    {
        "nome": "BENEVENTO",
        "sigla": "BN"
    },
    {
        "nome": "BERGAMO",
        "sigla": "BG"
    },
    {
        "nome": "BIELLA",
        "sigla": "BI"
    },
    {
        "nome": "BOLOGNA",
        "sigla": "BO"
    },
    {
        "nome": "BOLZANO",
        "sigla": "BZ"
    },
    {
        "nome": "BRESCIA",
        "sigla": "BS"
    },
    {
        "nome": "BRINDISI",
        "sigla": "BR"
    },
    {
        "nome": "CAGLIARI",
        "sigla": "CA"
    },
    {
        "nome": "CALTANISSETTA",
        "sigla": "CL"
    },
    {
        "nome": "CAMPOBASSO",
        "sigla": "CB"
    },
    {
        "nome": "CARBONIA-IGLESIAS",
        "sigla": "CI"
    },
    {
        "nome": "CASERTA",
        "sigla": "CE"
    },
    {
        "nome": "CATANIA",
        "sigla": "CT"
    },
    {
        "nome": "CATANZARO",
        "sigla": "CZ"
    },
    {
        "nome": "CHIETI",
        "sigla": "CH"
    },
    {
        "nome": "COMO",
        "sigla": "CO"
    },
    {
        "nome": "COSENZA",
        "sigla": "CS"
    },
    {
        "nome": "CREMONA",
        "sigla": "CR"
    },
    {
        "nome": "CROTONE",
        "sigla": "KR"
    },
    {
        "nome": "CUNEO",
        "sigla": "CN"
    },
    {
        "nome": "ENNA",
        "sigla": "EN"
    },
    {
        "nome": "FERMO",
        "sigla": "FM"
    },
    {
        "nome": "FERRARA",
        "sigla": "FE"
    },
    {
        "nome": "FIRENZE",
        "sigla": "FI"
    },
    {
        "nome": "FOGGIA",
        "sigla": "FG"
    },
    {
        "nome": "FORLI’-CESENA",
        "sigla": "FC"
    },
    {
        "nome": "FROSINONE",
        "sigla": "FR"
    },
    {
        "nome": "GENOVA",
        "sigla": "GE"
    },
    {
        "nome": "GORIZIA",
        "sigla": "GO"
    },
    {
        "nome": "GROSSETO",
        "sigla": "GR"
    },
    {
        "nome": "IMPERIA",
        "sigla": "IM"
    },
    {
        "nome": "ISERNIA",
        "sigla": "IS"
    },
    {
        "nome": "LA SPEZIA",
        "sigla": "SP"
    },
    {
        "nome": "L’AQUILA",
        "sigla": "AQ"
    },
    {
        "nome": "LATINA",
        "sigla": "LT"
    },
    {
        "nome": "LECCE",
        "sigla": "LE"
    },
    {
        "nome": "LECCO",
        "sigla": "LC"
    },
    {
        "nome": "LIVORNO",
        "sigla": "LI"
    },
    {
        "nome": "LODI",
        "sigla": "LO"
    },
    {
        "nome": "LUCCA",
        "sigla": "LU"
    },
    {
        "nome": "MACERATA",
        "sigla": "MC"
    },
    {
        "nome": "MANTOVA",
        "sigla": "MN"
    },
    {
        "nome": "MASSA-CARRARA",
        "sigla": "MS"
    },
    {
        "nome": "MATERA",
        "sigla": "MT"
    },
    {
        "nome": "MEDIO CAMPIDANO",
        "sigla": "VS"
    },
    {
        "nome": "MESSINA",
        "sigla": "ME"
    },
    {
        "nome": "MILANO",
        "sigla": "MI"
    },
    {
        "nome": "MODENA",
        "sigla": "MO"
    },
    {
        "nome": "MONZA E BRIANZA",
        "sigla": "MB"
    },
    {
        "nome": "NAPOLI",
        "sigla": "NA"
    },
    {
        "nome": "NOVARA",
        "sigla": "NO"
    },
    {
        "nome": "NUORO",
        "sigla": "NU"
    },
    {
        "nome": "OGLIASTRA",
        "sigla": "OG"
    },
    {
        "nome": "OLBIA-TEMPIO",
        "sigla": "OT"
    },
    {
        "nome": "ORISTANO",
        "sigla": "OR"
    },
    {
        "nome": "PADOVA",
        "sigla": "PD"
    },
    {
        "nome": "PALERMO",
        "sigla": "PA"
    },
    {
        "nome": "PARMA",
        "sigla": "PR"
    },
    {
        "nome": "PAVIA",
        "sigla": "PV"
    },
    {
        "nome": "PERUGIA",
        "sigla": "PG"
    },
    {
        "nome": "PESARO E URBINO",
        "sigla": "PU"
    },
    {
        "nome": "PESCARA",
        "sigla": "PE"
    },
    {
        "nome": "PIACENZA",
        "sigla": "PC"
    },
    {
        "nome": "PISA",
        "sigla": "PI"
    },
    {
        "nome": "PISTOIA",
        "sigla": "PT"
    },
    {
        "nome": "PORDENONE",
        "sigla": "PN"
    },
    {
        "nome": "POTENZA",
        "sigla": "PZ"
    },
    {
        "nome": "PRATO",
        "sigla": "PO"
    },
    {
        "nome": "RAGUSA",
        "sigla": "RG"
    },
    {
        "nome": "RAVENNA",
        "sigla": "RA"
    },
    {
        "nome": "REGGIO CALABRIA",
        "sigla": "RC"
    },
    {
        "nome": "REGGIO EMILIA",
        "sigla": "RE"
    },
    {
        "nome": "RIETI",
        "sigla": "RI"
    },
    {
        "nome": "RIMINI",
        "sigla": "RN"
    },
    {
        "nome": "ROMA",
        "sigla": "RM"
    },
    {
        "nome": "ROVIGO",
        "sigla": "RO"
    },
    {
        "nome": "SALERNO",
        "sigla": "SA"
    },
    {
        "nome": "SASSARI",
        "sigla": "SS"
    },
    {
        "nome": "SAVONA",
        "sigla": "SV"
    },
    {
        "nome": "SIENA",
        "sigla": "SI"
    },
    {
        "nome": "SIRACUSA",
        "sigla": "SR"
    },
    {
        "nome": "SONDRIO",
        "sigla": "SO"
    },
    {
        "nome": "TARANTO",
        "sigla": "TA"
    },
    {
        "nome": "TERAMO",
        "sigla": "TE"
    },
    {
        "nome": "TERNI",
        "sigla": "TR"
    },
    {
        "nome": "TORINO",
        "sigla": "TO"
    },
    {
        "nome": "TRAPANI",
        "sigla": "TP"
    },
    {
        "nome": "TRENTO",
        "sigla": "TN"
    },
    {
        "nome": "TREVISO",
        "sigla": "TV"
    },
    {
        "nome": "TRIESTE",
        "sigla": "TS"
    },
    {
        "nome": "UDINE",
        "sigla": "UD"
    },
    {
        "nome": "VARESE",
        "sigla": "VA"
    },
    {
        "nome": "VENEZIA",
        "sigla": "VE"
    },
    {
        "nome": "VERBANO-CUSIO-OSSOLA",
        "sigla": "VB"
    },
    {
        "nome": "VERCELLI",
        "sigla": "VC"
    },
    {
        "nome": "VERONA",
        "sigla": "VR"
    },
    {
        "nome": "VIBO VALENTIA",
        "sigla": "VV"
    },
    {
        "nome": "VICENZA",
        "sigla": "VI"
    },
    {
        "nome": "VITERBO",
        "sigla": "VT"
    }
]

export default province