<template>
  <v-app>
    
    <router-view/>

    <Stripe/>

    <FastCheckout/>

    <StateRequest/>

    <Loading :status="isLoading"/>

  </v-app>
</template>

<style lang="scss">
  .mt-15 { margin-top: 60 !important; }
  .mt-20 { margin-top: 80 !important; }
  .mb-15 { margin-bottom: 60 !important; }
  .mb-20 { margin-bottom: 80 !important; }

  .pt-15 { padding-top: 60 !important; }
  .pt-20 { padding-top: 80 !important; }

  .pointer { cursor: pointer !important; }

  .with-shadow,
  .with-shadow-hover {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .with-shadow-hover {
    &:hover {
      box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    }
  }
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

import FastCheckout from '@/components/ecommerce/FastCheckout'
import Loading from '@/components/UI/Loading'
import StateRequest from '@/components/StateRequest'
import Stripe from '@/components/ecommerce/Stripe'

export default {
  name: 'App',

  components: {
    FastCheckout,
    Loading,
    StateRequest,
    Stripe
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters({
      appVersion: 'appVersion',
      isLoading: 'ui/isLoading'
    })
  },

  methods: {
    ...mapActions({
        initCart: 'ecommerce/initCart',
        loadCart: 'ecommerce/loadCart'
    })
  },

  mounted()
  {
    console.debug('Zwan Store v' + this.appVersion)

    this.loadCart()
        .then((cart)=>{
          this.initCart(cart)
        })
        .catch(()=>{
          this.initCart()
        })
  },

  watch:
  {
    
  }
};
</script>
