<template>

	<v-navigation-drawer
      absolute
      right
      dark
      :value="status"
      @input="onToggle"
    >

    <v-list class="pt-10 text-center">
      
      <div v-for="category in categories"
           :key="category.id">

        <!-- categories without submenu -->
        <v-list-item v-if="!category.hasChildren"
                     @click="onCategoryClick(category)">
          <v-list-item-content>
            <v-list-item-title>
              {{ category.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- categories with submenu -->
        <v-list-group v-else
                      :value="menuStatus[category.id]">
          
          <template v-slot:activator>
            <v-list-item-title>
              {{ category.name }}
            </v-list-item-title>
          </template>

          <v-list-item v-for="child in category.children"
                       :key="child.id"
                       @click="onCategoryClick(child)">
            <v-list-item-content>
              <v-list-item-title>
                {{ child.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

      </div>

    </v-list>
  </v-navigation-drawer>
  
</template>

<script>
import API from '@/api'

import utility from '@/mixins/utility'

export default {
  name: 'MobileMenu',

  mixins: [ utility ],

  components: {
    
  },

  data: () => ({
    categories: [],
    menuStatus: {},
    status: false
  }),

  props:
  {
    
  },

  computed:
  {
    
  },

  methods:
  {
    /**
     * @todo potrei gestirle nello state e fare una sola chiamata API
     */
    loadCategories()
    {
      API.categories.list({ 'filter[main]':true })
                    .then(({ data })=>{
                      
                      this.categories = this.mapModels(data.data, 'category')

                      this.loaded = true

                    })
    },

    onCategoryClick(category)
    {
      if( !category.hasChildren )
        this.goTo({ 
          name:'category', 
          params: { slug:category.slug }
        })
    },

    onToggle(status)
    {
      this.status = status
    },

    show()
    {
      this.status = true
    }
  },

  mounted()
  {
    this.loadCategories()
  },

  watch: {}
};
</script>
