class CartItem {

  #product = {}

  quantity = 0

  constructor(product, quantity=0)
  {  
    this.#product = product

    this.quantity = quantity
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.product.id
  }
  
  get product()
  {
    return this.#product
  }

  get meta()
  {
    return this.#product.meta || {}
  }
  
  get price()
  {
    return (this.quantity * this.product.price.value)
  }

  get isDigital()
  {
    return this.product.isDigital ? true : false
  }

  /**
   * Computed properties.
   */
  
  /**
   * Methods.
   */
  
  add()
  {
    this.quantity++
  }

  remove()
  {
    if( this.quantity > 0 )
      this.quantity--
  }

  canBuyMore( quantity=1 )
  {
    if( !this.#product.hasMaxAmount )
      return true

    let maxAmount = this.#product.maxAmount

    return (this.quantity + quantity) <= maxAmount
  }

}

export default CartItem