<template>

	<div>

      <Loading v-show="isLoading">
        {{ loadingMessage }}
      </Loading>

      <div v-show="!isLoading">
    
        <v-row>
          
          <v-col class="text-center px-10"
                 :style="shippingCss"
                 cols="12"
                 lg="6"
                 v-if="hasShipping">
            <Shipping @update="onShippingUpdate"/>
          </v-col>

          <v-col class="text-center px-10"
                 cols="12"
                 lg="6"
                 :offset=" hasShipping ? 0 : 3 ">
            <Payment @update="onPaymentUpdate"/>
          </v-col>

        </v-row>

        <Alert v-if="errorMessage"
               type="warning"
               class="mt-10">
          {{ $t(errorMessage, errorData) }}
        </Alert>

        <p class="text-center mt-10">
          <v-btn x-large 
                 color="primary"
                 :disabled="!canSubmit"
                 @click="checkout">
            Invia ordine
          </v-btn>
        </p>

        <p class="text-center mx-auto mt-10" style="max-width: 600px">
          <small v-html="$t('ecommerce.checkout.disclaimer')"></small>
        </p>

        <p class="text-center mx-auto mt-10" style="max-width: 600px">
          <small v-html="$t('ecommerce.checkout.zero_cart')"></small>
        </p>

      </div>

  </div>
  
</template>

<script>
import API from '@/api'

import Alert from '@/components/UI/Alert'
import Loading from '@/components/ecommerce/checkout/Loading'
import Payment from '@/components/ecommerce/checkout/Payment'
import Shipping from '@/components/ecommerce/checkout/Shipping'

import { mapActions, mapGetters } from 'vuex'

import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

export default {
  name: 'GuestCheckout',

  mixins: [ mq, utility ],

  components: {
    Alert,
    Loading,
    Payment,
    Shipping
  },

  data: () => ({
    forms: {
      payment: { status: false },
      shipping: { status: false }
    },
    isLoading: false,
    errorMessage: '',
    errorData: {},
    loadingMessage: ''
  }),

  computed:
  {
    ...mapGetters({
      cart: 'ecommerce/cart',
      hasShipping: 'ecommerce/cartHasShipping',
      products: 'ecommerce/products',
      stripe: 'ecommerce/stripe'
    }),

    canSubmit()
    {
      let checkShippingForm = this.hasShipping ? 
                              this.forms.shipping.status : true

      return checkShippingForm
             && this.forms.payment.status
             && this.forms.payment.form.email
    },

    shippingCss()
    {
      return this.device.isLg ? `border-right: 1px solid #ccc` : ``
    }

  },

  methods:
  {
    ...mapActions({
      clearCart: 'ecommerce/clearCart',
      confirmCard: 'ecommerce/confirmCard'
    }),

    onPaymentUpdate(update)
    {
      this.forms.payment = update
    },

    onShippingUpdate(update)
    {
      this.forms.shipping = update
    },

    checkout()
    {
      let email = this.forms.payment.form.email

      this.resetLoading()

      this.setLoading(`Raccolgo le informazioni per inviare l'ordine.`)

      // 1. get / create User
      API.auth.upsertUser({ email })
      
      // 2. get Stripe intent
         .then(()=>{
          API.ecommerce.getIntent(email)

      // 3. confirm credit card
             .then(({ data })=>{

                this.setLoading(`Verifico la carta di credito.`)

                this.confirmCard({
                  intentToken: data,
                  cardholder: this.forms.payment.creditCard.cardholder,
                  card: this.forms.payment.creditCard.card
                })
                    
      // 4. save payment method
                    .then((res)=>{

                      // Something went wrong?
                      if (res.error) {

                        let errorMessage = `errors.credit_card`

                        console.error(res.error.message)

                        this.resetLoading(errorMessage)
                      
                      } else {

                        // Save payment method
                        let paymentMethod = res.setupIntent.payment_method
                        
                        this.setLoading(`Registro il metodo di pagamento.`)
                        
                        API.ecommerce
                           .savePaymentMethod(paymentMethod, email)
                           .then(()=>{

      // 5. checkout
                            let meta = {},
                                payment_method = paymentMethod,
                                shipping = this.forms.shipping.form,
                                coupon = this.forms.payment.form.coupon,
                                products = this.cart.productsForCheckout


                            let checkout = {
                              products,
                              payment_method,
                              coupon,
                              shipping,
                              meta
                            }

                            this.setLoading(`Effettuo il checkout.`)

                            API.ecommerce.checkout(checkout, email)
                                         .then(({ data })=>{

                                          this.clearCart()

                                          let order = data.data

                                          this.goTo({ 
                                            name:'orderPlaced', 
                                            params:{ orderNumber:order.number } 
                                          })

                                         })
                                         .catch((err)=>{

                                          let errorMessage = err.response.data.message,
                                              errorData = { }

                                          if( err.response.data.product )
                                            errorData.productName = err.response.data.product.name

                                          this.resetLoading(errorMessage, errorData)

                                         })
                                         .finally(()=>this.resetLoading())

                            /*
                            const content = 'Metodo di pagamento registrato',
                                  options = { color:'success' }
                            
                            this.growl({ content, ...options })
                            */

                            

                           })

                      }

                    })

             })

         })
      
    },

    resetLoading(errorMessage='', errorData={})
    {
      this.errorMessage = errorMessage

      this.errorData = errorData
                        
      this.loadingMessage = ''
      
      this.isLoading = false
    },

    setLoading(loadingMessage='')
    {
      this.loadingMessage = loadingMessage

      this.isLoading = true
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
