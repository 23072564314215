<template>
  <div>
    <v-container class="py-10" style="max-width:700px">

      <v-row>
        <v-col class="text-center">
          
          <p>
              <check-circle-icon size="5.5x" class="success--text"></check-circle-icon>
          </p>
          
          <p class="text-h2 mt-10">
            Ci siamo quasi!  
          </p>
          
          <p class="text-h5 mt-5">
            Per completare la creazione del tuo <strong>Zwan ID</strong> non ti resta che confermare il tuo indirizzo e-mail cliccando sul link che ti abbiamo appena inviato.
          </p>

        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import { CheckCircleIcon } from 'vue-feather-icons'

export default {
  
  name: 'OrderPlaced',

  mixins: [],

  components: {
    CheckCircleIcon    
  },

  data: () => ({

  }),

  computed: 
  {
    orderId()
    {
      return this.$route.params.orderId
    }
  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch:
  {
    
  }

}
</script>
