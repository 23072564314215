<template>
  <div>
    <v-container class="py-10">

      <Alert v-if="errorMessage" 
             type="warning"
             icon
             class="mx-auto"
             style="max-width: 700px">
        {{ $t(errorMessage) }}
      </Alert>

      <div v-else-if="order">

        <p class="text-h3 mb-10 mt-5">
          Ordine <span class="primary--text">#{{ orderNumber }}</span>
        </p>

        <!-- details -->
        <v-simple-table class="order-details">
          <template v-slot:default>
            <tbody>
              <!-- order number -->
              <tr>
                <th>Numero ordine</th>
                <td class="text-right">
                  {{ order.number }}
                </td>
              </tr>
              <!-- dates -->
              <tr>
                <th>Data ordine</th>
                <td class="text-right">
                  {{ order.date.format('DD/MM/Y') }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- products -->
        <p class="mt-10 text-h5">
          Prodotti
        </p>

        <v-simple-table class="mb-10">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Nome
                </th>
                <th class="text-right">
                  Quantità
                </th>
                <th class="text-right">
                  Prezzo
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in order.items"
                  :key="i">
                <td>{{ item.product.name }}</td>
                <td class="text-right">
                  {{ item.quantity }}
                </td>
                <td class="text-right">
                  {{ item.total | currency }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- 🏷 coupon -->
        <v-row v-if="order.coupon">
          <v-col>
            <span class="text-h5">
              Coupon
            </span>
          </v-col>
          <v-col class="text-right">
            <span class="text-h5">
              {{ order.coupon.code }}
            </span>
          </v-col>
        </v-row>

        <!-- 🚛 spese di spedizione -->
        <v-row>
          <v-col>
            <span class="text-h5">
              Spese di spedizione
            </span>
          </v-col>
          <v-col class="text-right">
            <span class="text-h5 success--text">
              Gratis
            </span>
          </v-col>
        </v-row>

        <!-- 💶 totale -->
        <v-row class="mt-10">
          <v-col>
            <span class="text-h4 font-weight-bold">
              Totale
            </span>
          </v-col>
          <v-col class="text-right">
            <span class="text-h4 primary--text">
              {{ order.total | currency }}
            </span>
          </v-col>
        </v-row>
      </div>

    </v-container>
  </div>
</template>

<style lang="scss">
  .order-details {
    * {
      font-size:  1.05em !important;
    }
  }
</style>

<script>
//import { CheckCircleIcon } from 'vue-feather-icons'

import API from '@/api'

import Order from '@/models/order'

import { mapMutations } from 'vuex'

import Alert from '@/components/UI/Alert'

export default {
  
  name: 'Order',

  mixins: [],

  components: {
    Alert,
    //CheckCircleIcon
  },

  data: () => ({
    order: null,
    errorMessage: null
  }),

  computed: 
  {
    orderNumber()
    {
      return this.$route.params.orderNumber
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),
    
    loadOrder()
    {
      this.setLoading()

      this.errorMessage = null

      API.ecommerce.order(this.orderNumber)
                   .then(({ data })=>{
                    this.order = new Order(data.data)
                   })
                   .catch((err)=>{
                    
                    let errorMessage = err.response.data.message

                    this.errorMessage = errorMessage

                   })
                   .finally(this.setLoading(false))
    }
  },

  mounted()
  {
    this.loadOrder()
  },

  watch:
  {
    
  }

}
</script>
