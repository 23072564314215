import Cart from '@/models/cart'

import Crypto from '@/plugins/crypto'

const state = {
    stripe: null,
    cart: {}
}

const getters = {

    stripe(state)
    {
        return state.stripe
    },

    cart(state)
    {
        return state.cart
    },

    cartIsEmpty(state)
    {
        return state.cart.isEmpty
    },

    cartItemsCount(state)
    {
        return state.cart.itemsCount
    },

    cartTotal(state)
    {
        return state.cart.total
    },

    cartHasShipping(state)
    {
        return state.cart.hasShipping
    },

    products(state)
    {
        return state.cart.products
    }

}

const actions = {

    initCart({ commit }, data={})
    {
        commit('setCart', new Cart(data) )
    },

    initStripe({ commit }, Stripe)
    {
        commit('setStripe', Stripe)
    },

    confirmCard({ state }, data)
    {
      return state.stripe.confirmCardSetup(
                data.intentToken.client_secret, {
                    payment_method: {
                        card: data.card,
                        billing_details: {
                            name: data.cardholder
                        }
                    }
                }
            )
    },

    loadCart()
    {
        return new Promise((resolve, reject) => {

          const cart = localStorage.getItem('cart')

          if( cart )
          {
            let decryptedCart = Crypto.decrypt(cart)

            resolve(decryptedCart)
          }
          else {

            reject()

          }

        })

    },

    storeCart({ state })
    {
        let encodedCart = state.cart.encoded,
            encryptedCart = Crypto.encrypt(encodedCart)

        localStorage.setItem('cart', encryptedCart)
    },

    addToCart({ commit, dispatch }, product, quantity=1)
    {
        commit('addToCart', product, quantity)

        dispatch('storeCart')
    },

    removeFromCart({ commit, dispatch }, product)
    {
        commit('removeFromCart', product)

        dispatch('storeCart')
    },


    clearCart({ commit, dispatch })
    {
        commit('clearCart')

        dispatch('storeCart')
    },
}

const mutations = {

    setCart(state, cart)
    {
        state.cart = cart
    },
    
    setStripe(state, Stripe)
    {
        state.stripe = Stripe
    },

    addToCart(state, product, quantity=1)
    {
        state.cart.add(product, quantity)
    },

    removeFromCart(state, product)
    {
        state.cart.remove(product)
    },

    clearCart(state)
    {
        state.cart.clear()
    }

}

const ecommerce = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default ecommerce