<template>

  <span>
    <v-progress-circular
      indeterminate
      size="20"
      width="2"
      v-if="status"
    ></v-progress-circular>
  </span>
  
</template>

<script>
export default {
  name: 'InputLiveTypingIcon',

  mixins: [],

  components: {
    
  },

  data: () => ({
    //
  }),

  props: {
    status: {
      type: Boolean,
      default: false
    }
  },

  computed:
  {
    
  },

  methods:
  {
    
  },

  mounted()
  {

  },

  watch: {}
};
</script>
