<template>

	<Panel :highlighted="highlighted"
         :withHover="withHover"
         @click="onClick">
    
    <v-row>
      <v-col cols="8">
        
        <Icon :brand="paymentMethod.brand" height="25" class="mr-2"/>
        <span class="text-h5">
          {{ paymentMethod.cardNumber }}
        </span>

        <div v-show="expanded">
        
          <p class="mt-2 mb-0">
            Intestata a <strong>{{ paymentMethod.name }}</strong> 
          </p>

          <p class="mt-2 mb-0">
            Scade il <strong>{{ paymentMethod.expiration }}</strong>
          </p>

        </div>

      </v-col>
    </v-row>
    
    <slot name="bottom"></slot>

  </Panel>
  
</template>

<script>
import Icon from '@/components/ecommerce/credit-card/Icon'
import Panel from '@/components/UI/Panel'

export default {
  name: 'CreditCardPanel',

  mixins: [],

  components: {
    Icon,
    Panel
  },

  data: () => ({
    //
  }),

  computed:
  {

  },

  props: 
  {
    paymentMethod:
    {
      type: Object,
      required: true
    },

    expanded:
    {
      type: Boolean,
      default: true
    },

    highlighted:
    {
      type: Boolean,
      default: false
    },

    withHover:
    {
      type: Boolean,
      default: false
    }
  },

  methods:
  {
    onClick()
    {
      this.$emit('click')
    }
  },

  mounted()
  {

  },

  watch: {}
};
</script>
