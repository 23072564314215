<template>

	<v-slide-group
    v-model="active"
    center-active
    show-arrows
    class="mt-0"
  >
    <v-slide-item
      v-for="category in categories"
      :key="category.id"
    >
      <v-card class="mx-4 transparent"
              width="150"
              :elevation="0"
              @click="goToCategory(category)">
        
        <v-img
          height="200"
          :src="category.image"
          contain
          position="bottom center"
        ></v-img>

        <v-card-title 
          style="word-break: break-word"
          class="text-subtitle-1">
          {{ category.name }}
        </v-card-title>
      </v-card>
    </v-slide-item>
  </v-slide-group>
  
</template>

<script>
import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

export default {
  name: 'CategoriesCarousel',

  mixins: [ mq, utility ],

  components: {
    
  },

  data: () => ({
    active: null
  }),

  props:
  {
    categories: {
      required: true
    }
  },

  computed:
  {
    
  },

  methods:
  {
    goToCategory(category)
    {
      this.goTo({ 
            name:'category', 
            params:{ slug:category.slug } 
          })
    },
  },

  mounted()
  {

  },

  watch:
  {

  }
};
</script>
