<template>
  <div>
    <v-container class="py-10">

      <v-row>
        <v-col class="text-center">
          <p>
              <check-circle-icon size="5.5x" class="success--text"></check-circle-icon>
          </p>
          <p class="text-h5 mt-10">
            L'ordine <strong class="primary--text">#{{ orderNumber }}</strong> &egrave; stato completato con successo.
          </p>
          <p>
            Riceverai maggiori informazioni all'indirizzo e-mail che hai fornito.
          </p>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import { CheckCircleIcon } from 'vue-feather-icons'

import goTo from 'vuetify/lib/services/goto'

export default {
  
  name: 'OrderPlaced',

  mixins: [],

  components: {
    CheckCircleIcon    
  },

  data: () => ({

  }),

  computed: 
  {
    orderNumber()
    {
      return this.$route.params.orderNumber
    }
  },

  methods:
  {
    scrollToTop()
    {
      goTo(0)
    }
  },

  mounted()
  {
    this.scrollToTop()
  },

  watch:
  {
    
  }

}
</script>
