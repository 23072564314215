<template>
  <div>
    <v-container class="py-10">

      <p class="text-h3 mb-10 pb-5 mt-5">
        Downloads
      </p>

      <v-alert type="info" v-if="isLoading">
        Sto recuperando i tuoi download.
      </v-alert>

      <v-alert type="info" v-if="noDownloads">
        Non hai ancora acquistato nessun prodotto con associati download digitali.
      </v-alert>

      <v-row class="pa-4"
             justify="space-between">
        
        <v-col cols="12" md="5">

          <v-alert type="info" color="blue lighten-2">
            Questi sono i prodotti che hai acquistato che hanno dei contenuti digitali da poter scaricare.
          </v-alert>

          <v-treeview
            :items="products"
            item-children="downloads"
            :active.sync="active"
            :open.sync="open"
            activatable
            color="warning"
            open-on-click
            transition>
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="item.downloads">
                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
              <v-icon v-else>
                mdi-download
              </v-icon>
            </template>
          </v-treeview>
        </v-col>

        <v-divider vertical v-if="!device.isSm"/>

        <v-col
          class="d-flex text-center"
        >
          <!-- anchor for programatic scroll -->
          <div id="download-card"></div>

          <v-scroll-y-transition mode="out-in">

            <!-- select placeholder -->
            <div
              v-if="!selected"
              class="text-h6 grey--text text--lighten-1 font-weight-light"
              style="align-self: center;"
            >
              <arrow-left-icon 
                class="d-block mb-5"
                size="2x"
                v-if="!device.isSm"/>
              <arrow-up-icon 
                class="d-block mx-auto mb-5"
                size="2x"
                v-else/>
              Seleziona un prodotto per effettuare il download
            </div>

            <!-- download card -->
            <v-card
              v-else
              :key="selected.id"
              class="pt-6 mx-auto"
              flat
              max-width="400"
            >
              
              <v-card-text>
                <v-avatar size="88" color="grey lighten-2">
                  <v-icon size="50">
                    mdi-download
                  </v-icon>
                </v-avatar>
                <p class="text-h4 mt-5">
                  {{ selected.name }}
                </p>

                <v-divider class="my-5"/>

                <div class="text-h6 font-weight-light mb-10"
                     v-html="selected.description"
                     v-if="selected.description">
                </div>

                <p v-if="selected.url">
                  <v-btn x-large color="primary"
                         @click="save(selected)">
                    Scarica
                  </v-btn>
                </p>

              </v-card-text>

            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<style lang="scss">
  .v-treeview-node__label {
  font-size: 1.3em !important;
}
</style>

<script>
import _ from 'lodash'
import API from '@/api'

import mq from '@/mixins/mq'
import utility from '@/mixins/utility'

import { mapGetters, mapMutations } from 'vuex'

import goTo from 'vuetify/lib/services/goto'

import { ArrowLeftIcon, ArrowUpIcon } from 'vue-feather-icons'

export default {
  
  name: 'Downloads',

  mixins: [ mq, utility ],

  components: {
    ArrowLeftIcon,
    ArrowUpIcon
  },

  data: () => ({
    active: [],
    open: [],
    selected: null,
    loaded: false,
    downloads: [],
    products: [],
  }),

  computed: 
  {
    ...mapGetters({
      isLoading: 'ui/isLoading',
      user: 'auth/user'
    }),

    noDownloads()
    {
      return this.loaded && !this.products.length
    },

  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    loadDownloads()
    {
      this.setLoading()

      API.auth.downloads()
              .then(({ data })=>{

                this.products = this.mapModels(data.data, 'product')
                                    .sort((a,b)=>{
                                      return b.name - a.name
                                    })

                this.downloads = _.chain(this.products)
                                  .map('downloads')
                                  .flatten()
                                  .uniq()
                                  .value()

                this.loaded = true

               })
               .finally(()=>{

                this.setLoading(false)

               })
    },

    save(download)
    {
      if( download.url )
        window.open(download.url)
    },

  },

  watch:
  {
    active(activeItems)
    {
      if (!activeItems.length) return this.selected = null

      const id = activeItems[0]

      this.selected = this.downloads
                          .find(download => download.id === id)

      goTo('#download-card')
    }
  },

  mounted()
  {
    this.loadDownloads()
  }

}
</script>
