<template>
  <div>
    <v-container class="py-10">

      <v-alert type="info" 
               v-if="cartIsEmpty">
        Il tuo carrello &egrave; vuoto.
      </v-alert>

      <div v-else>

        <div class="text-center">
          <p class="text-h4 font-weight-bold">
            Il totale della tua shopping bag &egrave; {{ cartTotal | currency('€') }}.
          </p>

          <p>
            Ricevi a casa con corriere espresso i tuoi acquisti oppure effettua subito il download.
          </p>

          <p class="my-10">
            <v-btn x-large
                   color="primary" 
                   @click="checkout">
              Vai alla cassa
              <v-icon class="ml-3">mdi-arrow-right</v-icon>
            </v-btn>
          </p>
        </div>

        <v-divider/>

        <!-- CART ITEMS -->
        <CartItem v-for="(item,i) in cart"
                  :key="i"
                  :item="item"/>

        <!-- CART TOTAL -->
        <v-divider/>

        <CartTotal class="mb-10"/>

        <!-- checkout button-->
        <p :class="device.isSm ? 'text-center': 'text-right'">
          <v-btn x-large
                 class="mb-5"
                 @click="clearCart">
            Svuota carrello
          </v-btn>
          <v-btn color="primary" 
                 x-large
                 class="ml-3 mb-5"
                 @click="checkout">
            Vai alla cassa
            <v-icon class="ml-3">mdi-arrow-right</v-icon>
          </v-btn>
        </p>
        
      </div>

    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import mq from '@/mixins/mq'

import goTo from 'vuetify/lib/services/goto'

import CartItem from '@/components/ecommerce/cart/CartItem'
import CartTotal from '@/components/ecommerce/cart/Total'

export default {
  
  name: 'Cart',

  mixins: [ mq ],

  components: {
    CartItem,
    CartTotal
  },

  data: () => ({
    
  }),

  computed: 
  {
    ...mapGetters({
      cart: 'ecommerce/products',
      cartIsEmpty: 'ecommerce/cartIsEmpty',
      cartTotal: 'ecommerce/cartTotal'
    })  
  },

  methods:
  {
    ...mapActions({
      clearCart: 'ecommerce/clearCart'
    }),

    checkout()
    {
      this.$router.push({ name:'checkout' })
    }
  },

  mounted()
  {
    goTo(0)
  }

}
</script>
